import React = require("react")
import { FirewardOutput, FirewardInput, Product } from "@root/shared/lib/database"
import { useStore } from "../store/StoreContext"
import { observer } from "mobx-react-lite"
import { UserImage } from "./kit/UserImage"
import { Icon } from "./kit/icons"
import { Link, useRouting } from "../routing/routes"
import { Login } from "./Login"
import { action, observable } from "mobx"
import { MainStore } from "../store/store"
import { errorToString } from "@root/shared/lib/x"
import { useController } from "../util/mobxUtils"
import { Spinner } from "./kit/Spinner"
import classNames = require("classnames")
import { ButtonBar } from "./kit/ButtonBar"
import { Button } from "./kit/Button"
import { Modal } from "./kit/Modal"
import { ToggleInput } from "./kit/ToggleInput"


export type ProductListProps = {
  products: Product[]
  loading?: boolean
}
type ProductBusyStatus = 'archiving'

export class ProductListController {
  @observable productBusy: Record<string, undefined|null|false|ProductBusyStatus> = {};
  @observable props: ProductListProps
  private store: MainStore

  @observable unarchiverVisible = false;
  @observable archivedProducts: Product<FirewardOutput>[]|null = null;

  constructor(props: ProductListProps, store: MainStore, routing: ReturnType<typeof useRouting>) {
    this.props = props;
    this.store = store; 
  }

  @action toggleArchive = (product: Product<FirewardOutput>, archived: boolean) => {
    this.updateProduct({productId: product.productId, archived}, 'archiving')
    ?.then(action(() => product.archived = archived))
  }

  @action showUnarchiver = () => {
    const uid = this.store.user.user?.uid;
    if (!uid) return;
    this.unarchiverVisible = true;
    this.archivedProducts = null;
    
    return this.store.products.getArchivedProducts(uid)
    .then(action(products => this.archivedProducts = products))
    .catch(action(e => {
      this.unarchiverVisible = false;
      this.store.alert.error([errorToString(e)]);
      this.store.error.captureException(e, {extra: {place: 'productList::showUnarchiver::catch'}});
      return Promise.reject(e)
    }))

  }
  @action hideUnarchiver = () => {
    this.unarchiverVisible = false;
  }

  @action updateProduct = (product: Partial<Product<FirewardInput>> & {productId: string}, status: ProductBusyStatus) => {
    const uid = this.store.user.user?.uid;
    if (!uid) return;
    if (this.productBusy[product.productId]) return;
    
    this.productBusy[product.productId] = status;
    return this.store.products.updateProduct(product, uid)
    .then(action(() => {
      this.productBusy[product.productId] = false;
    }))
    .catch(action(e => {
      this.productBusy[product.productId] = false;
      this.store.alert.error([errorToString(e)], "Server responded with an error:");
      this.store.error.captureException(e, {extra: {product, place: 'updateProduct::catch'}});
      return Promise.reject(e);
    }))


  }
}
export const ProductList = observer(function ProductList(props: ProductListProps) {

  const store = useStore();
  const rt = useRouting();
  const self = useController(() => new ProductListController(props, store, rt))
  
  
  if (!store.user.state) return <Login/>

  return <div className="ProductList">
    <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      {
        store.products.productList?.map( item => <li key={item.productId} className="col-span-1 bg-white rounded-lg shadow">
          <div className="w-full flex items-center justify-between p-6 space-x-6">
            <div className="flex-1 truncate">
              <div className="flex items-center space-x-3">
                <h3 className="text-gray-900 text-sm leading-5 font-medium truncate">{item.code} {item.name}</h3>
                {/* <span className="flex-shrink-0 inline-block px-2 py-0.5 text-teal-800 text-xs leading-4 font-medium bg-teal-100 rounded-full">Admin</span> */}
              </div>
              <p className="mt-1 text-gray-500 text-sm leading-5 truncate">{item.description}</p>
            </div>
            <UserImage path={item.colors.find(x=>x.imagePath)?.imagePath} className="w-10 h-10 flex-shrink-0" imageClasses="bg-gray-300 rounded-full" />
          </div>
          <div className="border-t border-gray-200">
            <div className="-mt-px flex">
              <div className="w-0 flex-1 flex border-r border-gray-200">
                <Link href={rt.productEdit.href({productId: item.productId})} className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150">
                  <Icon name="pencil" color="default" />
                  <span className="ml-3 text-sm label">Edit</span>
                </Link>
              </div>
              <div className="-ml-px w-0 flex-1 flex">
                <div onClick={() => self.toggleArchive(item, true)} className="cursor-pointer relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150">
                  {
                    self.productBusy[item.productId] === 'archiving'
                    ? <Spinner/>
                    : <Icon name="trash" color="danger" />
                  }
                  <span className={classNames({
                    'text-gray-500': self.productBusy[item.productId]}, "ml-3 text-sm label")}>Archive</span>
                </div>
              </div>
            </div>
          </div>
        </li>)
      }
    </ul>
    <ButtonBar left>
      <Button
        text="New Product"
        icon="plus"
        onClick={() => rt.productCreate.navigate()}
      />
      <Button
        text="Unarchive"
        noBorder
        onClick={() => self.showUnarchiver()}
      />
    </ButtonBar>
    {
      self.unarchiverVisible && 
      <Modal
        title="Archived Products"
        onOK={() => self.hideUnarchiver()}
        okColor="default"
        okButton="Close"  
        onXClose={self.hideUnarchiver}
        icon="clipboardList"
      >
        {
          self.archivedProducts
          ? <div>
              {
                self.archivedProducts.length > 0 && <div className="Header">
                  <span>Archived</span>
                </div>
              }
              <ul style={{maxHeight: '400px'}} className="overflow-auto">
              {
                self.archivedProducts.map(product => <li key={product.productId} className="flex items-center mb-2 p-3">
                  <ToggleInput
                    disabled={!!self.productBusy[product.productId]}
                    className="mr-3"
                    value={product.archived}
                    onChange={archived => self.toggleArchive(product, archived)}
                  /> <span className="label text-sm">{product.name}</span>
                </li>)
              }
              </ul>
              {
                self.archivedProducts.length === 0 && <p className="text-sm text-gray-700">
                  No archived products found.
                </p>
              }
            </div>
          : <Spinner/>
        }

      </Modal>
    }
  </div>
})
