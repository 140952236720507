import { PositioningRule, ProductCanvas } from "../database";

/**
 * Semver of this TS package. Update if you need stuff.
 */
export const APP_VERSION = '0.1.4';

export const PRINT_DPI = 300;
/**
 * The fraction by which the uploaded design can be short of 300 DPI resolution in either dimension.
 */
export const PRINT_DPI_TOLERANCE = 0.25;
export const IMAGE_WIDTHS: Record<PositioningRule, number|null> = {
  'center': null,
  'top': null,
  'top-11.5': 11.5,
  'top-12': 12,
  'top-12.5': 12.5,
  'top-13': 13,
  'mirrored': null
};