import { FirewardOutput, ProductCanvas } from "./database"
import { IMAGE_WIDTHS } from "./schemas/constants"
import { memoize } from "./x"

const sample = {
  "Exif":{"PixelXDimension":1200,"DateTimeDigitized":"2014:04:21 21:13:46","PixelYDimension":680,"ColorSpace":1},
  "Orientation":1,
  "Output":{},
  "Content-Type":"image\/jpeg",
  // "JFIF":{"DensityUnit":1,"YDensity":300,"JFIFVersion":[1,0,1],"XDensity":300},
  "DPIWidth":300,"Content-Length":"904954","Depth":8,"ColorModel":"RGB","DPIHeight":300,
  // "TIFF":{"ResolutionUnit":2,"DateTime":"2014:09:05 12:09:82","Orientation":1,"Software":"Pixelmator 3.2","YResolution":300,"XResolution":300},
  "PixelWidth":1200,
  "PixelHeight":680,
  "ProfileName":"sRGB IEC61966-2.1"
}

export type ImgixMetadata = {
  Exif?: {
    PixelXDimension?: number;
    DateTimeDigitized?: string;
    PixelYDimension?: number;
    ColorSpace?: number;
  }
  Orientation: number;
  Output?: {};
  "Content-Type": string;
  DPIWidth?: number;
  DPIHeight?: number;
  "Content-Length": string;
  Depth: number;
  ColorModel: string;
  PixelWidth: number;
  PixelHeight: number;
  ProfileName: string;
}

export const IMGIX_TRANSPARENT_IMG = 'transparent.png'


