import React = require("react")
import { observer } from "mobx-react-lite"
import { useStore } from "../store/StoreContext"
import { Select } from "./kit/Select";
import { SHIPPING_PACKAGE_TYPES } from "@root/shared/lib/clients/EbayTradingTypes";
import { keys, clone, errorToString, isNumber, isValidationMapError, flatten, mapObject } from "@root/shared/lib/x";
import {cleanupCode} from "@root/shared/lib/skus";
import {isProduct, isProductSizePrice, isProductColorOption, validateProduct, isProductImage, isProductSKU} from '../../../shared/lib/schemas/productGuards'
import { TextInput } from "./kit/TextInput";
import { TextArea } from "./kit/TextArea";
import { ButtonBar } from "./kit/ButtonBar";
import { Button } from "./kit/Button";
import { SpinnerOverlay } from "./kit/SpinnerOverlay";
import { NotFoundDetail } from "./kit/NotFoundDetail";
import { Product, ProductPricing, FirewardOutput, SKUAlgorithm } from "@root/shared/lib/database";
import { MainStore } from "../store/store";
import { observable, computed, action, toJS, reaction } from "mobx";
import { Login } from "./Login";
import { Checkbox } from "./kit/Checkbox";
import { FloatInput } from "./kit/FloatInput";
import { BySizePriceInput, SizePriceModel } from "./BySizePriceInput";
import { ColorOptionsInput } from "./ColorOptionsInput";
import { ProductCanvasModel, ProductModel } from "../store/ProductStore";
import { ManualSKUMatrixInput } from "./ManualSKUMatrixInput";
import { ProductCanvasInput } from "./ProductCanvasInput";
import { ProductImagesInput } from "./ProductImagesInput";
import classNames = require("classnames");
import { Spinner } from "./kit/Spinner";
import { SetupIncompleteMsg } from "./SetupIncompleteMsg";
import { useRouting } from "../routing/routes";
import { CanvasPlacementInput } from "./CanvasPlacementInput";

const shippingPackageOptions = [
  {value: undefined, label: 'Select Package Type'},
  ...keys(SHIPPING_PACKAGE_TYPES).map(value=>({value, label: SHIPPING_PACKAGE_TYPES[value]}))
];

const PRICING_LABELS: Record<ProductPricing, string> = {
  bySize: 'By Size',
  onePrice: 'One Price'
}
const pricingOptions = keys(PRICING_LABELS).map(value=>({value, label: PRICING_LABELS[value]}));


const DEFAULT_MODEL: ProductModel = ({
  skuAlgorithm: 'shirts',
  pricing: 'bySize',
  manualSkus: [],
  colors: [],
  canvases: [],
  images: [],
  sizes: [],
  dims: {},
  weight: {units: 'English'},
  irregularPackage: false,
  specifics: [],
  shipFromLocation: '',
  shipFromPostalCode: '',
  archived: false,
  printProvider: null,
  previewCanvasOptions: {
    positioning: null,
    canvasId: null
  },
  printLocation: 'Front Center',
  ebayTitle: ''
});

const PRODUCT_LABELS: Record<Exclude<keyof ProductModel, undefined>, string> = {
  skuAlgorithm: 'SKU algorithm',
  pricing: 'pricing',
  manualSkus: 'manual SKUs',
  colors: 'colors setup',
  canvases: 'canvases setup',
  images: 'product images',
  sizes: 'sizes',
  dims: 'package dimensions',
  onePrice: 'one price amount',
  userId: 'logged in user',
  categoryId: 'eBay category',
  code: 'product code',
  description: 'product description',
  irregularPackage: 'irregular package',
  name: 'product name',
  ebayTitle: 'eBay Title',
  packageType: 'package type',
  productId: 'product id',
  shippingPolicyProfileId: 'shipping policy',
  weight: 'package weight',
  specifics: 'category specific',
  colorSpecificName: 'color specific name',
  sizeSpecificName: 'color specific name',
  shipFromLocation: 'ship from location',
  shipFromPostalCode: 'ship from postal code',
  archived: 'archived',
  printProvider: 'Print Provider',
  previewCanvasOptions: 'Preview Canvas Options',
  printLocation: 'Print Location',
  dispatchTimeMax: 'Max Dispatch Days'
}

const SKU_ALORITHMS: Record<SKUAlgorithm, string> = {
  'shirts': 'Shirts',
  'manual': 'Manual Entry',
}
const SKUOptions = keys(SKU_ALORITHMS).map(value=>({value, label: SKU_ALORITHMS[value]}));

const productToModel = (product: Product<FirewardOutput>)=>({
  ...DEFAULT_MODEL,
  ...product,
  
  shipFromLocation: product.shipFromLocation || '',
  shipFromPostalCode: product.shipFromPostalCode || '',
})

class ProductEditorController {

  @observable saving = false;
  @observable product: ProductModel;
  @observable dirty = false;
  @observable error: string | null = null;
  
  @observable previewImageUrl: string|null = null
  
  private store: MainStore
  private props: ProductEditorProps
  private routing: ReturnType<typeof useRouting>
  
  constructor(product: ProductModel , store: MainStore, props: ProductEditorProps, routing: ReturnType<typeof useRouting>) {
    this.store = store;
    this.props = props;
    this.routing = routing;
    
    this.product = {
      ...DEFAULT_MODEL,
      ...product
    };
    
    

    //
    // reset the model if a new one is received from props
    //
    reaction(() => [this.propMatchProduct, props.productId],() => {
      if (
        this.propMatchProduct 
        && this.propMatchProduct.productId !== props.productId
        ) {
        this.reset(productToModel(this.propMatchProduct));
      }
    }, {fireImmediately: false});

    //
    // react to category change by rebuilding specifics
    // 
    reaction(() => this.category, cat => {
      if (cat) {

        this.product.specifics = cat.specifics
          .filter(spec => spec.name !== cat.colorSpecificName && spec.name !== cat.sizeSpecificName)
          .map(spec => ({
            name: spec.name, 
            value: this.product.specifics.find(s => s.name === spec.name)?.value || '',
            required: spec.required !== false
          }))
        ;

        this.product.colorSpecificName = cat.colorSpecificName;
        this.product.sizeSpecificName = cat.sizeSpecificName;
      }
    }, {fireImmediately: true})


    // 
    // save the model to localStorage on change
    //
    reaction(() => mapObject<any, any>(this.product, () => null), () => {
      this.product && store.products.saveModelToStorage(this.product)
    }, {delay: 300})

    //
    // react to product image upload
    //
    reaction(() => this.product.colors.find(c => c.imagePath), (c) => {

      const path = c?.imagePath
      
      if (path) {
        
        store.images.getImageUrl(path)
        .then(action(url => {
          
          //
          // if the URL changes because a new image is uploaded,
          // we need to reset all the positionings
          //
          if (this.previewImageUrl && this.previewImageUrl != url) {
            this.product.previewCanvasOptions.positioning = null;
            this.product.previewCanvasOptions.canvasId = null;
          }
          this.previewImageUrl = url;
          
        }));

      } else this.previewImageUrl = null;
      
    }, {delay: 300, fireImmediately: true});


    reaction(() => this.product.printProvider?.shipFromPostalCodeDefault, zip => {
      if (zip) this.product.shipFromPostalCode = zip;
    })

  }

  mapProductToModel = (p: Product<FirewardOutput>): ProductModel => {
    return productToModel(p)
  }

  //
  // Computed properties
  //

  @computed get originalProduct(): ProductModel {
    
    return  this.propMatchProduct 
      ? this.mapProductToModel(this.propMatchProduct) 
      : DEFAULT_MODEL
      ;
  }
  
  @computed get sizes() {
    return this.matchSizesToPrices(this.product.sizes).filter(isProductSizePrice)
  }
  @computed get loading() {
    return !this.products && !!this.props.productId
  }
  @computed get products() {
    return this.store.products.productList;
  }
  @computed get userId() {
    return this.store.user.user?.uid;
  }
  @computed get valid() {
    return isProduct(this.product)
  }
  @computed get propMatchProduct(): Readonly<Product<FirewardOutput>>|null {
    return this.props.productId && this.store.products.productList?.find(p => p.productId == this.props.productId) || null
  }
  @computed get notFound() {
    return !!(this.props.productId && this.store.products.productList && !this.propMatchProduct)
  }

  @computed get productNameDuplicate() {
    return !!this.store.products.productList?.find(
      p => p.name == this.product.name && p.productId != this.product.productId
    )
  }
  
  @computed get productImagesError() {
    return this.product.images.filter(isProductImage).length != this.product.images.length
      ? `Please finish setting up the product images.`
      : this.product.images.length > 11
      ? `No more than 11 additional images are allowed. You have ${this.product.images.length}.`
      : null;
  }

  @computed get positioningError() {
    return !this.product.previewCanvasOptions.positioning || !this.product.previewCanvasOptions.positioning.left || !this.product.previewCanvasOptions.positioning.scale || !this.product.previewCanvasOptions.positioning.top
      ? `please position the preview canvas`
      : null
  }

  @computed get onePriceError() {
    if (this.product.pricing!='onePrice') return null;
    
    return this.product.onePrice === undefined || isNaN(this.product.onePrice)
      ? `Please enter a price.`
      : this.product.onePrice <= 0
      ? `The price must be greater than 0.`
      : null
  }

  @computed get category() {
    return this.product.categoryId 
      && this.store.ebay.registeredCategories?.find(cat => cat.categoryId === this.product.categoryId) 
      || null
  }

  @computed get categoryOptions() {
    return this.store.ebay.registeredCategories?.map( cat => ({
      value: cat.categoryId, label: cat.customTitle || cat.name
    }))
  }

  @computed get zeroCategoriesError() {
    return this.categoryOptions && this.categoryOptions.length === 0
      ? `No ebay categories set up.`
      : null
      ;
  }

  @computed get shippingPolicyOptions() {
    return this.store.ebay.policies?.shippingPolicyProfiles.map(p=>({
      label: p.profileName, value: p.profileId
    }))
  }

  @computed get zeroShippingPoliciesError() {
    return this.shippingPolicyOptions && this.shippingPolicyOptions.length === 0
    ? `No shipping policies set up on eBay.`
    : null;
  }

  @computed get selectedCanvas() {
    return this.positinableCanvases
      .find(item => item.id == this.product.previewCanvasOptions.canvasId) || null
  }

  @computed private get positinableCanvases(): (ProductCanvasModel & {width: number, height: number} )[] {
    const positionable = (o: ProductCanvasModel): o is ProductCanvasModel & {width: number, height: number} => 
      !!(o.width && o.height)
    return this.product.canvases
    .filter(positionable)
  }

  @computed get canvasOptions() {
    return this.positinableCanvases
      .map(o=>({value: o.id, label: o.width + ' x ' + o.height}))
  }

  //
  // Actions
  //

  @action reset(product: ProductModel) {
    this.product = clone(product);
  }


  @action save = async (userId: string) => {
    
    this.dirty = true;
    const product: ProductModel = toJS({...this.product, userId})
    product.sizes = this.matchSizesToPrices(product.sizes);
    product.archived = product.archived || false;
    
    const err = (...str: string[]) => this.store.alert.error(str)

    if (product.pricing !== 'onePrice' && typeof product.onePrice!=='number') {
      product.onePrice = 0;
    }
    
    if (product.skuAlgorithm !== 'manual') {
      product.manualSkus = product.manualSkus.filter(isProductSKU)
    }
    
    product.weight.units = product.weight.units || 'English';


    if (!product.shipFromPostalCode && !product.shipFromLocation) {
      err(`Please supply either a postal code or location.`)
      return;
    }


    product.manualSkus = product.manualSkus.map(man => ({...man, sku: (man.sku || '').trim()}));
    if (product.skuAlgorithm === 'manual') {
      if (product.manualSkus.some(man => !(man.sku || '').trim())) {
        err(`Manual SKU form contains empty items.`);
        return;
      }
    }

    if (product.sizes.some(s => flatten(product.canvases.map(c => c.productSizeIds)).indexOf(s.id) == -1)) {
      err(`Please make sure that all sizes have exactly one canvas assigned.`);
      return;
    }

    if (this.productNameDuplicate) {
      return err(`Product name must be unique across all your products.`);
    }

    try {
      if (!validateProduct(product)) return;
    } catch (error: unknown) {
      if (isValidationMapError(error)) {
        console.log(error)
        this.store.alert.error(error.messages(PRODUCT_LABELS), 'Please fix the errors before saving.');
      } else {
        this.store.alert.error([errorToString(error)], 'An error has occurred.');
      }
      return;
    }

    if (this.productImagesError) {
      this.store.alert.error([this.productImagesError], 'An error has occurred.');
      return;
    }

    if (product.dispatchTimeMax === undefined) {
      delete product.dispatchTimeMax;
    }

    this.saving = true;

    const originalProductId = product.productId;
    product.name = product.name.trim();
    product.code = product.code.trim();

    this.store.products.saveProduct(userId, product)
      .then(action(next => {
        this.dirty = false;
        this.saving = false;
        this.product.productId = next.productId;
        this.store.products.deleteModelStorage(originalProductId || null);
        this.store.alert.add({
          'type': 'success', message: 'Product saved.'
        });
        this.routing.products.navigate();        
      }))
      .catch(action(e => {
        this.saving = false;
        this.store.error.captureException(e);
        this.store.alert.error([errorToString(e)], 'Hmm... we have encountered some errors.');
      }));
    
  }

  @action setPricingType = (type: ProductPricing) => {
    this.product.pricing = type;
  }

  private matchSizesToPrices(sizes: SizePriceModel[]): SizePriceModel[] {
    return sizes.map(s => ({
      ...s,
      price: s.price === undefined && this.product.pricing == 'onePrice'
        ? this.product.onePrice : s.price
    }));
  }

  specRequired = (specName: string) => {
    return this.category?.specifics.find(spec => spec.name == specName)?.required 
      || false
  }
  
}

type ProductEditorProps = {
  productId?: string;
};

export const ProductEditor = observer(function ProductEditor(props: ProductEditorProps) {
  
  const routing = useRouting();
  const store = useStore();
  const [ self, setSelf ] = React.useState<ProductEditorController|null>(null);
  const [ loadError, setLoadError ] = React.useState<any>(null);

  React.useEffect(() => {
    const uid = store.user.state?.uid;
    if (!uid) return;
    if (!props.productId) {
      setSelf(new ProductEditorController(
        store.products.getModelFromStorage(null) || DEFAULT_MODEL,
        store, props, routing
      ))
    } else {
      const stored = store.products.getModelFromStorage(props.productId);
      if (stored) {
        setSelf(new ProductEditorController(
          stored,
          store, props, routing
        ));
      } else {
        store.products.getProduct(props.productId, uid)
        .then(p => {
          setSelf(new ProductEditorController(
            p ? productToModel(p) : DEFAULT_MODEL,
            store, props, routing
          ));
        })
        .catch( e => {
          store.alert.error([errorToString(e)]);
          store.error.captureException(e, {extra: {place: 'store.products.getProduct'}});
          setLoadError(e);
        })
  
      }
    }
    
    return ()=>{}
  }, [props.productId, store.user.state?.uid]);


  const userId = store.user.state?.uid;
  if (!userId) return <Login/>;

  if (!self) {
    if (loadError) return <div>Could not load product.</div>
    else return <div className="h-64"><SpinnerOverlay /></div>
  }

  const dirty = self.dirty;

  const positiveFloat = (val?: number|undefined)=>val === undefined ? `Please entere a value` : isNaN(val) ? `Please enter a valid number.` : val <= 0 ? `Please enter a number greater than 0.` : null
  
  
  const incompleteMsg = SetupIncompleteMsg(store, routing)
  if (incompleteMsg) return incompleteMsg;

  
  return <div className="ProductEditor">
    <SpinnerOverlay show={self.loading || self.saving}/>
    {
      self.notFound
      ? <NotFoundDetail 
          title="Product not found" 
          text="There appears to be something wrong with the URL. Perhaps the product has been deleted." 
        />
      : <div className="cmy-2">

          <TextInput
            label="Product"
            value={self.product.name || ''}
            onChange={val => self.product.name = val}
            className="mb-3"
            dirty={dirty}
            errorFunc={val => !(val?.trim()) ? `Please name the product.` : self.productNameDuplicate ? `must be unique` : null}
          />

          <TextInput
            className="my-3"
            label="eBay Title"
            value={self.product.ebayTitle || ''}
            onChange={val => self.product.ebayTitle = val || ''}
            dirty={self.dirty}
            errorFunc={val => (val?.trim()) ? null : `required`}
          />

          <TextInput
            label="Product Code"
            hint="to form SKUs"
            value={self.product.code || ''}
            onChange={val => self.product.code = val}
            filter={cleanupCode}
            maxLength={10}
            className="my-3"
            dirty={dirty}
            errorFunc={val => val ? null : `The code is needed to build SKU's.`}
          />

          <TextArea
            value={self.product.description}
            onChange={val => self.product.description = val || ''}
            label="Description"
            className="my-3"
            dirty={dirty}
            error={self.product.description ? null : `Please enter a product description.`}
          />

          { !self.categoryOptions
            ? <Spinner text="loading categories" />
            : <Select
              dirty={dirty}
              label="Ebay Category"
              value={self.product.categoryId}
              onChange={val => self.product.categoryId = val}
              options={self.categoryOptions}
              error={self.zeroCategoriesError || !self.product.categoryId && `Select a category.`}
            />
          }

          {
            self.product.specifics.length > 0
            ? <div className="my-4 max-w-md">
                <h3 className="mb-2 mt-4">Specifics Values</h3>
                <ul>
                  {
                    self.product.specifics.map(spec => <li className="my-2" key={spec.name}>
                      <TextInput 
                        redAsterisk={self.specRequired(spec.name)} 
                        label={spec.name} 
                        value={spec.value || ''} 
                        onChange={val => spec.value = val || ''} 
                        dirty={self.dirty} 
                        errorFunc={val => val?.trim() || !spec.required ? null : `please enter a value`
                      }/>
                    </li>)
                  }
                </ul>
              </div>
            : null
          }

          <div className="my-12 border-t border-gray-200"></div>

          <Select 
            label="Package Type"
            onChange={val => self.product.packageType = val}
            value={self.product.packageType}
            options={shippingPackageOptions} 
            className="mb-4"
            dirty={dirty}
            error={self.product.packageType ? null : `Please select a package type.`}
          />



          <div className="label text-sm">Package Dimensions</div>
          <div className={classNames(
            'sm:flex cm-2'
          )}>
            <FloatInput
              positive
              dirty={self.dirty}
              unitsPostfix="W in"
              value={self.product.dims.width}
              onChange={val => self.product.dims.width = val}
              errorFunc={positiveFloat}
            />
            <FloatInput
              positive
              dirty={self.dirty}
              unitsPostfix="H in"
              value={self.product.dims.height}
              onChange={val => self.product.dims.height = val}
              errorFunc={positiveFloat}
            />
            <FloatInput
              positive
              dirty={self.dirty}
              unitsPostfix="D in"
              value={self.product.dims.depth}
              onChange={val => self.product.dims.depth = val}
              errorFunc={positiveFloat}
            />
          </div>

          <Checkbox
            label="Irregular Package"
            value={self.product.irregularPackage}
            onChange={val => self.product.irregularPackage = val}
          />

          <div className="my-3">
            <div className="label text-sm mb-2">Package Weight</div>
            <div className="flex cm-2">
              <FloatInput
                positive
                dirty={self.dirty}
                unitsPostfix="lbs"
                value={self.product.weight.major}
                onChange={val => self.product.weight.major = val}
                errorFunc={val => !isNumber(val) ? `required` : val < 0 ? `enter a positive number` : null}
              />
              <FloatInput
                positive
                dirty={self.dirty}
                unitsPostfix="oz"
                value={self.product.weight.minor}
                onChange={val => self.product.weight.minor = val}
                errorFunc={positiveFloat}
              />
            </div>
          </div>

          <div className="my-12 border-t border-gray-200"></div>
          <h2 className="font-bold text-base">Shipping Settings</h2>

          <Select
            label={PRODUCT_LABELS.printProvider}
            value={self.product.printProvider?.id}
            onChange={id => self.product.printProvider = store.settings.standardSettings?.providers?.find(p => p.id == id) || null}
            options={store.settings.standardSettings?.providers?.map(p => ({label: p.name, value: p.id})) || []}
            dirty={dirty}
            className="mb-4"
            error={!store.settings.standardSettings?.providers?.length ? `please set up in settings` : self.product.printProvider ? null : `please select one`}
          />   
            
          <TextInput
            label="Ship from Postal Code"
            value={self.product.shipFromPostalCode || ''}
            onChange={val => self.product.shipFromPostalCode = val || ''}
            dirty={self.dirty}
            errorFunc={val => val || self.product.shipFromLocation ? null : `required`}
          />

          {/* <TextInput
            label="Ship from location"
            value={self.product.shipFromLocation || ''}
            onChange={val => self.product.shipFromLocation = val || ''}
            dirty={self.dirty}
            errorFunc={val => val || self.product.shipFromPostalCode ? null : `enter location OR postal code`}
          /> */}

          

                       

          { !self.shippingPolicyOptions
            ? <Spinner text="loading shipping options" />
            : <Select
                dirty={dirty}
                label="Shipping Policy"
                value={self.product.shippingPolicyProfileId}
                onChange={val => self.product.shippingPolicyProfileId = val}
                options={self.shippingPolicyOptions}
                error={self.zeroShippingPoliciesError || !self.product.shippingPolicyProfileId && `Select a policy.`}
              />
          }

          


          <div className="my-12 border-t border-gray-200"></div>


          
          <h3 className="label text-sm">Additional Product Images</h3>
          <div className="text-xs muted">These are additional images you wish to use, such as sizing charts, etc.</div>
          
          <ProductImagesInput
            max={11}
            dirty={dirty}
            className='my-2'
            value={self.product.images}
            onChange={action(val => self.product.images = val)}
            error={self.productImagesError}
          />


          <div className="my-12 border-t border-gray-200"></div>

          
          <Select
            label="Pricing"
            options={pricingOptions}
            value={self.product.pricing}
            onChange={val=>self.setPricingType(val || 'bySize')}
          />

          {
            self.product.pricing == 'onePrice'
            ? <FloatInput
                label="Amount"
                unitsPrefix="$"
                value={self.product.onePrice}
                onChange={val => self.product.onePrice = val}
                error={self.onePriceError}
              />
            : null
          }

          <BySizePriceInput
            dirty={self.dirty}
            fixedPrice={self.product.pricing==='onePrice' ? (self.product.onePrice || 0) : undefined}
            value={self.product.sizes}
            onChange={val => self.product.sizes = val}
            error={self.sizes.length == 0 ? `At least one is required.` : null}
          />

          <ColorOptionsInput
            dirty={self.dirty}
            value={self.product.colors}
            sizes={self.sizes}
            onChange={colors => self.product.colors = colors}
            error={self.product.colors.length == 0 ? `At least one is required.` : null}
          />

          <div className="my-4">
            <Select
              label="Product SKU Generation"
              value={self.product.skuAlgorithm}
              onChange={val => val && (self.product.skuAlgorithm = val)}
              options={SKUOptions}
              error={!self.product.skuAlgorithm ? `please select one` : null}
              dirty={dirty}
            />
          </div>
          
          { 
            self.product.skuAlgorithm == 'manual' &&
              <ManualSKUMatrixInput
                dirty={dirty}
                value={self.product.manualSkus}
                colorOptions={self.product.colors.filter(isProductColorOption)}
                sizes={self.sizes}
                onChange={val => self.product.manualSkus = val}
              />
          }

            <ProductCanvasInput
              dirty={dirty}
              previewImageUrl={self.previewImageUrl}
              value={self.product.canvases}
              onChange={action(canvases => self.product.canvases = canvases)}
              sizes={self.sizes}
              
            />
            <TextInput
              className="my-4"
              label={PRODUCT_LABELS.printLocation}
              value={self.product.printLocation || DEFAULT_MODEL.printLocation}
              onChange={val => self.product.printLocation = val || ''}
              errorFunc={val => !(val?.trim()) ? `required` : null}
              dirty={self.dirty}
            />
            { self.previewImageUrl &&
                <div className="mt-3">

                  <Select 
                    label="Preview Canvas Placement"
                    options={self.canvasOptions}
                    value={self.product.previewCanvasOptions.canvasId}
                    onChange={val => {self.product.previewCanvasOptions = {canvasId: val || null, positioning: null}}}
                  />

                  { self.selectedCanvas &&
                    <CanvasPlacementInput
                      className="w-64"
                      previewImageUrl={self.previewImageUrl}
                      value={self.product.previewCanvasOptions.positioning}
                      canvas={self.selectedCanvas}
                      onChange={val => self.product.previewCanvasOptions.positioning = val}
                    />
                  }

                  {
                    self.positioningError && self.dirty && <div className="error text-xs">
                      {self.positioningError}
                    </div>
                  }

                </div>
              }
          <ButtonBar>
            {/* <Button outline>Cancel</Button> */}
            <Button onClick={() => self.reset(self.originalProduct)} outline>Reset</Button>
            <Button spinner={self.saving} onClick={() => self.save(userId)}>Save</Button>
          </ButtonBar>
      </div>
    }
  </div>
})
