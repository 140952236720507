import React = require('react');
import { useStore } from '../store/StoreContext';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { EbayCategory, EbayTradingError, EbayTradingErrorItem, isEbayTradingError } from '@root/shared/lib/clients/EbayTradingTypes';
import { useErrorState } from '../util/useErrorState';
import { Spinner } from './kit/Spinner';
import { Button } from './kit/Button';
import { EbayCategoryItem } from './EbayCategoryListItem';
import { SpinnerOverlay } from './kit/SpinnerOverlay';
import { Crumbs } from './kit/Crumbs';
import { Login } from './Login';
import { useRouting } from '../routing/routes';
import classNames = require('classnames');
import { Route, Routes, useParams } from 'react-router-dom';


export type EbayCategoryPickerProps = {
  // parents: EbayCategory[]  
}

export const EbayCategoryPicker = observer(function EbayCategoryPicker(props: EbayCategoryPickerProps) {
  
  const store = useStore();
  const routing = useRouting();

  const [ cats, setCats ] = useState<EbayCategory[]|null>(null);
  const [ errors, setEbayErrors ] = useState<EbayTradingErrorItem[]|null>(null);
  const [err, setErr] = useErrorState();
  const [ loading, setLoading ] = useState<boolean>(true);

  const [ parents, setParents ] = useState<EbayCategory[]>([]);
  
  const viewAllNodes = true;
  
  
  const fetchDownCats = React.useCallback((parent: EbayCategory)=>{
    setParents([...parents, parent]);
  }, [parents]);
  
  const fetchCrumbCat = React.useCallback((parent: EbayCategory|null|undefined) => {
    if (!parent) return setParents([]);
    const i = parents.findIndex(p => p.CategoryID == parent.CategoryID);
    if (i === -1) return;
    setParents(parents.slice(0, i + 1));
  }, [parents]);

  const fetchUpCats = React.useCallback(()=>{
    const ps = [...parents];
    ps.pop();
    setParents(ps);
  }, [parents]);

  const parent = [...parents].pop()
  const levelLimit = parent  && (parent.CategoryLevel + 1) || 1;


  useEffect(() => {

    const parentId = [...parents].pop()?.CategoryID;
    const categoryParent = parentId ? parentId : undefined;
    
    setErr(null);
    setEbayErrors(null);
    setLoading(true);

    store.ebay.listCategories({categoryParent, viewAllNodes, levelLimit})
    .then(res => {
      
      setLoading(false);
      setCats(res);
      store.browser.scrollIntoView('#category-top-anchor')

    }).catch(e => {
      setLoading(false);
      store.error.captureException(e, {extra: {operation: 'EbayCategories::getCategories effect'}})
      console.log('cat error', e.details)
      if (isEbayTradingError(e.details)) setEbayErrors(e.details.response.Errors);
      else setErr(e)
    })
  }, [parents.map(p=>p.CategoryID).join('-')]);

  if (!store.user.state) return <Login />;
  const visibleCats = cats?.filter(c=>c.CategoryID != parent?.CategoryID);

  // return <Routes>
  //   <Route path=":categoryId/:levelId" element={<EbayCategoryPicker parents={parents}/>} />
  //   <Route path="" element={
  return <div className="EbayCategoriesEditor">
        <div className="my-2">
          
        </div>
        <a id="category-top-anchor"/>
        { parents.length > 0 &&
          <div className="flex items-baseline my-2">
            <button onClick={() => fetchUpCats()} disabled={loading} className="mr-4 cursor-pointer text-sm leading-5 font-medium text-indigo-600 truncate flex items-center">
              Up <svg className="ml-1 h-4 w-4" fill="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
            </button>
            <Crumbs 
              items={
                parents.length > 0
                ? [{label: 'Top', key: 0}, ...parents.map(p=>({label: p.CategoryName, key: p.CategoryID}))]
                : []
              } 
              onCrumbClick={c => fetchCrumbCat(parents.find(p => p.CategoryID == c.key))}
            />
          </div>
        }
        {
          <div className={classNames("bg-white shadow overflow-hidden sm:rounded-md relative max-w-xl", {
            "min-h-16": loading
          })}>
            {
              loading && <SpinnerOverlay/>
            }
            {
              visibleCats && <ul>
                {visibleCats.map((category, i) => 
                  <EbayCategoryItem 
                    key={category.CategoryID} 
                    category={category} 
                    onSelect={() => fetchDownCats(category)} 
                    first={i === 0}
                    last={i === visibleCats.length - 1} 
                  />)}
              </ul>
            }
          </div>
        }

        <pre className="text-red-600">
          {errors && JSON.stringify(errors, null, '  ')}
        </pre>
        <pre className="text-red-600">
          {err && JSON.stringify(err, null, '  ')}
        </pre>
      </div>
  //   } />
  // </Routes>
  
})