export type WardFieldValue = { isEqual: (other: WardFieldValue) => boolean };
export type WardTimestamp = {seconds: number, nanoseconds: number, toDate: ()=>Date, isEqual: (other: WardTimestamp)=>boolean, toMillis: ()=>number, valueOf: ()=>string};
export function isTimestamp(v: any): v is WardTimestamp { return !!v && (typeof v=='object') && !!v.toDate && !!v.toMillis && (typeof v.nanoseconds=='number') && (typeof v.seconds=='number')};
export type WardGeoPoint = { latitude: number, longitude: number, isEqual: (other: WardGeoPoint)=>boolean, toJSON: ()=>{latitude: number, longitude: number} }
export function isGeoPoint(v: any): v is WardGeoPoint {  return !!v && (typeof v=='object') && (typeof v.isEqual=='function')  && (typeof v.latitude=='number') && (typeof v.longitude=='number') };
export type FirewardOutput = /** what you get from DB */ { timestamp: WardTimestamp|null; number: number; array: never };
export type FirewardInput = /** what you send to DB */ { timestamp: WardTimestamp|Date|WardFieldValue; number: number|WardFieldValue; array: WardFieldValue };
export type FirewardTypes = FirewardInput | FirewardOutput;

export type AuthData<Types extends FirewardTypes = FirewardTypes> = {
  uid: string
  email?: string
  emailVerified: boolean
  isAnonymous: boolean
  photoURL?: string
  providerId?: string
  phoneNumber?: string
  tenantId?: string
  displayName?: string
}
export type EbayTradingListingDuration<Types extends FirewardTypes = FirewardTypes> = "Days_1" | "Days_10" | "Days_120" | "Days_14" | "Days_21" | "Days_3" | "Days_30" | "Days_5" | "Days_60" | "Days_7" | "Days_90" | "GTC"
export type PrintProvider<Types extends FirewardTypes = FirewardTypes> = {
  shipFromPostalCodeDefault?: string
  shipFromLocation?: string
  name: string
  code: string
  id: string
}
export type StandardSettings<Types extends FirewardTypes = FirewardTypes> = {
  description: {
    feedback: string
    contactUs: string
  }
  paymentPolicy: {
    name: string
    ebayPolicyId: Types['number']
  }
  returnPolicy: {
    name: string
    ebayPolicyId: Types['number']
  }
  ebayListingDuration: EbayTradingListingDuration<Types>
  providers?: PrintProvider<Types>[] | Types['array']
}
export type User<Types extends FirewardTypes = FirewardTypes> = {
  uid: string
  auth: AuthData<Types>
  firstName?: string
  lastName?: string
}
export type UserDropbox<Types extends FirewardTypes = FirewardTypes> = {
  connectedOnTimestamp: Types['timestamp']
  accountEmail?: string
}
export type UserDropboxAccount<Types extends FirewardTypes = FirewardTypes> = {
  accessToken: string
  accountId: string
  accountEmail?: string
  lastCursor: string | null
}
export type DropboxHookData<Types extends FirewardTypes = FirewardTypes> = {
  accountId: string
  time: Types['timestamp']
}
export type OrderDeskAccount<Types extends FirewardTypes = FirewardTypes> = {
  apiKey: string
  storeId: string
  connectedOnTimestamp: Types['timestamp']
}
export type UserEbay<Types extends FirewardTypes = FirewardTypes> = {
  connectedOnTimestamp: Types['timestamp']
}
export type EbayAuth<Types extends FirewardTypes = FirewardTypes> = {
  username?: string
  sessionId?: string
  authToken?: string
  authTokenExpires: Types['timestamp']
}
export type RegisteredEbayCategory<Types extends FirewardTypes = FirewardTypes> = {
  customTitle?: string | null
  name: string
  categoryId: Types['number']
  userId: string
  categoryLevel: Types['number']
  sizeSpecificName: string
  colorSpecificName: string
  specifics: {
    name: string
    id: string
    required?: boolean
  }[] | Types['array']
  archived?: boolean
}
export type ProductGroupColor<Types extends FirewardTypes = FirewardTypes> = {
  id: string
  colorIds: string[] | Types['array']
  productId: string
  previewColorId: string
}
export type ProductGroup<Types extends FirewardTypes = FirewardTypes> = {
  id: string
  name: string
  userId: string
  lastWrite: Types['timestamp']
  productColors: ProductGroupColor<Types>[] | Types['array']
  ebayStoreCategoryId?: string
  ebayStoreCategory2Id?: string
  archived: boolean
}
export type ProductPricing<Types extends FirewardTypes = FirewardTypes> = "bySize" | "onePrice"
export type ProductSizePrice<Types extends FirewardTypes = FirewardTypes> = {
  size: string
  price: Types['number']
  id: string
}
export type ProductColorOption<Types extends FirewardTypes = FirewardTypes> = {
  id: string
  name: string
  code: string
  sizeIds: string[] | Types['array']
  imagePath: string
}
export type ProductSKU<Types extends FirewardTypes = FirewardTypes> = {
  productColorOptionId: string
  productSizeId: string
  sku: string
}
export type PositioningRule<Types extends FirewardTypes = FirewardTypes> = 'center' | 'top' | 'top-11.5' | 'top-12' | 'top-12.5' | 'top-13' | 'mirrored'
export type ProductCanvasPositioning<Types extends FirewardTypes = FirewardTypes> = {
  top: Types['number']
  left: Types['number']
  scale: Types['number']
}
export type ProductCanvas<Types extends FirewardTypes = FirewardTypes> = {
  id: string
  productSizeIds: string[] | Types['array']
  positioningRule: PositioningRule<Types>
  width: Types['number']
  height: Types['number']
}
export type ProductImage<Types extends FirewardTypes = FirewardTypes> = {
  id: string
  path: string
}
export type SKUAlgorithm<Types extends FirewardTypes = FirewardTypes> = 'shirts' | 'manual'
export type Product<Types extends FirewardTypes = FirewardTypes> = {
  archived: boolean
  productId: string
  userId: string
  name: string
  ebayTitle: string
  code: string
  description: string
  categoryId: Types['number']
  shippingPolicyProfileId: Types['number']
  packageType: string
  irregularPackage: boolean
  sizes: ProductSizePrice<Types>[] | Types['array']
  colors: ProductColorOption<Types>[] | Types['array']
  printLocation: string
  canvases: ProductCanvas<Types>[] | Types['array']
  previewCanvasOptions: {
    canvasId: string
    positioning: ProductCanvasPositioning<Types>
  }
  onePrice: Types['number']
  pricing: ProductPricing<Types>
  manualSkus: ProductSKU<Types>[] | Types['array']
  images: ProductImage<Types>[] | Types['array']
  skuAlgorithm: SKUAlgorithm<Types>
  specifics: {
    name: string
    value?: string
    required: boolean
  }[] | Types['array']
  colorSpecificName: string
  sizeSpecificName: string
  shipFromLocation: string | null
  shipFromPostalCode: string
  printProvider: PrintProvider<Types>
  dispatchTimeMax?: Types['number']
  dims: {
    width: Types['number']
    height: Types['number']
    depth: Types['number']
  }
  weight: {
    major: Types['number']
    minor: Types['number']
    units: 'English'
  }
}
export type OAuth<Types extends FirewardTypes = FirewardTypes> = {
  accessToken: string
}
export type UserImageType<Types extends FirewardTypes = FirewardTypes> = 'preview' | 'product'
export type UserImage<Types extends FirewardTypes = FirewardTypes> = {
  id: string
  path: string
  type: UserImageType<Types>
  ext: string
  created: Types['timestamp']
}
export type ProductListingSKUs<Types extends FirewardTypes = FirewardTypes> = {
  ebayCustomLabel: string
  ebaySKU: string
  orderDeskSKU: string
  orderDeskName: string
  printSKU: string
}
export type ProductListing<Types extends FirewardTypes = FirewardTypes> = {
  id: string
  productId: string
  price: Types['number']
  size: {
    code: string
    label: string
  }
  color: {
    code: string
    label: string
    previewImagePath: string
    id: string
  }
  canvas: ProductCanvas<Types>
  previewOptions: {
    canvas: ProductCanvas<Types>
    positioning: ProductCanvasPositioning<Types>
    previewImagePath: string
  }
  printLocation: string
  designId: string
  designCode: string
  productEbayTitle: string
  imageName: string
  orderDeskId: string | Types['number'] | null
  ebayListed: Types['timestamp'] | null
  completed: boolean
  publicationError: string | null
  disabled: boolean
  groupId: string
  lastChange: Types['timestamp']
  skus: ProductListingSKUs<Types>
  specifics: {
    name: string
    value: string
  }[] | Types['array']
  colorSpecificName: string
  sizeSpecificName: string
  previewImageUrl: string
  printProvider: PrintProvider<Types>
  mainListingPreviewUrl: string
}
export type ProductDesign<Types extends FirewardTypes = FirewardTypes> = {
  id: string
  groupId: string
  designCode: string
  completed: Types['timestamp'] | null
  archived: boolean
  sizeInBytes: Types['number']
  bucketPath: string
  queuedForPublication: boolean
  processing: boolean
  publicationError: string | null
  title: string
  description?: string
  designName: string
  lastChange?: Types['timestamp'] | null
}
export type CloudDriveProvider<Types extends FirewardTypes = FirewardTypes> = 'dropbox'
export type DesignQueueItem<Types extends FirewardTypes = FirewardTypes> = {
  id: string
  cloudPath: string
  cloudDisplayPath: string
  driveProvider: CloudDriveProvider<Types>
  sizeInBytes: Types['number']
  queuedForProcessing: boolean
  processing: boolean
  error: string | null
  lastWrite: Types['timestamp']
}










