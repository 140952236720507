import React = require("react")
import { ClassValue } from "classnames/types";
import classNames = require("classnames");
import { randomId } from "@root/shared/lib/x";
import { action } from "mobx";


type Option<T> = {label: string, value: T}
export type SelectProps<T> = {
  options: Option<T>[]
  value: T
  onChange: (value: T|undefined) => void 
  label?: string
  className?: ClassValue
  dirty?: boolean
  error?: string|null|false|""
  inputId?: string
}

export const Select = function<T>(props: SelectProps<T>) {

  const [ inputId ] = React.useState<string>(props.inputId || randomId('Select'));
  const [ dirty, setDirty ] = React.useState<boolean>(false);
  
  React.useEffect(() => {
    if (!props.dirty) setDirty(false);
  }, [props.dirty]);

  const showError = dirty || props.dirty;
  const error = showError && props.error;

  const os:{o: Option<T|undefined>, key: string}[] = props.options.map((o, i)=>({
    o, key: o.label + `${i}`
  }));
  
  const valueKey = os.find(o=>o.o.value===props.value)?.key || ''
  if (valueKey==='') { // create an undefined value only if the value provided is not found
    os.unshift({
      key: '', o: {label: '', value: undefined}
    })
  }

  const onChange = (key:string) => action(props.onChange)(os.find(o => o.key === key)?.o.value);

  return <div className={classNames(props.className)}>
    {
      props.label &&
        <label htmlFor={inputId} className="block text-sm leading-5 font-medium text-gray-700">
          {props.label}
        </label> 
    }
    <select id={inputId} value={valueKey} onChange={e=>onChange(e.target.value)} className={classNames([
      "mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 focus:outline-none sm:text-sm sm:leading-5",
      {
        "border-gray-300 focus:shadow-outline-blue focus:border-blue-300": !error,
        "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red": !!error,
      }
    ])}>
      {
        os.map(o=><option key={o.key} value={o.key} >{o.o.label}</option>)
      }
    </select>
    {
    error && <p className="mt-1 text-xs text-red-600" id={inputId}>
      {error}
    </p>
  }
  </div>
}
