import '../../../css/index.css';
import React = require('react');
import ReactDOM = require('react-dom');
import { makeLiveWebDependencies } from './liveWebDependencies';
import { MainLayout } from './components/MainLayout';
import {User} from '@root/shared/lib/database';
import { createStore } from './store/store';
import { StoreProvider } from './store/StoreContext';

// import { View, Router } from 'react-navi'
import { RouteSchema } from './routing/routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { WebEnv } from './env/WebEnv';

export function main(env: WebEnv) {
  const deps = makeLiveWebDependencies(env);
  const store = createStore(deps);
  
  
  const App = () => <StoreProvider store={store}>
    <Router>
      <MainLayout>
        {RouteSchema}
      </MainLayout>
    </Router>
  </StoreProvider>;
  
  const el = document.getElementById('app');
  ReactDOM.render(<App/>, el);
}
