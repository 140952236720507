import { StringInputProps, StringInput } from "./StringInput"
import React = require("react")
import { observer } from "mobx-react-lite"

type FloatInputProps = {
  positive?: boolean
  value?: number|null
  onChange?: (value: number|undefined) => void
  errorFunc?: (value: number|undefined) => string|undefined|null|false|''
  precision?: 0|1|2|3|4|5|6|7|8|9
  currency?: boolean
} & Omit<StringInputProps, 'type'|'parser'|'value'|'onChange'|'errorFunc'>

export const FloatInput = observer(function FloatInput(props: FloatInputProps) {
  const [ raw, setRaw ] = React.useState<string>(props.value?.toString() || '');
  
  const value:string|undefined|null = !props.value || parseFloat(raw) === props.value || isNaN(props.value)
    ? raw 
    : props.value.toString();

  const onChange = (nextRaw: string|undefined) => {
    nextRaw = nextRaw || '';
    setRaw(nextRaw);
    const next = parseFloat(nextRaw);
    console.log(nextRaw, next)
    props.onChange && props.onChange(next)
  }

  const filter = (val: string) => {
    const next = val.replace(/[^0-9.]/g, '').replace(/(\..*?)\.$/g, '$1');
    const p = props.precision !== undefined 
      ? props.precision 
      : props.currency
      ? 2
      : undefined
    ;

    return p === undefined
      ? next
      : p === 0
      ? next.replace(/\..*$/, '')
      : next.replace(new RegExp(`\\.(\\d{${p}})\\d*$`), '.$1')
  }

  return <StringInput
    {...props}
    errorFunc={val => props.errorFunc && props.errorFunc(val===undefined ? undefined : parseFloat(val)) || null}
    onChange={onChange}
    type="number" 
    value={value || ''}
    filter={filter}
  />
})
