import React = require("react")
import classNames = require("classnames")

export type ButtonBarProps = React.PropsWithChildren<{
  left?: boolean
}>
export const ButtonBar = function ButtonBar(props: ButtonBarProps) {
  return <div className="mt-8 border-t border-gray-200 pt-5">
    <div className={classNames([
      "flex cm-3",
      {
        "justify-end": !props.left,
        "justify-start": props.left,
      }
    ])}>
      {props.children}
    </div>
  </div>
}
