import React = require('react');
import { observer } from 'mobx-react-lite';
import { observable, action, autorun, computed, toJS } from 'mobx';
import { useController } from '../util/mobxUtils';
import classNames = require('classnames');
import { ClassValue } from 'classnames/types';
import { ProductGroupColor, Product, FirewardOutput } from '@root/shared/lib/database';
// import {NoArraySentinelTypes} from '@root/shared/lib/guards/commonGuards';

import { TextInput } from './kit/TextInput';
import { clone, randomId } from '@root/shared/lib/x';
import { Checkbox } from './kit/Checkbox';
import { UserImage } from './kit/UserImage';
import { RadioInput } from './kit/Radio';
import { NoArraySentinelTypes } from '@root/shared/src/schemas/productGuards';


export type ProductGroupColorInputProps = {
  className?: ClassValue
  value: ProductGroupColor<FirewardOutput>[]
  onChange: (value: ProductGroupColor<FirewardOutput>[]) => void
  products: Product<FirewardOutput>[]
  dirty?: boolean  
}
const upsert = (val: string, arr: string[]) => arr.includes(val) ? arr : [...arr, val];

class ProductGroupColorInputController {
  
  @observable private props: ProductGroupColorInputProps;

  constructor(props: ProductGroupColorInputProps) {
    this.props = props;
  }
  isPreviewColor = (productId: string, colorId: string) => {
    return this.props.value.find(m => m.productId == productId)?.previewColorId == colorId
  }
  @computed get error() {
    const error = this.props.value.some( p => p.colorIds.length > 0)
      ? null
      : `Please select some products colors.`;
    return this.dirty && error;
  }
  @computed get dirty() {
    return this.props.dirty;
  }
  @action setProps = (props: ProductGroupColorInputProps) => {
    this.props = props;
  }
  @action selectPreviewColor = (productId: string, colorId: string) => {
    const model = this.props.value.find(m => m.productId == productId);

    //
    // if not selected for publication, then select it
    //
    if (!model) this.onColorCheck(productId, colorId, true);
    else this.updateModel({...model, previewColorId: colorId});

  }
  
  @action onColorCheck = (productId: string, colorId: string, checked: boolean) => {
    
    const model = clone(this.props.value.find(prev => prev.productId == productId) || {
      id: randomId('group-color'), 
      productId, 
      colorIds: [colorId],
      previewColorId: colorId
    });

    if (checked) {
      if (!model.colorIds.includes(colorId)) model.colorIds.push(colorId);     
    } else {
      model.colorIds = model.colorIds.filter(id => id !== colorId)
    }

    if (model.colorIds.length > 0 && !model.colorIds.includes(model.previewColorId)) {
      model.previewColorId = model.colorIds[0];
    }
    
    this.updateModel(model);
  }

  @action updateModel = (model: ProductGroupColor<FirewardOutput>) => {
    const pi = this.props.value.findIndex( prev => prev.productId == model.productId );
    const value = [...this.props.value];
    if (pi > -1) {
      value[pi] = model;
    } else {
      value.push(model);
    }
    model.colorIds = toJS(model.colorIds)
    
    this.props.onChange(value.filter(m => m.colorIds.length > 0))
  }

  colorChecked = (productId: string, colorId: string) => {
    return !!this.props.value.find(p=>p.productId == productId)?.colorIds.includes(colorId)
  }
  
}

export const ProductGroupColorInput = observer(function ProductGroupColorInput(props: ProductGroupColorInputProps) {
  const self = useController(()=>new ProductGroupColorInputController(props), props);
  return <div className={classNames('ProductGroupColorInput', props.className)}>
    <ul className="list-none">
    {
      props.products.map( (p, pi) => <li key={p.productId} className={classNames([
        {"border-b mb-2": pi < props.products.length - 1}
      ])}>
        <div className="font-bold label">{p.name} — {p.code}</div>
        <ul className="list-none grid lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 grid-cols-2">
          {
            p.colors.map( c => <li key={c.id} className="m-2 flex items-start flex-col">
              <Checkbox 
                label={ <span className="flex items-center">
                    <UserImage path={c.imagePath} className="w-8"/>
                    <span className="ml-2 text-sm label">{c.name}</span>
                    

                  </span>
                }
                value={self.colorChecked(p.productId, c.id)} 
                onChange={val => self.onColorCheck(p.productId, c.id, val) }
              />
              <div className="flex items-center  mt-3">
                {/* <input type="radio"
                  name={p.productId + '-previewColorId'}
                  className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out mr-2"
                  value={c.id}
                  checked={self.isPreviewColor(p.productId, c.id)}
                  onChange={val => val.target.checked && self.selectPreviewColor(p.productId, c.id)}
                /> */}
                <RadioInput<string>
                  group={p.productId + '-previewColorId'}
                  value={c.id}
                  checked={self.isPreviewColor(p.productId, c.id)}
                  onChange={colorId => self.selectPreviewColor(p.productId, colorId)}
                />
                <div className="ml-3 text-xs label">Listing preview</div>
              </div>
              
            </li>)
          }
        </ul>
      </li> )
    }
    </ul>
    {self.error && <div className="error text-sm my-2">{self.error}</div>}
  </div>
});
