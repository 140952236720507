import React = require('react');
import { observer } from 'mobx-react-lite';
import { observable, action, autorun } from 'mobx';
import { useController } from '../util/mobxUtils';
import classNames = require('classnames');
import { ClassValue } from 'classnames/types';
import { EbayCategoryPicker } from './EbayCategoryPicker';


export type EbayCategoryRegistrarProps = {
  className?: ClassValue
}

class EbayCategoryRegistrarController {
  
  @observable private props: EbayCategoryRegistrarProps;

  constructor(props: EbayCategoryRegistrarProps) {
    this.props = props;
  }

  @action setProps = (props: EbayCategoryRegistrarProps) => {
    this.props = props;
  }
}

export const EbayCategoryRegistrar = observer(function EbayCategoryRegistrar(props: EbayCategoryRegistrarProps) {
  const self = useController(()=>new EbayCategoryRegistrarController(props), props);
  return <div className={classNames('EbayCategoryRegistrar', props.className)}>
    <EbayCategoryPicker />
  </div>
});