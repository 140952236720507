import { WebDependencies } from "../WebDependencies";
import { computed, observable, autorun, action, flow } from "mobx";
import { UserStore } from "./UserStore";
import { snapData } from "../util/util";

export type AlertError = {
  title?: string
  type: 'error'
  content: string[]
}
export type AlertSuccess = {
  type: 'success'
  message: string
}
export type AlertWarning = {
  type: 'warning'
  content: string[]
}

export type AlertContent = AlertError | AlertSuccess | AlertWarning
export class AlertStore {
  private deps: WebDependencies;
  private auth: UserStore
  @observable private alerts: AlertContent[] = []
  constructor(deps: WebDependencies, auth: UserStore) {
    this.deps = deps;
    this.auth = auth;
  }

  /**
   * Grab the top alert in the stack
   */
  @computed get alert() {
    return this.alerts.length > 0 ? this.alerts[0] : null;
  }
  /**
   * Dismiss the top alert
   */
  @action dismiss = () => {
    this.alerts.shift();
  }
  @action add = (alert: AlertContent, sticky = false) => {
    this.alerts.push(alert);
    const current = this.alert;
    this.deps.setTimeout(action(()=>{
      if (this.alert === current) this.dismiss();
    }), 10000)
  }
  @action error = (errors: AlertError['content'], title?:string, sticky = false) => {
    this.add({type: 'error', content: errors, title}, sticky)
  }
  @action success = (message: string) => {
    this.add({type: 'success', message})
  }
}
