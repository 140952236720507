import React = require('react');
import { Spinner } from './Spinner';
import classNames = require('classnames');
import { IconName, Icon } from './icons';
import { ColorType, colors, colorClass } from './kitTypes';
import { ClassValue } from 'classnames/types';

export type ButtonProps = React.PropsWithChildren<{
  spinner?: boolean
  text?: string|number
  disabled?: boolean
  onClick?: ()=>void
  color?: ColorType
  outline?: boolean
  type?: 'button' | 'submit'
  icon?: IconName
  /** for placing the icon on the other side of the button */
  iconOther?: IconName
  noBorder?: boolean
  className?: ClassValue
}>
export const Button = function Button (props: ButtonProps) {
  const outline = props.outline || props.noBorder;
  
  const disabled = props.disabled || props.spinner;
  
  const color = props.color || 'default';

  const fg = colors[color];
  
  const iconColor = outline ? color : null;

  const icon = props.icon && <span className="mr-2 flex items-center"><Icon size={4} name={props.icon} color={iconColor} /></span>;
  
  const iconOther = props.iconOther && <span className="ml-2 flex items-center">
    <Icon name={props.iconOther} />
  </span>;

  const loader = props.spinner && <span className="mr-2">
    <Spinner color={outline ? color : 'white'} style={{width: '12px', height: '12px'}} />
  </span>;


  const type = props.type || 'button';
  return outline
      ? <span className={classNames("inline-flex", {"rounded-md shadow-sm": !props.noBorder}, props.className)}>
        <button onClick={props.onClick} disabled={disabled} type={type} 
                className={classNames(
                  [
                    `inline-flex items-center rounded-md text-xs leading-5 font-medium text-${fg}-700 hover:text-${fg}-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-${fg}-50 active:text-${fg}-800 transition duration-150 ease-in-out`, 
                    {"py-2 px-4": !props.noBorder},
                    {[`border border-${fg}-300`]: !props.noBorder},
                    {'opacity-75': disabled},
                    {'cursor-not-allowed': disabled},
                    
                    // this line contains all the classes for tailwind purge
                    {"border-blue-300 text-blue-700 hover:text-blue-500 active:bg-blue-50 active:text-blue-800 border-blue-300 bg-blue-600 hover:bg-blue-500 focus:border-blue-700 active:bg-blue-700 border-red-300 text-red-700 hover:text-red-500 active:bg-red-50 active:text-red-800 border-red-300 bg-red-600 hover:bg-red-500 focus:border-red-700 active:bg-red-700 border-green-300 text-green-700 hover:text-green-500 active:bg-green-50 active:text-green-800 border-green-300 bg-green-600 hover:bg-green-500 focus:border-green-700 active:bg-green-700 border-gray-300 text-gray-700 hover:text-gray-500 active:bg-gray-50 active:text-gray-800 border-gray-300 bg-gray-600 hover:bg-gray-500 focus:border-gray-700 active:bg-gray-700": false},
                  ])
                }>
          {loader || icon} {props.children || props.text}
        </button>
      </span>
    : <span className={classNames("inline-flex rounded-md shadow-sm", props.className)}>
        <button onClick={props.onClick} disabled={disabled} type={type} 
                className={classNames(
                  [
                    `inline-flex items-center px-4 py-2 border border-transparent text-xs leading-6 font-medium rounded-md text-white bg-${fg}-600 hover:bg-${fg}-500 focus:outline-none focus:border-${fg}-700 focus:shadow-outline-indigo active:bg-${fg}-700 transition ease-in-out duration-150`, 
                    {'opacity-75': disabled},
                    {'cursor-not-allowed': disabled}
                  ])
                }>
          {loader || icon} {props.children || props.text} {iconOther}
        </button>
      </span>
    
}
