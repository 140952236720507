import { WebDependencies } from "../WebDependencies";

export class ModalStore {
  private deps: WebDependencies
  constructor(deps: WebDependencies) {
    this.deps = deps;
  }

  simpleConfirm = (text: string, ok: string = "OK", cancel: string = "Cancel"): Promise<boolean> => {
    return new Promise(res => {
      res(confirm(text))
    })
  }
}