import { StringInputProps, StringInput } from "./StringInput"
import React = require("react")

export type TextInputProps = React.PropsWithRef<{
  type?: 'text' | 'email' | 'tel' | 'password'
  ref?: React.Ref<HTMLInputElement>
} & Omit<StringInputProps, 'type'>>

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>((props: TextInputProps, ref) => {
  
  return <StringInput 
    type={props.type || 'text'}
    ref={ref}
    {...props}
  />
})
