import React = require("react")
import { errorToObject, errorToString } from "@root/shared/lib/x"
import classNames = require("classnames")

export type AlertProps = {
  title?: string
  className?: string
  id?: string
  onDismiss: ()=>void
}
export type AlertDangerProps = {
  errors: any[]
} & AlertProps
export const AlertDanger = (props: AlertDangerProps) => {
  
  return <div id={props.id} className={`rounded-md bg-red-50 p-4 ${props.className||''}`}>
    <div className="flex">
      <div className="flex-shrink-0">
        <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
        </svg>
      </div>
      <div className="ml-3">
        {
          props.title &&
            <h3 className="text-sm leading-5 font-medium text-red-800">
              {props.title}
            </h3>
        }
        <div className="mt-2 text-sm leading-5 text-red-700">
          <ul className="list-disc pl-5">
            {
              props.errors.map((e, i)=>
                <li key={errorToString(e)} className={classNames({'mt-1': i > 0})}>
                  {errorToString(e)}
                </li>
                )
            }
          </ul>
        </div>
        <div className="mt-4">
          <div className="-mx-2 -my-1.5 flex">
            <button onClick={props.onDismiss} className="ml-3 px-2 py-1.5 rounded-md text-sm leading-5 font-medium text-red-800 hover:bg-green-100 focus:outline-none focus:bg-green-100 transition ease-in-out duration-150">
              Dismiss
            </button>
          </div>
        </div>        
      </div>
    </div>
  </div>
}

export const AlertSuccess = (props: AlertProps & {message: string})=>{
  return <div className="rounded-md bg-green-50 p-4">
    <div className="flex">
      <div className="flex-shrink-0">
        <svg className="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
        </svg>
      </div>
      <div className="ml-3">
        <p className="text-sm leading-5 font-medium text-green-800">
          {props.message}
        </p>
      </div>
      <div className="ml-auto pl-3">
        <div className="-mx-1.5 -my-1.5">
          <button onClick={props.onDismiss} className="inline-flex rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:bg-green-100 transition ease-in-out duration-150">
            <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
}
