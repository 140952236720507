import React = require('react');
import { observer } from 'mobx-react-lite';
import { observable, action, autorun, reaction } from 'mobx';
import { useController } from '../util/mobxUtils';
import classNames = require('classnames');
import { ClassValue } from 'classnames/types';
import { delayPromise, errorToString, Model } from '@root/shared/lib/x';
import { MainStore } from '../store/store';
import { useStore } from '../store/StoreContext';
import { User } from '@root/shared/lib/database';
import { TextInput } from './kit/TextInput';
import { SpinnerOverlay } from './kit/SpinnerOverlay';
import { Login } from './Login';
import { Button } from './kit/Button';


export type UserAccountPageProps = {
  className?: ClassValue
}

class ModelController {
  @observable private props: UserAccountPageProps;
  store: MainStore
  @observable model: User
  auth: firebase.User
  @observable email?: string
  @observable currentPassword: string = ''

  @observable savingUser = false;
  @observable savingEmail = false;
  @observable resettingPwd = false;

  @observable dirtyUser = false;
  @observable dirtyEmail = false;

  constructor(props: UserAccountPageProps, store: MainStore, user: User, auth: firebase.User) {
    this.props = props;
    this.store = store;
    this.model = user
    this.auth = auth;
    this.email = auth.email || '';
  }

  @action setProps = (props: UserAccountPageProps) => {
    this.props = props;
  }
  @action setUser = (user: User) => {
    this.model = user;
  }
  @action setAuth = (auth: firebase.User) => {
    this.auth = auth;
    this.email = auth.email || this.email || ''
  }

  @action saveEmail = () => {
    const email = this.email;
    this.dirtyEmail = true;
    if (email && this.currentPassword && this.auth.email) {
      
      this.savingEmail = true;
      this.store.user.loginWithPwd({email: this.auth.email, password: this.currentPassword})
      .then(() => this.auth.updateEmail(email))
      .then(() => this.auth.sendEmailVerification())
      .then(action(() => {
        this.dirtyEmail = false;
        this.savingEmail = false;
        this.currentPassword = '';
        this.store.alert.success(`Email updated. Check your inbox for confirmation instructions.`);
      }))
      .catch(action(e => {
        this.dirtyEmail = false;
        this.savingEmail = false;
        this.store.alert.error([errorToString(e)]);
        this.store.error.captureException(e);
      }))
    }
  }

  @action resetPassword = () => {
    if (this.auth.email && this.store.util.confirm(`Resetting the password will send you reset link to the email address on file. Continue?`)) {
      this.resettingPwd = true;
      this.store.user.resetPwd(this.auth.email)
      .then(action(() => {
        this.resettingPwd = false;
        this.store.alert.success(`Password reset sent to email ${this.auth.email}.`);
      }))
      .catch(action(e => {
        this.resettingPwd = false;
        this.store.alert.error([errorToString(e)]);
        this.store.error.captureException(e);
      }))
      
    }
  }

  @action saveUser = () => {
    this.dirtyUser = true;
    this.savingUser = true;
    this.store.user.updateUser({
      firstName: this.model.firstName || '',
      lastName: this.model.lastName || '',
      uid: this.model.uid,
    })
    .then(action(() => {
      this.savingUser = false;
      this.dirtyUser = false;
    }))
    .catch(action(e => {
      this.savingUser = false;
      this.store.alert.error([errorToString(e)]);
      this.store.error.captureException(e);
    }))
  }
}

class UserAccountPageController {
  
  @observable private props: UserAccountPageProps;
  @observable controller: ModelController|null = null;
  store: MainStore
  auth: firebase.User | null = null
  user: User|null = null

  constructor(props: UserAccountPageProps, store: MainStore) {
    this.props = props;
    this.store = store;
    this.auth = store.user.state || null;
    this.user = store.user.user || null;
    
    const init = (user: User, auth: firebase.User) => {
      if (!this.controller) {
        this.controller = new ModelController(this.props, store, user, auth)
      }
    }

    this.store.user.onAuth((uid, auth) => {
      if (!auth) return;
      this.auth = auth;

      this.controller && this.controller.setAuth(auth);
      if (this.user) init(this.user, auth);
    });

    reaction(() => store.user.user, user => {
      if (!user) return;
      this.user = user || null;

      this.controller && this.controller.setUser(user);;
      if (this.auth) init(user, this.auth);
    })
  }

  @action setProps = (props: UserAccountPageProps) => {
    this.props = props;
    this.controller?.setProps(props);
  }
}

export const UserAccountPage = observer(function UserAccountPage(props: UserAccountPageProps) {
  const store = useStore();
  const self = useController(() => new UserAccountPageController(props, store), props);
  
  if (store.user.user === undefined) return <SpinnerOverlay/>;
  if (!store.user.user) return <Login/>;

  const controller = self.controller;
  if (!controller) return <SpinnerOverlay/>
  const userModel = controller.model;
  return <div className={classNames('UserAccountPage', props.className)}>
    <div className="max-w-6xl">
      <div className="flex items-end">
        <TextInput
          dirty={controller.dirtyUser}
          className="mr-2 w-64"
          autoFocus
          label="First Name"
          disabled={controller.savingUser}
          value={userModel.firstName || ''}
          onChange={val => userModel.firstName = val || ''}
        />
        <TextInput
          dirty={controller.dirtyEmail}
          className="mr-2"
          disabled={controller.savingUser}
          label="Last Name"
          value={userModel.lastName || ''}
          onChange={val => userModel.lastName = val || ''}
        />
        <Button
          spinner={controller.savingUser}
          onClick={controller.saveUser}
          text="Update name"
        />
      </div>

      

      <div className="flex my-4 items-end">
        <TextInput
          className="mr-2 w-64"
          dirty={controller.dirtyEmail}
          type="email"
          label="Email"
          disabled={controller.savingEmail}
          value={controller.email || ''}
          onChange={val => controller.email = val || ''}
          errorFunc={val => !val ? `required` : /.*@.*\..*/.test(val?.trim() || '') ? null : `invalid`}
        />
        <TextInput
          dirty={controller.dirtyEmail}
          disabled={controller.savingEmail}
          className="mr-2"
          type="password"
          label="Passwrod"
          value={controller.currentPassword || ''}
          onChange={val => controller.currentPassword = val || ''}
          errorFunc={val => val ? null : `required`}
        />
        <Button
          spinner={controller.savingEmail}
          onClick={controller.saveEmail}
          text="Update email"
        />
      </div>
      {
        store.user.state?.email && <div className="my-4">
          <Button
            spinner={controller.resettingPwd}
            color="danger"
            onClick={controller.resetPassword}
            text="Reset password"
          />
        </div>
      }
    </div>
  </div>
});