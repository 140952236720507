import React = require("react")
import classNames = require("classnames")



type CrumbItem<T> = {
  label: string
  key?: T
}

export type CrumbsProps<T> = {
  onCrumbClick?: (item: CrumbItem<T>)=>void
  items: CrumbItem<T>[]
}
export const Crumbs = function Crumbs<T extends string|number>(props: CrumbsProps<T>) {
  return <nav className="flex items-center text-sm leading-5 font-medium">
    {
      props.items.map((item, i) => <span className="flex items-center" key={item.key||item.label}>
        <span onClick={() => props.items.length - 1 > i && props.onCrumbClick && props.onCrumbClick(item)} 
           className={classNames({
            "flex items-center transition duration-150 ease-in-out": true,
            "cursor-pointer text-indigo-600 hover:text-indigo-700": !!(props.onCrumbClick) && props.items.length - 1 > i,
            "text-gray-500 hover:text-gray-700": !(!!(props.onCrumbClick) && props.items.length - 1 > i)
           })}>
          {item.label}
        </span>
        {
          (i+1) < props.items.length && 
          <svg className="flex-shrink-0 mx-2 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
        </svg>
        }
      </span>)
    
    }
    {/* <a href="#" className="text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">Engineering</a>
    <svg className="flex-shrink-0 mx-2 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
      <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
    </svg>
    <a href="#" className="text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">Back End Developer</a> */}
  </nav>
}
