import React = require("react")
import { ClassValue } from "classnames/types";
import classNames = require("classnames");
import { randomId } from "@root/shared/lib/x";
import { action } from "mobx";


export type TextAreaProps = {
  value?: string
  onChange?: (value: string) => void
  label?: string
  help?: string|JSX.Element
  inputId?: string
  placeholder?: string
  className?: ClassValue
  labelClassName?: ClassValue
  inputClassName?: ClassValue
  readOnly?: boolean
  disabled?: boolean
  /** default is 3 */
  rows?: number
  dirty?: boolean
  error?: string|null
  errorFunc?: (val: string|undefined) => string|undefined|null
}
export const TextArea = function TextArea(props: TextAreaProps) {
  
  const [ inputId ] = React.useState<string>(props.inputId || randomId('TextArea'));
  const [ dirty, setDirty ] = React.useState<boolean>(false);
  const showError = dirty || props.dirty;
  const error = showError && (props.error || props.errorFunc && props.errorFunc(props.value));

  React.useEffect(() => {
    if (!props.dirty) setDirty(false);
  }, [props.dirty]);

  return <div className={classNames(props.className)}>
  {
    props.label && <label htmlFor={inputId} className={classNames("block text-sm leading-5 font-medium text-gray-700", props.labelClassName)}>
      {props.label}
    </label>
  }

  <div className="rounded-md shadow-sm relative">

    <textarea value={props.value} onChange={e => props.onChange && action(props.onChange)(e.target.value)} id={inputId} rows={props.rows === undefined ? 3 : props.rows} 
      className={classNames([
        "form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" ,
        {"border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red": !!error},
        {"text-gray-500": props.disabled},
        props.inputClassName
      ])}
      placeholder={props.placeholder}>
    </textarea>

    {
      error && <div className="absolute inset-y-0 right-0 pr-2 flex items-start pt-2 pointer-events-none">
        <svg className="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
        </svg>
      </div>
    }

    {
      !!error && <p style={{top: '-0.5rem'}}  className="text-right text-xs relative z-10 text-red-600 pr-3 h-0 max-w-full overflow-visible">
        <span className="bg-white p-1">{error}</span>
      </p>
    }
  </div>
  {
    props.help && <p className="mt-2 text-sm text-gray-500 z-0">
      {props.help}
    </p>
  }
</div>
}
