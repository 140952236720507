import { observer } from "mobx-react-lite"
import React = require("react")
import { action, observable, computed } from "mobx"
import { useController } from "../util/mobxUtils"
import classNames = require("classnames")
import { ClassValue } from "classnames/types"
import { ProductColorOption, FirewardInput, ProductSKU, ProductSizePrice } from "@root/shared/lib/database"
import { Model, Assign, flatten, notVoid } from "@root/shared/lib/x"
import { TextInput } from "./kit/TextInput"
import { ManualSKUModel } from "../store/ProductStore"
import { NoArraySentinelInput } from "@root/shared/src/schemas/productGuards"



export type ManualSKUMatrixInputProps = {
  className?: ClassValue,
  value?: ManualSKUModel[]
  onChange?: (value: ManualSKUModel[]) => void
  colorOptions: ProductColorOption<NoArraySentinelInput>[]
  sizes: ProductSizePrice[]
  dirty?: boolean
}

const sameModel = (a: ManualSKUModel, b: ManualSKUModel)=>
  a.productColorOptionId == b.productColorOptionId && a.productSizeId == b.productSizeId;

export class ManualSKUMatrixInputController {
  
  @observable props: ManualSKUMatrixInputProps
  
  constructor(props: ManualSKUMatrixInputProps) {
    this.props = props;
  }
  getSize = (sizeId: string) => {
    return this.props.sizes.find(s=>s.id == sizeId)
  }
  onItemChange = (item: ManualSKUModel) => {
    const next = this.items.valid.map(prev => sameModel(item, prev.value) ? item : prev.value);
    this.onChange(next)
  }
  @action private onChange = (items: ManualSKUModel[]) => {
    this.props.onChange && this.props.onChange(items)
  }
  @action setProps = (props: ManualSKUMatrixInputProps) => {
    this.props = props;
  }

  /**
   * {
   *  valid: all the items that have a correspondng size and color options
   *  orphans: all the previous items that no longer do
   * }
   */
  @computed get items() {
    const prev = (this.props.value || []);
    const get = (m: ManualSKUModel): ManualSKUModel => 
      prev.find(p=>sameModel(m, p))
      || {...m};

    const valid = flatten(this.props.colorOptions.map(({sizeIds, ...o}) => 
      sizeIds.map(this.getSize).filter(notVoid)
      .map(size => ({size, color: o, value: get({productSizeId: size.id, productColorOptionId: o.id})})
    )));
    const orphans = prev.filter(p=>!valid.some(v=>v.color.id == p.productColorOptionId && v.size.id == p.productSizeId));
    return {
      valid, orphans
    }
  }

}
export const ManualSKUMatrixInput = observer(function ManualSKUMatrixInput(props: ManualSKUMatrixInputProps) {
  
  const self = useController(()=>new ManualSKUMatrixInputController(props), props);

  return <div className={classNames(
    'ManualSKUMatrixInput', props.className
  )}>
    <table>
      <thead>
        <tr>
          <th>Color</th>
          <th>Size</th>
          <th>SKU</th>
        </tr>
      </thead>
      <tbody>
        {
          self.items.valid.map(item => <tr key={item.color.id + item.size.id}>
            <td>{item.color.name}</td>
            <td>{item.size.size}</td>
            <td><TextInput
              dirty={props.dirty}
              value={item.value.sku || ''}
              onChange={sku => self.onItemChange({...item.value, sku})}
              errorFunc={val => val && val.trim() ? null : `field required`}
            /></td>
          </tr>)
        }
      </tbody>
    </table>
  </div>
})
