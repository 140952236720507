import { isString, isNumber, isArrayOf, isBoolean, hasString, Assign, hasNumber, isValid, guardOnly, ValidationMapError, Pred, keys, validateNonEmptyString, validateArrayOf, validateNumber, isLiteral, isEither, isNull, hasChild, isInt } from "../x";
import { ProductColorOption, FirewardInput, ProductPricing, FirewardTypes, Product, ProductSizePrice, ProductSKU, PositioningRule, ProductCanvas, ProductImage, ProductCanvasPositioning, SKUAlgorithm, WardTimestamp, PrintProvider, FirewardOutput } from "../database";

export type NewProduct = Omit<Product<NoSentinelOutput>, 'productId'>
export type NoSentinelOutput = {number: number, timestamp: WardTimestamp|Date, array: never}
export type NoArraySentinelTypes = Assign<FirewardTypes, {array: never}>
export type NoArraySentinelInput = Assign<FirewardInput, {array: never}>

export function validateProductPricing(v: unknown): v is ProductPricing<NoSentinelOutput> {
  if (v==='onePrice' || v==='bySize') {
    const q: ProductPricing<NoSentinelOutput> = v;
    return true;
  }
  return false
  // throw new ValidationMapError([`not one of: onePrice or bySize`]);
}
export const isProductPricing: Pred<ProductPricing<NoSentinelOutput>> = guardOnly(validateProductPricing);

export function validateProductSizePrice(p: unknown): p is ProductSizePrice<NoSentinelOutput> {
  if (
    hasString(p, 'id') &&
    hasString(p, 'size') &&
    hasNumber(p, 'price')
  ) {
    const q: ProductSizePrice = p;
    return true;
  }
  
  throw new ValidationMapError(`should be one of: onePrice or bySize, is ` + p);
}
export const isProductSizePrice = guardOnly(validateProductSizePrice);

export function isPackageDims(d: unknown): d is Product<NoSentinelOutput>['dims'] {
  return hasNumber(d, 'width') && hasNumber(d, 'height') && hasNumber(d, 'depth'); 
}

export function isProductSKU(p: unknown): p is ProductSKU {
  if (
    hasChild('sku', isString)(p) &&
    hasString(p, 'productSizeId') && 
    hasString(p, 'productColorOptionId')
    ) {
      const q: ProductSKU = p;
      return true;
    } return false;
}


export function isPositionRule(o: unknown): o is PositioningRule {
  const t: Record<PositioningRule, number> = {
    'center': 1,
    'top': 1,
    'top-11.5': 1,
    'top-12': 1,
    'top-12.5': 1,
    'top-13': 1,
    'mirrored': 1
  }
  return keys(t).some(k => k == o)
}


export const validateProductCanvasPositioning = isValid<ProductCanvasPositioning<NoSentinelOutput>>({ 
  top: isNumber, left: isNumber, scale: isNumber 
});

export const isProductCanvasPositioning = guardOnly(validateProductCanvasPositioning);

export function validateProductCanvas(p: unknown): p is ProductCanvas<NoSentinelOutput> {
  if (isValid<ProductCanvas<NoSentinelOutput>>({
      id: validateNonEmptyString,
      productSizeIds: isArrayOf(validateNonEmptyString),
      positioningRule: isPositionRule,
      width: validateNumber,
      height: validateNumber
    })(p)) {
    const q: ProductCanvas<NoSentinelOutput> = p;
    return true;
  } return false;
}
export const isProductCanvas = guardOnly(validateProductCanvas);

export function validateProductColorOption(p: unknown): p is ProductColorOption<NoSentinelOutput> {
  if (isValid<ProductColorOption<NoSentinelOutput>>({
    imagePath: isString,
    code: isString,
    id: isString,
    name: isString,
    sizeIds: validateArrayOf(isString)
  })(p)) {

    const q: ProductColorOption<NoSentinelOutput> = p;
    return true;
  } return false;
}

export const isProductColorOption = guardOnly(validateProductColorOption)

export const validateProductImage = isValid<ProductImage<NoSentinelOutput>>({
  id: isString, path: isString
});

export const isProductImage = guardOnly(validateProductImage);

export const isSKUAlgorithm: Pred<SKUAlgorithm<NoSentinelOutput>> = (o: unknown): o is SKUAlgorithm<NoSentinelOutput> =>
  keys<Record<SKUAlgorithm<NoSentinelOutput>, number>>({'manual': 1, 'shirts': 1}).some(k => k===o)

export function validateProductSpecific(item: unknown): item is Product<NoSentinelOutput>['specifics'][0] {
  if (!isValid<Product<FirewardOutput>['specifics'][0]>({
    name: validateNonEmptyString,
    value: [isString],
    required: isBoolean
  }, {required: true})(item)) return false;

  if (!item.value?.trim() && item.required) {
    console.log('failed specific', {...item})
    throw new ValidationMapError('is missing');
  }
  
  return true;
}
export const validatePrintProvider = isValid<PrintProvider<FirewardInput>>({
  shipFromLocation: [validateNonEmptyString],
  shipFromPostalCodeDefault: [validateNonEmptyString],
  code: validateNonEmptyString,
  name: validateNonEmptyString,
  id: validateNonEmptyString
})


export const validateProduct = isValid<NewProduct & {productId?: string}>({
    userId: validateNonEmptyString,
    name: validateNonEmptyString,
    code: validateNonEmptyString,
    ebayTitle: validateNonEmptyString,
    description: validateNonEmptyString,
    categoryId: isNumber,
    shippingPolicyProfileId: isNumber,
    packageType: validateNonEmptyString,
    pricing: isProductPricing,
    sizes: validateArrayOf(validateProductSizePrice, 1),
    onePrice: isNumber,
    irregularPackage: isBoolean,
    manualSkus: isArrayOf(isProductSKU),
    skuAlgorithm: isSKUAlgorithm,
    colors: validateArrayOf(isProductColorOption, 1),
    canvases: validateArrayOf(isProductCanvas, 1),
    dims: isPackageDims,
    images: validateArrayOf(isProductImage),
    productId: [validateNonEmptyString],
    printProvider: validatePrintProvider,
    weight: isValid({units: isLiteral({English: 1}), major: isNumber, minor: isNumber}),
    specifics: isArrayOf(validateProductSpecific),
    colorSpecificName: validateNonEmptyString,
    sizeSpecificName: validateNonEmptyString,
    shipFromLocation: isEither(isString, isNull),
    shipFromPostalCode: validateNonEmptyString,
    printLocation: validateNonEmptyString,
    archived: isBoolean,
    previewCanvasOptions: isValid<NewProduct['previewCanvasOptions']>({
      positioning: validateProductCanvasPositioning,
      canvasId: validateNonEmptyString
    }),
    dispatchTimeMax: [isInt]
  }, {printLocation: 'Front Center', ebayTitle: ''})

export const isProduct = guardOnly(validateProduct)


