import type * as firebase from 'firebase';
import { WardTimestamp, isTimestamp, WardFieldValue } from '@root/shared/lib/database';
import { rnd, array, mapObject, hasChild, isString, upsert, isObject } from '@root/shared/lib/x';
import React = require('react');
import { action } from 'mobx';

export function snapData<T>(snap: firebase.firestore.DocumentSnapshot): T|null
export function snapData<T, D>(snap: firebase.firestore.DocumentSnapshot, def: D): T|D
export function snapData<T>(snap: firebase.firestore.DocumentSnapshot, def: any = null): any {
  return snap.exists ? (snap.data()||def) : def
}

export const isFieldValue = (o: unknown): boolean => hasChild('lc', isString)(o) && o.lc.indexOf('FieldValue.') === 0;

export function dateFromStamp<T>(t: Date | WardTimestamp | T): Date|null {
  
  return t instanceof Date ? t : isTimestamp(t) ? new Date(Math.floor(t.seconds * 1000)) : null;
}
export type Size = {width: number, height: number};


export function useSize<T extends HTMLElement>(callback: (s: Size) => void) {

  const ref = React.useRef<T>(null);
  const sendUpdate = () => {
    const height = ref.current?.clientHeight;
    const width = ref.current?.clientWidth;

    if (width && height) callback({width, height});
  }
  React.useEffect(() => {
    
    sendUpdate();
    
    const isimg = ref.current && ref.current.tagName == 'IMG'
    
    if (ref.current && isimg) {
      ref.current.addEventListener('load', sendUpdate);  
    }
      
    ()=>ref.current && isimg && ref.current.removeEventListener('load', sendUpdate);

  }, [ref.current, ref.current?.clientHeight, ref.current?.clientWidth]);

  return ref;
}


export function upsertChanges<T>(
  arr: T[],
  snap: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>,
  getId: (t:T) => string, 
  ) {
    snap.docChanges().forEach(d => 
      action(upsert)(arr, getId, d.doc.id, d.type === 'removed' ? null : snapData<T>(d.doc)
    )
    
  )
}

/**
 * Does exactly the same thing as mobx `action` but add type safety to it
 */
export const queryAction = 
  (
    fn: (snap: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) => void
  ) => action(fn);
export const snapAction = 
  (
    fn: (snap: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) => void
  ) => action(fn)
