import React = require("react")
import { observer } from "mobx-react-lite"
import { useStore } from "../store/StoreContext"
import { ProductList } from "./ProductList";
import { Login } from "./Login";
import { Button } from "./kit/Button";
import { useRouting } from "../routing/routes";
import { Spinner } from "./kit/Spinner";
import { LinkText } from "./kit/LinkText";
// import { Link } from "react-navi";
import { keys } from "@root/shared/lib/x";
import { SetupIncompleteMsg } from "./SetupIncompleteMsg";
import { ButtonBar } from "./kit/ButtonBar";


export const ProductsPage = observer(function ProductsPage(props: {productId?: string}) {
  const store = useStore();
  const user = store.user.user;
  
  const routing = useRouting();
  
  if (!user) return <Login/>;


  const setupIncompleteMsg = SetupIncompleteMsg(store, routing)

  return <div className="ProductsPage">
    {
      !setupIncompleteMsg
      ? <>
          <ProductList products={[]} loading={false} />                    
        </>
      : <div>
          {setupIncompleteMsg}
        </div>
    }
  </div>
})
