import React = require('react');
import { useEffect } from 'react';
import { useRouting } from '../routing/routes';
import { useStore } from '../store/StoreContext';
import Spinner = require('react-spinkit');
import { useState } from 'react';
import { Login } from './Login';
import { useSearchParams } from 'react-router-dom';
type EbayLinkFinishPageProps = {
  // query: {
  //   ebaytkn?: string
  //   tknexpires?: string
  //   username?: string
  // }
} 
export const EbayLinkFinishPage = (props: EbayLinkFinishPageProps)=>{
  
  const [query] = useSearchParams();
  const username = query.get('username');

  const routing = useRouting();
  const store = useStore();
  const [ error, setError ] = useState<string|null>(null);
  useEffect(() => {
    store.ebay.completeConnection(username || '')
    .then(()=>routing.linkedAccounts.navigate())
    .catch(e=>{
      setError(e && e.message || e)
      store.error.captureException(e, {tags: {action: 'store.ebay.completeConnection'}})
    })
  }, [username]);
  
  if (!store.user.state) return <Login />

  return <div className="EbayLinkFinishPage">
    <div className="flex flex-col items-center mt-24 justify-center">
      {
        !error
        ? <>
            <Spinner name="chasing-dots" />
            <div className="text-gray-400">Please wait while we finish up setting up your eBay connection.</div>
          </>
        : <div className="text-red-400">An error has occurred: {error}</div>
      }
    </div>
  </div>
}
