import * as React from 'react'
import { WebDependencies } from '../WebDependencies'
import { MainStore } from './store'
import {useLocalStore} from 'mobx-react-lite';
const storeContext = React.createContext<MainStore | null>(null)

export const StoreProvider:React.StatelessComponent<{store: MainStore}> = ({ children, store }) => {
  const storex = useLocalStore(()=>store)
  return <storeContext.Provider value={storex}>{children}</storeContext.Provider>
}

export const useStore = () => {
  const store = React.useContext(storeContext)
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error('useStore must be used within a StoreProvider.')
  }
  return store
}
