import React = require('react');
import { observer } from 'mobx-react-lite';
import { observable, action, autorun, computed, runInAction } from 'mobx';
import { useController } from '../util/mobxUtils';
import classNames = require('classnames');
import { ClassValue } from 'classnames/types';
import { useStore } from '../store/StoreContext';
import { MainStore } from '../store/store';
import { Login } from './Login';
import { useRouting } from '../routing/routes';
import { SetupIncompleteMsg } from './SetupIncompleteMsg';
import { SpinnerOverlay } from './kit/SpinnerOverlay';
import { UserImage } from './kit/UserImage';
import { Checkbox } from './kit/Checkbox';
import { FirewardOutput, FirewardInput, Product, ProductDesign, ProductListing } from '@root/shared/lib/database';
import { NoSentinelOutput } from '../../../shared/lib/schemas/productGuards';
import { errorToString, foldl, groupBy, hasChild, hasString, isString, itself, keys, tuple, uniq } from '@root/shared/lib/x';
import { Icon } from './kit/icons';
import { Button } from './kit/Button';
import { Spinner } from './kit/Spinner';
import { TextInput } from './kit/TextInput';
import { MouseOver } from './kit/MouseOver';
import { literal } from 'mobx-state-tree/dist/internal';
import { TextArea } from './kit/TextArea';
import { Modal } from './kit/Modal';
import { CanvasPlacementInput } from './CanvasPlacementInput';
import { DesignCanvasAdjuster } from './DesignCanvasAdjuster';

import * as mobx from 'mobx';
import {IEqualsComparer} from 'mobx';
import { IMGIX_TRANSPARENT_IMG } from '@root/shared/lib/imgixTypes';
import { Select } from './kit/Select';
import { PendingListingsPagination } from './PendingListingsPagination';
import { useSearchParams } from 'react-router-dom';
import { computedFn } from 'mobx-utils';


export type PendingListingsPageProps = {
  className?: ClassValue
}

const DEFAULT_PER_PAGE = 50;

type DesignItem = Exclude<PendingListingsPageController['allItems'], null | undefined>[0];

const yeslog = (...args:any[]): true => {console.log.apply(console, args); return true;}

const Labeled = (props: {label: string, value: string|number, className?: string})=>
<div className={classNames(props.className, 'flex cm-2 text-sm text-gray-600 my-1')}>
  <div className="font-medium">{props.label}</div>: 
  <div>{props.value}</div>
</div>

type OverrideStatus = 'title' | 'description' | 'queuedForPublication';
type UseSearchParams = ReturnType<typeof useSearchParams>
/**
 * null means do not filter
 */
type Filters = {
  groupId: string|null
  completed: boolean|null
  errors: boolean|null
}
class PendingListingsPageController {
  @observable private props: PendingListingsPageProps;
  private store: MainStore
  @observable listingBusy: Record<string, boolean> = {}
  @observable designBusy: Record<string, boolean> = {}


  //
  // elements used to override everything
  //
  @observable overrideDirty: Partial<Record<OverrideStatus, boolean>> = {}
  @observable overrideBusy:false|OverrideStatus = false;
  
  @observable overrideTitle = ""
  @observable overrideDesc = ""

  @observable adjustedDesignId: string|null = null;
  @observable adjustedDesignBusy = false;
  
  @observable filters: Filters = {
    groupId: null,
    completed: null,
    errors: null
  }

  @observable perPage: number
  @observable currentPage: number;
  setQuery: UseSearchParams[1];

  @observable listingStatusQueue: Record<string, {colorGroupId: string, on: boolean}> = {};
  @observable colorGroupBusy: Record<string, boolean> = {};


  constructor(props: PendingListingsPageProps, store: MainStore, queryHook: UseSearchParams) {
    this.props = props;
    this.store = store;

    this.setQuery = queryHook[1];
    this.currentPage = parseInt(queryHook[0].get('page') || '$$$') || 0;

    this.perPage = this.store.listings.getListingOptions()?.perPage || DEFAULT_PER_PAGE;


    mobx.reaction(() => tuple(keys(this.listingStatusQueue).map(k => this.listingStatusQueue[k]), this.store.user.user?.uid), ([q, uid]) => {
      if (q.length === 0) return;

      if (uid) {
        this.toggleQueue(uid)
      } else if (!uid) {
        this.listingStatusQueue = {};
      }
    }, {fireImmediately: false, delay: 300});

  }

  //
  // Pagination
  //
  @action setPage = (page: number) => {
    if (page > this.totalPages - 1) return;
    if (page < 0) return;

    if (page) {
      this.setQuery({page: page.toString()})
    } else {
      this.setQuery({})
    }
  };
  @action setPerPage = (perPage: number = 50) => {
    this.perPage = perPage;
    this.setPage(0);
    this.store.listings.saveListingOptions({perPage})
  };
  @computed get totalPages() {
    return Math.ceil((this.filteredItems?.length || 0) / this.perPage) 
  }

  @action setProps = (props: PendingListingsPageProps) => {
    this.props = props;
  }

  @action nextPage = () => {
    this.setPage(this.currentPage + 1)
  }
  @action prevPage = () => {
    this.setPage(this.currentPage - 1)
  }

  //
  // URL Query Params
  //
  @action setQueryHook([query, setQuery]: UseSearchParams) {
    this.setQuery = setQuery;
    const page = parseInt(query.get('page') || '$$$');
    this.currentPage = page || 0;
  }


  @computed get someBusy() {
   
    return keys(this.designBusy).some(k => this.designBusy[k])
      || keys(this.listingBusy).some(k => this.listingBusy[k])
      || !!this.overrideBusy
      ;
  }

  @computed get groups() {
    return this.store.groups.productGroups
  }


  @computed({name: 'allItems'}) get allItems() {
    const allListings = this.store.listings.pendingListings;
    const groups = this.groups;

    return this.store.listings.pendingDesigns?.map(design => {

      const listings = allListings.filter(l => l.designId == design.id);

      return {
        design,
        interrupt: () => this.store.util.confirm(`Danger! Only do this if the item has been stuck for more than 15 minutes. Otherwise it will be processing twice in parallel. Continue?`) && this.updateDesign({
          id: design.id,
          queuedForPublication: false,
          processing: false
        }),
        process: () => this.updateDesign({
          publicationError: null,
          queuedForPublication: true,
          id: design.id,
        }),
        startPreviewAdjuster: () => this.startPreviewAdjuster(design),
        archive: () => this.updateDesign({
          archived: true,
          id: design.id,
        }),
        update: () => design.title?.trim() && design.description?.trim() && this.updateDesign(design),
        byProductColor: groupBy(listings, l => l.productId + l.color.id + design.id).map(item => ({
          key: item.id,
          product: this.store.products.productList?.find(p => p.productId == item.items[0].productId),
          listings: item.items,
          enabled: item.items.some(x => !x.disabled),
          disableProductColorListings: (on: boolean) => {
            const ls = item.items.filter(item => !item.completed);
            ls.forEach(listing => this.listingStatusQueue[listing.id] = {colorGroupId: item.id, on});

          },
        })).sort((a,b) => a.listings[0].productId > b.listings[0].productId ? 1 : -1),
        byProduct: groupBy(listings, l => l.productId).map(item => ({
          product: this.store.products.productList?.find(p => p.productId == item.items[0].productId),
          listings: item.items,
        }))
        .filter((item): item is (typeof item)&{product: Product<FirewardOutput>} => !!item.product),
        listings: listings,
        group: groups?.find(g => g.id == design.groupId),
      }
      
    })
  }

  @computed get showPagination() {
    // 
    // think about this edge case: say showPagination returns true if this.items.length > this.perPage, 
    // but your perPage is the largest of the possible choices. You won't be able to downsize it. Therefore
    // we show the pagination always
    //
    return !!this.filteredItems // && (this.items.length > DEFAULT_PER_PAGE || this.totalPages > 0)
  }

  @computed get filtersActive() {
    return keys(this.filters).some(k => this.filters[k])
  }

  @computed get pageItems() {
    const start = this.currentPage * this.perPage;
    const end = start + this.perPage;
    return this.filteredItems?.slice(start, end);
  }
  
  @computed get filteredItems() {
    return this.allItems
    ?.filter(item => {
      const listings = item.listings;
      const design = item.design;


      const errors = this.filters.errors===true && !!(design.publicationError || listings.some(l => l.publicationError))
        || this.filters.errors===false && !(design.publicationError || listings.some(l => l.publicationError))
        || this.filters.errors===null
      ;
      const group = this.filters.groupId===null || design.groupId===this.filters.groupId;

      const completed = this.filters.completed===null 
        || this.filters.completed === (!!design.completed)
      ;
      return errors && group && completed
    }) || null
  }

  @computed get adjustedDesign() {
    return this.adjustedDesignId 
      && this.pageItems?.find(d => d.design.id === this.adjustedDesignId)
      || null
  }


  // listingsByProduct(des: Exclude<PendingListingsPageController['items'], null>[0]) {
  //   const products = this.store.products.productList;
  //   if (!products) return null;

  //   const items = groupBy(des.listings, l => l.productId)
  //     .map(item => ({
  //       listings: item.items,
  //       product: products?.find(p => p.productId == item.id)
  //     }))
  //     .filter((item): item is (typeof item)&{product: Product<FirewardInput>} => !!item.product);
  //     return items;
  // }

  @computed get pendingDesigns() {
    return this.filteredItems?.filter(d => !d.design.completed && !d.design.queuedForPublication && !this.designBusy[d.design.id] && !d.design.archived)
  }

  @action startPreviewAdjuster(design: ProductDesign<FirewardOutput>): void {
    this.adjustedDesignId = design.id
  }

  @action onPreviewAdjusterCancel = () => {
    this.adjustedDesignId = null;
  }

  @action onPreviewAdjusterSave = (lss: ProductListing<FirewardOutput>[]) => {
    
    const uid = this.store.user.user?.uid;
    if (!uid) return;
    this.adjustedDesignBusy = true;
    const d = this.adjustedDesign;
    this.updateMultipleListings(lss, uid)
    .then(action(() => {
      if (d) { // updating the listings so that the previews reflect the change
        d.listings.forEach((prev, i) => {          
          const next = lss.find(next => next.id == prev.id);
          if (next) d.listings[i] = next;
        })

      }
      this.adjustedDesignBusy = false;
      // this.adjustedDesignId = null; // this closes the modal. client does not want that.
      
    }))
    .catch(action(() => this.adjustedDesignBusy = false))
  }

  @action interruptAll = () => {
    const uid = this.store.user.user?.uid;
    if (!uid) return;
    const items = this.pageItems?.filter(d => d.design.queuedForPublication);
    if (!items || items.length === 0) return;

    // const minAge = Date.now() - 60 * 15;
    // const recent = items.filter(item => !item.design.lastChange || item.design.lastChange.seconds > minAge);
    // if (recent.length === 0) return;

    return this.overrideUpdateAll({queuedForPublication: false, processing: false}, 'queuedForPublication', {
      confirmMsg: `Danger! Only do this if the items have been stuck for more than 15 minutes. Proceed?`,
      designsToOverride: items
    })
  }
  

  @action updateDesign = (design: Partial<ProductDesign<FirewardOutput>> & {id: string}) => {
    const uid = this.store.user.user?.uid;
    if (!uid) return;
    this.designBusy[design.id] = true;
    
    return this.store.listings.updateDesign(({...design}), uid)
    .then(action(() => {
      this.designBusy[design.id] = false;
    }))
    .catch(action(e => {
      this.designBusy[design.id] = false;
      this.store.alert.error([errorToString(e)])
    }))
    ;
  }

  thumbnailify = (url: string, edge = 400) => {
    return this.store.images.imgix.buildURL(IMGIX_TRANSPARENT_IMG, {
      w: 300,
      h: 300,
      mark: url,
      'mark-w': 1,
      'mark-h': 1,
      fit: 'fill',
      'mark-fit': 'clip',
      'mark-align:': 'center,middle'
    })
  }

  @action archive = async (design: ProductDesign<FirewardOutput>) => {
    if (!design.completed) {
      const ok = await this.store.modal.simpleConfirm("Archive this design and all its listings without publishing? Note that you can reset it by uploading a different image with the same name.");
      if (!ok) return;
    }
    this.updateDesign({id: design.id, archived: true})
  }

  @action toggleQueue = (uid: string) => {
    const q = {...this.listingStatusQueue};
    this.listingStatusQueue = {};

    const lss: {id: string, disabled: boolean}[] = keys(q).map(id => ({id: id.toLocaleString(), disabled: !q[id].on}));
    const pks = uniq(keys(q).map(k => q[k].colorGroupId));

    pks.forEach(k => this.colorGroupBusy[k] = true);

    console.log('toggling queue', q)
    
    this.store.listings.partialUpdateMultipleListings(lss, uid)
    .then(action(() => {
      pks.forEach(k => this.colorGroupBusy[k] = false);
    }))
    .catch(action(error => {
      pks.forEach(k => this.colorGroupBusy[k] = false);
      this.store.alert.error([errorToString(error)]);
      this.store.error.captureException(error, {extra: {place: 'PendingListingsPage::toggleQueue::catch'}});
    }))
  }
  @action updateMultipleListings = (lss: ProductListing<FirewardOutput>[], uid: string) => {

    groupBy(lss, ls => ls.designId).forEach(g => this.designBusy[g.id] = true);
    
    return this.store.listings.updateMultipleListings(lss.map(ls => ({
      ...ls,
      canvas: {...ls.canvas},
      lastChange: new Date(),
      previewOptions: {
        ...ls.previewOptions,
        canvas: {...ls.previewOptions.canvas},
        positioning: {...ls.previewOptions.positioning}
      }
    })), uid)
    .then(action(() => {
      groupBy(lss, ls => ls.designId).forEach(g => this.designBusy[g.id] = false);
    }))
    .catch(action(e => {
      groupBy(lss, ls => ls.designId).forEach(g => this.designBusy[g.id] = false);
      this.store.error.captureException(e, {extra: {place: 'updateMultipleListings'}});
      this.store.alert.error([errorToString(e)]);
      return Promise.reject(e)
    }))
  }

  @action updateListing = (ls: ProductListing<FirewardOutput>) => {
    const uid = this.store.user.user?.uid;
    if (!uid) {
      this.store.alert.error(['You are not logged in.'])
      return;
    }
    this.listingBusy[ls.id] = true;
    const next: ProductListing<FirewardInput> = {
      ...ls,
      canvas: {
        ...ls.canvas,
      },
      previewOptions: {
        ...ls.previewOptions,
        canvas: {...ls.previewOptions.canvas},
        positioning: {...ls.previewOptions.positioning}
      },
      lastChange: ls.lastChange || this.store.fieldValue.serverTimestamp()
    }

    this.store.listings.updateListing(next, uid)
      .then(action(()=>{
        this.listingBusy[ls.id] = false;
      }))
      .catch(action(e => {
        this.listingBusy[ls.id] = false;
        this.store.alert.error([errorToString(e)], 'Error saving listing:');
        this.store.error.captureException(e, {extra: {place: 'PendingListingsPage::updateListing'}})
      }))
  }

  @action overrideUpdateAll = async (update: Partial<ProductDesign<FirewardOutput>>, status: OverrideStatus, opt: {
    designsToOverride?: Exclude<PendingListingsPageController['pageItems'], null>
    confirmMsg?: string
  } = {}) => {
    
    this.overrideDirty[status] = true;

    const uid = this.store.user.user?.uid;

    const designs = opt.designsToOverride || this.pendingDesigns;
    
    const designIds = designs?.map(d => 
      d.design.id
    );

    // @ts-ignore // and yet another bug in typescript
    if (isString(update[status])) update[status] = update[status].trim();

    if (!designIds || !uid || designIds.length == 0) return;
    

    const ok = await this.store.modal.simpleConfirm(opt.confirmMsg || "Override all unprocessed designs?");
    if (!ok) return;

    runInAction(() => {
      this.overrideBusy = status;
      this.store.listings.updateMultipleDesigns(designIds.map(id => ({...update, id})), uid)
      .then(action(() => {
        this.overrideDirty[status] = false;
        this.overrideBusy = false;
      }))
      .catch(action(e => {
        this.overrideDirty[status] = false;
        this.overrideBusy = false;
        this.store.alert.error([errorToString(e)]);
        this.store.error.addBreadcrumb({data: {designIds, uid, update}});
        this.store.error.captureException(e);
      }))
    })

  }

  @action processAllDesigns = async () => {
    const designs = this.pendingDesigns;
    if (!designs) return;
    const uid = this.store.user.user?.uid;
    if (!uid) return;

    // const ok = await this.store.modal.simpleConfirm(`Initiate processing of all pending designs (${designs.length} count)?`);
    const ok = this.store.util.confirm(`Initiate processing of all pending designs (${designs.length} count)?`);
    if (!ok) return;
    
    const designIds = designs.map(d => d.design.id);

    //
    // mark each design busy
    //
    designIds.forEach(id => {
      this.designBusy[id] = true;
    });


    this.store.listings.processMultipleDesigns(designIds, uid)
    .then(action(() => {
      designIds.forEach(id => {
        this.designBusy[id] = false;
      })
    }))
    .catch(action(e => {
      designIds.forEach(id => {
        this.designBusy[id] = false;
      });
      this.store.alert.error([errorToString(e)]);
      this.store.error.addBreadcrumb({data: {designIds, palce: 'process all designs'}})
      this.store.error.captureException(e)
    }))

  }

  @action processDesign = (design: ProductDesign<FirewardOutput>) => {
    const uid = this.store.user.user?.uid;
    if (!uid) {
      this.store.error.captureException(new Error('User is not logged in, but should be.'), {extra: {place: 'PendingListingsPage::processDesign'}})
      return;
    }

    const next: ProductDesign<FirewardInput> = {
      ...design,
      queuedForPublication: true,
      publicationError: null,
    }

    this.designBusy[design.id] = true;
    
    this.store.listings.updateDesign(next, uid)
    .then(action(() => {
      this.designBusy[design.id] = false;
    }))
    .catch(action(e => {
      this.store.alert.error([errorToString(e)], "Error queueing design " + design.designCode);
      this.store.error.captureException(e, {extra: {place: 'PendingListingsPage::processDesign catch'}})
      this.designBusy[design.id] = false;
    }))
  }

  @action archiveAll = () => {
    const designs = this.filteredItems?.filter(d => {
      return !this.designBusy[d.design.id] && !d.design.archived && !d.design.queuedForPublication
    });
    
    if (!designs) return;
    const uid = this.store.user.user?.uid;
    if (!uid) return;

    if (!this.store.util.confirm(`Archive ALL the design that match your filter settings (${designs.length} count)?`)) {
      return;
    }

    //
    // Mark all the designs busy
    //
    designs.forEach(d => { this.designBusy[d.design.id] = true; });

    this.store.listings.updateMultipleDesigns(designs.map(d => ({id: d.design.id, archived: true})), uid)
    .then(action(() => {
      designs.forEach(d => { this.designBusy[d.design.id] = false; })
    }))
    .catch(action(e => {
      designs.forEach(d => { this.designBusy[d.design.id] = false; })
      this.store.alert.error([errorToString(e)]);
      this.store.error.addBreadcrumb({data: {designs, palce: 'process all designs'}})
      this.store.error.captureException(e)
    }))

  }
}

export const PendingListingsPage = observer(function PendingListingsPage(props: PendingListingsPageProps) {
  const store = useStore();
  const routing = useRouting();
  const queryHook = useSearchParams({ perPage: '50' });
  
  const self = useController(() => new PendingListingsPageController(props, store, queryHook), props);

  self.setQueryHook(queryHook);

  if (!store.user.user) {
    return <Login/>
  }
  const incomplete = SetupIncompleteMsg(store, routing)
  if (incomplete) return incomplete;

  

  const adjustedDesign = self.adjustedDesign;
  return <div className={classNames('PendingListingsPage', props.className)}>
    {
      self.allItems && self.allItems.length > 0 && <div className="flex flex-wrap cm-2  mb-4 pb-2 border-b border-gray-300">
        <div className="text-sm mr-4 italic leading-5 label text-gray-600">Filter by</div>
        <Select
          label="Group"
          options={[{label: 'All', value: null}, ...(self.groups?.map(g => ({label: g.name, value: g.id})) || [])]}
          value={self.filters.groupId}
          onChange={val => self.filters.groupId = val===undefined ? null : val}
        />
        <Select
          label="Completion"
          options={[{label: 'All', value: null}, {label: 'Complete', value: true}, {label: 'Incomplete', value: false}]}
          value={self.filters.completed}
          onChange={val => self.filters.completed = val===undefined ? null : val}
        />
        <Select
          label="Errors"
          options={[{label: 'All', value: null}, {label: 'Has Errors', value: true}, {label: 'No Errors', value: false}]}
          value={self.filters.errors}
          onChange={val => self.filters.errors = val===undefined ? null : val}
        />
      </div>
    }
    {
      self.filteredItems && self.filteredItems.length > 0 && 
      <div className="mb-4">
        
        <div className="cm-2 flex items-center min-w-64 w-8/12">
            <TextInput
              className="items-center flex cm-2"
              labelClassName="w-24"
              label="Override Title"
              value={self.overrideTitle}
              onChange={val => self.overrideTitle = (val || '')}
              maxLength={255}
              errorFunc={val => val?.trim() ? null : `can't be empty`}
              dirty={self.overrideDirty['title']}
              disabled={self.someBusy}
            />
            <Button 
              text="Override Titles"
              onClick={() => self.overrideTitle.trim() && self.overrideUpdateAll({title: self.overrideTitle.trim()}, 'title')}
              color="danger" outline 
              disabled={self.someBusy || self.pendingDesigns?.length === 0}
              spinner={self.overrideBusy === 'title'}
            />
        </div>
        <div className="flex items-start cm-2 mt-2">
          <TextArea
            className="items-start flex cm-2"
            labelClassName="w-24 mr-2"
            inputClassName="sm:w-48"
            label="Override Description"
            value={self.overrideDesc}
            onChange={val => self.overrideDesc = (val || '')}
            errorFunc={val => val?.trim() ? null : `can't be empty`}
            dirty={self.overrideDirty['description']}
            disabled={self.someBusy}
          />
          <Button 
              text="Override Descriptions"
              onClick={() => self.overrideUpdateAll({description: self.overrideDesc.trim()}, 'description')}
              color="danger" outline 
              disabled={self.someBusy || self.pendingDesigns?.length === 0}
              spinner={self.overrideBusy === 'description'}
            />
        </div>
        <div className="flex items-center cm-2 mt-3">
          <Button 
            text="Archive"
            onClick={self.archiveAll}
            color="default" outline 
            disabled={self.someBusy}
          />
          <Button 
            text="Process Pending"
            onClick={self.processAllDesigns}
            color="default" outline 
            disabled={self.someBusy || self.pendingDesigns?.length === 0}
          />
          <Button 
            spinner={self.overrideBusy === 'queuedForPublication'}
            text="Interrupt Stuck Items"
            onClick={self.interruptAll}
            color="danger" outline 
            disabled={self.someBusy}
          />
        </div>
        <div className="mt-2 mb-1 text-xs text-gray-500">
          <p>Actions in this section affect <em>all pages</em> of applicable listings matched by your filter.</p>
          {
            self.totalPages > 1 && 
            <p><em>Note:</em> they will apply across ALL pages, not just the visible one.</p>
          }          
        </div>
      </div>
    }

    {
      //
      // Pagination
      //
      self.filteredItems && self.showPagination && <PendingListingsPagination className="my-4"
        next={self.nextPage}
        previous={self.prevPage}
        page={self.currentPage}
        onPage={self.setPage}
        perPage={self.perPage}
        totalItems={self.filteredItems.length}
        onPerPage={self.setPerPage}
      />
    }

    {
      !self.pageItems
      ? <SpinnerOverlay/>
      : <PendingDesignList
          pageItems={self.pageItems}
          designBusy={self.designBusy}
          listingBusy={self.listingBusy}
          colorGroupBusy={self.colorGroupBusy}
          thumbnailify={self.thumbnailify}
        />
    }
    {
      self.pageItems && self.pageItems.length === 0 && 
      <div className="text-sm my-4 text-gray-600">
        {
          self.filtersActive
          ? `No listings found.`
          : `No pending listings. Did you upload the image files into the Product Group’s DropBox folder?`
        }
        
      </div>
    }
    {
      //
      // Pagination
      //
      self.filteredItems && self.showPagination && <PendingListingsPagination className="my-4"
        next={self.nextPage}
        previous={self.prevPage}
        page={self.currentPage}
        onPage={self.setPage}
        perPage={self.perPage}
        totalItems={self.filteredItems.length}
        onPerPage={self.setPerPage}
      />
    }
    {
      adjustedDesign && adjustedDesign.group && 
      <Modal
        title={adjustedDesign.design.title}
        // okButton="Save"
        // cancelButton="Cancel"
        // onOK={() => adjustedDesign.design.title}
        // onCancel={() => self.adjustedDesignId = null}
        noButtons
        icon="photograph"
      >

        <DesignCanvasAdjuster
          busy={self.adjustedDesignBusy}
          design={adjustedDesign.design}
          group={adjustedDesign.group}
          listings={adjustedDesign.listings}
          onUpdate={self.onPreviewAdjusterSave}
          onCancel={self.onPreviewAdjusterCancel}
        />

      </Modal>
    }
  </div>
});


export const PendingDesignList = observer(function PendingDesignList(props: {
  pageItems: NonNullable<PendingListingsPageController['pageItems']>,
  designBusy: PendingListingsPageController['designBusy'],
  thumbnailify: (url: string, edge?: number) => string,
  listingBusy: PendingListingsPageController['listingBusy'],
  colorGroupBusy: PendingListingsPageController['colorGroupBusy']
}) {
  return <ul>
    { props.pageItems.map( des => 
        <DesignListItem key={des.design.id} des={des} designBusy={props.designBusy} listingBusy={props.listingBusy} thumbnailify={props.thumbnailify} colorGroupBusy={props.colorGroupBusy} />
      )
    }
  </ul>;
})

type DesignListItemProps = { 
  des: NonNullable<PendingListingsPageController['pageItems']>[0];
  designBusy: PendingListingsPageController['designBusy']; 
  thumbnailify: (url: string, edge?: number | undefined) => string; 
  listingBusy: PendingListingsPageController['listingBusy']; 
  colorGroupBusy: Record<string, boolean>
};

const DesignListItem = observer(function DesignListItem(props: DesignListItemProps) {
  const des = props.des;
  return <li className="border-b last:border-b-0 pb-1 mb-4 flex flex-col">

    <div className="flex items-start my-2 cm-2">
      <UserImage path={des.design.bucketPath} edge={48} version={des.design.lastChange?.seconds || ''} className="w-12" />
      <div className="flex flex-col items-start w-128 max-w-full">
        <div className="w-full max-w-xl my-2 flex items-center">
          <TextInput
            value={des.design.title}
            inputClassName="w-full"
            onChange={val => des.design.title = val || ''}
            errorFunc={val => val ? null : `cannot be empty`}
            disabled={props.designBusy[des.design.id] || des.design.queuedForPublication || !!des.design.completed}
            className="flex items-center w-full" />

          <span className="whitespace-no-wrap flex-shrink-0 flex-grow-0 text-xs text-gray-600 ml-2">{des.design.title.length} chars</span>
        </div>

        <div className="flex flex-row items-start my-2 w-full">
          <TextArea
            className="mr-2 flex-grow"
            inputClassName="w-full "
            value={des.design.description || ''}
            onChange={val => des.design.description = val || ''}
            errorFunc={val => val?.trim() ? null : `can't be empty`}
            disabled={props.designBusy[des.design.id] || des.design.queuedForPublication || !!des.design.completed}
            rows={3} />
          <Button
            text={"Update Title and Description"}
            className="flex-shrink-0 flex-grow-0"
            disabled={!des.design.description?.trim() || des.design.queuedForPublication || !!des.design.completed}
            outline
            onClick={des.update} />
        </div>
        <div className="flex flex-row items-center cm-2 my-2">
          {(des.design.queuedForPublication || des.design.processing) && <div className="text-gray-600 text-sm">
            {des.design.processing ? <Spinner small /> : <Icon name="clock" color="default" />} <em>...processing</em>
          </div>}
          {des.design.queuedForPublication
            ? <Button
              spinner={props.designBusy[des.design.id]}
              onClick={des.interrupt}
              text="Interrupt"
              color="danger"
              outline />
            : des.design.completed
              ? <div className="flex items-center text-xs cm-1 text-green-600">
                <Icon name="check" color="success" /><span>listed</span>
              </div>
              : <Button
                spinner={props.designBusy[des.design.id]}
                disabled={des.design.queuedForPublication || !!des.design.completed}
                onClick={des.process}
                text={des.design.queuedForPublication ? "Processing..." : "Process Design"}
                outline />}

          <Button
            disabled={props.designBusy[des.design.id] || des.design.queuedForPublication || !!des.design.completed}
            text="Adjust Previews"
            color="default"
            outline
            onClick={des.startPreviewAdjuster} />
          <Button
            disabled={props.designBusy[des.design.id] || des.design.queuedForPublication}
            text="Archive"
            color={des.design.completed ? "success" : "danger"}
            outline
            onClick={des.archive} />



        </div>
        <ul className="flex flex-wrap cm-4 items-center">
          <li>
            <MouseOver
              toggle="hover"
              content={<div className="bg-white w-80 max-w-full p-2 box-content border-blue-200 border whitespace-no-wrap overflow-auto flex-col cmy-2" style={{maxHeight: '70vh'}}>
                <Labeled label="Group Name" value={des.group?.name || '<unnamed>'} />
                <div className="border-b border-gray-300 mb-3 mt-1"/>
                {des.byProduct.map(({ product, listings }, i) => <div key={product.productId} className={i < des.byProduct.length - 1 ? "border-b border-gray-300" : ""}>
                  <Labeled label="Product Name" value={product.name} />
                  <Labeled label="Product eBay Title" value={listings[0].productEbayTitle} />
                  <Labeled label="Design Image" value={des.design.designName} />
                  <ul className="ml-2 text-gray-600 text-sm">
                    {
                      listings.map(item => <li key={item.id}>
                        {item.color.label} | {item.size.label} | {item.skus.ebaySKU}
                      </li>)
                    }
                  </ul>
                </div>
                )}
              </div>}
            >
              <Button noBorder text="listings" />
            </MouseOver>
          </li>
          {<ProductColorPreviewList {...props} />}
        </ul>
        {/* <div className="text-xs my-2 text-gray-400">{des.group?.name}</div> */}
        <ul className="error text-sm">

          {des.design.publicationError && <li>{des.design.publicationError}</li>}
          
        </ul>

      </div>

    </div>

  </li>;
})

const ProductColorPreviewList = observer(function ProductColorPreviewList(props: DesignListItemProps) {
  return <>{props.des.byProductColor.map(item => <ProductColorPreview key={item.key} {...props} item={item} />)}</>
})
const ProductColorPreview = observer(function ProductColorPreview(props: {item: DesignListItemProps['des']['byProductColor'][0]} & DesignListItemProps) {
  const item = props.item;
  const des = props.des;
  return <li key={item.key}>
    <Checkbox
      label={<>
        <MouseOver
          content={<div className="w-64 box-content border-blue-200 border bg-white flex flex-col items-center border-collapse">
            <div className="w-64 h-64">
              <img className="max-h-full max-w-full mx-auto" src={props.thumbnailify(item.listings[0].previewImageUrl, 900)} />
            </div>
            <ul className="flex flex-wrap mt-1 cm-2 justify-center text-sm">{item.listings.map(item => <li key={item.id}>{item.size.code}</li>)}</ul>
          </div>}
          className="w-8 h-8">
          <img src={props.thumbnailify(item.listings[0].previewImageUrl)} className="max-w-full max-h-full" />
        </MouseOver>
      </>}
      value={item.enabled}
      onChange={item.disableProductColorListings}
      disabled={des.design.queuedForPublication || !!des.design.completed || props.colorGroupBusy[item.key]} />
  </li>;
})

