import React = require('react');
import { observer } from 'mobx-react-lite';
import { observable, action, autorun, computed, reaction, toJS } from 'mobx';
import { useController } from '../util/mobxUtils';
import classNames = require('classnames');
import { MainStore } from '../store/store';
import { useStore } from '../store/StoreContext';
import { ClassValue } from 'classnames/types';
import { FirewardOutput, ProductCanvasPositioning, ProductDesign, ProductGroup, ProductListing } from '@root/shared/lib/database';
import { CanvasPlacementInput } from './CanvasPlacementInput';
import { first, groupBy } from '@root/shared/lib/x';
import { buildProductPreview } from '@root/shared/lib/listingUtils';
import { UserImage } from './kit/UserImage';
import { Button } from './kit/Button';
import { ModalButtons } from './ModalButtons';
import { SpinnerOverlay } from './kit/SpinnerOverlay';
import { ImgixMetadata } from '@root/shared/src/imgixTypes';


export type DesignCanvasAdjusterProps = {
  className?: ClassValue
  design: ProductDesign<FirewardOutput>
  listings: ProductListing<FirewardOutput>[]
  group: ProductGroup<FirewardOutput>
  busy?: boolean
  onUpdate: (listings: ProductListing<FirewardOutput>[]) => void
  onCancel: () => void
}

class DesignCanvasAdjusterController {
  
  private store: MainStore
  @observable private props: DesignCanvasAdjusterProps;

  @observable selectedProductId: string | null = null;
  @observable positioning: ProductCanvasPositioning<FirewardOutput> | null = null

  @observable previewImageUrl: string | null = null;
  
  @observable designReso: ImgixMetadata|null = null;
  @observable previewReso: ImgixMetadata|null = null;
  

  constructor(props: DesignCanvasAdjusterProps, store: MainStore) {
    this.props = props;
    this.store = store;

    store.util.getImageResolution(props.design.bucketPath)
    .then(action(res => this.designReso = res))
    .catch(e => console.error(e))

    reaction(() => this.previewImagePath, path => {
      
      this.previewImageUrl = null;
      
      if (path) {
        store.images.getImageUrl(path)
        .then(action(url => this.previewImageUrl = url));
      }

    }, {fireImmediately: true});
  }

  @computed get listings() {
    return this.props.listings.filter(l => l.productId == this.selectedProductId).reverse();
  }
  @computed get previewListing() {
    return this.selectedProductId ? this.getPreviewListing(this.selectedProductId) : null;
  }
  @computed get listingsByProduct() {
    return groupBy(this.props.listings, v => v.productId).map(x => ({productId: x.id, listings: x.items}))
  }

  getPreviewListing = (productId: string) => {
    return this.props.listings
      .filter(l => l.productId == productId)
      .filter(l => this.props.group.productColors.some(pc => pc.productId == l.productId && pc.previewColorId == l.color.id))[0]
  }
  getPreviewPath = (productId: string) => {
    return this.getPreviewListing(productId)?.previewOptions.previewImagePath;
  }
  @computed get previewImagePath() {
    return this.selectedProductId ? this.getPreviewPath(this.selectedProductId) : null
  }


  @action setProduct = (productId: string|null) => {
    this.selectedProductId = productId
    this.positioning = productId
      ? this.previewListing?.previewOptions.positioning || {top: 0, left: 0, scale: .2}
      : null
    ;

    this.previewReso = null;
    this.previewImagePath && this.store.util.getImageResolution(this.previewImagePath)
      .then(reso => {
        this.previewReso = reso
      })
      .catch(e => console.error(e))
  }

  @action setProps = (props: DesignCanvasAdjusterProps) => {
    this.props = props;
  }

  @action save = () => {
    const positioning = this.positioning;
    const designReso = this.designReso;
    const previewReso = this.previewReso;

    if (!this.selectedProductId || !positioning || !designReso || !previewReso) {
      return console.log('something is missing', this.selectedProductId , positioning , designReso, previewReso);
    }

    // searching in this non-type-safe way to get an error if this is missing
    const previewListing = this.getPreviewListing(this.selectedProductId);
    
    const mainListingPreviewUrl = buildProductPreview({
      blankProductImg: {
        path: previewListing.previewOptions.previewImagePath,
        info: previewReso
      },
      designImg: {
        path: this.props.design.bucketPath,
        info: designReso
      },
      canvas: previewListing.previewOptions.canvas,
      canvasPositioning: positioning,
      imgix: this.store.images.imgix
    })
    
    
    const listings = this.listings.map<ProductListing<FirewardOutput>>(listing => ({
      ...listing,
      previewOptions: {
        ...listing.previewOptions,
        positioning,
      },
      mainListingPreviewUrl,
      previewImageUrl: buildProductPreview({
        blankProductImg: {
          path: listing.previewOptions.previewImagePath,
          info: toJS(previewReso)
        },
        designImg: {
          path: this.props.design.bucketPath,
          info: designReso
        },
        canvas: listing.previewOptions.canvas,
        canvasPositioning: positioning,
        imgix: this.store.images.imgix
      }),

      
    }));

    this.props.onUpdate(listings);
    this.selectedProductId = null;
  }
}

export const DesignCanvasAdjuster = observer(function DesignCanvasAdjusterInput(props: DesignCanvasAdjusterProps) {
  const store = useStore();
  const self = useController(() => new DesignCanvasAdjusterController(props, store), props);
  
  const listing = self.previewListing;

  return <div className={classNames('DesignCanvasAdjuster max-w-md flex flex-col items-start', props.className)}>
    {
      props.busy && <SpinnerOverlay/>
    }
    { self.selectedProductId
      ? <div className="mb-2 self-start">
          <Button noBorder text="back" icon="chevronLeft" onClick={() => self.setProduct(null)} />
        </div>
      : <ul className="flex flex-wrap max-w-lg mt-3 cm-2 justify-center">
      
          { self.listingsByProduct.length > 0 &&
            self.listingsByProduct.map( pgroup => 
            <li 
              key={pgroup.productId} 
              className={classNames(
                {
                  'border-gray-700 border p-1': self.selectedProductId == pgroup.productId,
                  'hover:border-gray-400 cursor-pointer': self.selectedProductId != pgroup.productId,
                },
                'w-16 h-16 bg-white box-content border-gray-200 border-2 p-1'
              )}
              onClick={action(() => self.setProduct(pgroup.productId))}
            >
            {
              <UserImage path={self.getPreviewPath(pgroup.productId)} />
            }
            </li> )
          }
        </ul>
    }
    
    { self.selectedProductId && listing && self.previewImageUrl && 
      <CanvasPlacementInput      
        canvas={listing.previewOptions.canvas}
        previewImageUrl={self.previewImageUrl}
        value={self.positioning}
        onChange={val => {
          self.positioning = val
        }}
        className="w-64 min-h-64"
      />
    }
    <ModalButtons
      className="w-full"
      busy={props.busy}
      okButton="Save"
      cancelButton={self.selectedProductId ? "Cancel" : "Close"}
      onOK={self.selectedProductId ? self.save : undefined}
      onCancel={props.onCancel}
    />
  </div>
});