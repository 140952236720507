import { isString, isNumber, isArrayOf, isBoolean, hasString, Assign, hasNumber, isValid, guardOnly, ValidationMapError, Pred, keys, validateNonEmptyString, validateArrayOf, Model, isEither, isDate, isArray, isNull, validateNumber, isInt } from "../x";
import { ProductColorOption, FirewardInput, ProductPricing, FirewardTypes, Product, ProductSizePrice, ProductSKU, PositioningRule, ProductCanvas, ProductImage, ProductCanvasPositioning, SKUAlgorithm, ProductGroup, isTimestamp, FirewardOutput, ProductGroupColor } from "../database";
import { NoArraySentinelInput, NoSentinelOutput } from "./productGuards";
import { isFieldValue } from "../firex";

export type ProductGroupModel = Assign<Model<ProductGroup<NoSentinelOutput>>, {
  id?: string | undefined;
  categoryId?: number | undefined;
  userId?: string;
  lastWrite: ProductGroup<NoArraySentinelInput>['lastWrite'] | null
  productColors: ProductGroup<NoArraySentinelInput>['productColors']
  archived: boolean
  ebayStoreCategoryId?: string
  ebayStoreCategory2Id?: string
}>

export type NewProductGroup = Omit<ProductGroup<NoSentinelOutput>, 'id'>

export function productGroupToModel(g: ProductGroup<FirewardOutput>): ProductGroupModel {
  const m: ProductGroupModel = {
    ...g,
    productColors: g.productColors.map(c => ({...c}))
  }
  return m;
}

export const validateGroupProductColor = isValid<ProductGroupColor<NoSentinelOutput>>({
  id: validateNonEmptyString,
  colorIds: validateArrayOf(validateNonEmptyString, 1),
  previewColorId: validateNonEmptyString,
  productId: validateNonEmptyString
});

export const validateGroupModel = isValid<ProductGroupModel>({
  lastWrite: isEither(isDate, isFieldValue, isTimestamp, isNull),
  userId: [validateNonEmptyString],
  categoryId: [validateNumber],
  id: [validateNonEmptyString],
  name: [validateNonEmptyString],
  productColors: validateArrayOf(validateGroupProductColor, 1),
  archived: isBoolean,
  ebayStoreCategoryId: [validateNonEmptyString],
  ebayStoreCategory2Id: [validateNonEmptyString],
}, {archived: false});

export const isProductGroupModel = guardOnly(validateGroupModel);

export function validateProductGroupOutput(g: ProductGroupModel): g is (ProductGroup<NoSentinelOutput>|NewProductGroup) {
  return isValid<ProductGroup<NoSentinelOutput>|NewProductGroup>({
    name: validateNonEmptyString,
    lastWrite: isEither(isDate, isTimestamp),
    productColors: validateArrayOf(validateGroupProductColor, 1),
    userId: validateNonEmptyString,
    archived: isBoolean,
    ebayStoreCategoryId: [validateNonEmptyString],
    ebayStoreCategory2Id: [validateNonEmptyString],
  
  }, {archived: false})(g)
}

export const isProductGroup = guardOnly(validateProductGroupOutput);

export const PRODUCT_GROUP_LABELS = {
  'productColors.colorIds': 'Color selections',
  'productColors': 'Product selections',
}