import React = require("react")
import { InputProps } from "./kit/inputTypes"
import { ProductSizePrice, FirewardInput, WardTimestamp } from "@root/shared/lib/database"
import { TextInput } from "./kit/TextInput";
import { FloatInput } from "./kit/FloatInput";
import { Icon } from "./kit/icons";
import classNames = require("classnames");
import { observer } from "mobx-react-lite";
import { observable, action, computed } from "mobx";
import { useController } from "../util/mobxUtils";
import { Model, Assign, randomId, formatDollars } from "@root/shared/lib/x";
import {positiveFloat} from '@root/shared/lib/schemas/errorValidators';
import { cleanupCode } from "@root/shared/lib/skus";
// import { randomId, Model, Assign } from "../util/util";


export type SizePriceModel = Model<ProductSizePrice<Assign<FirewardInput, {number: number}>>> & {id: string};

export type BySizePriceInputProps = {
  value?: SizePriceModel[]|null
  onChange?: (value: SizePriceModel[]) => void
  fixedPrice?: number
} & InputProps;

function switchElements<T>(arr: T[], i1: number, i2: number) {
  const inrange = (s: number, e: number) => (n: number) => s <= n && n <= e;
  const r = inrange(0, arr.length - 1);
  if (!r(i1) || !r(i2)) return arr;
  const a = arr[i1];
  const b = arr[i2];
  const res = [...arr];
  res[i2] = a;
  res[i1] = b;
  return res;
}

class BySizePriceInputController {

  @observable _dirty: boolean = false;
  @observable props: BySizePriceInputProps;

  @observable autofocusItemId: string|null = null;

  constructor(props: BySizePriceInputProps) {
    this.props = props;
    this.autofocusItemId = this.items[0]?.id || null;
  }

  @computed get dirty() {
    return this._dirty && this.props.dirty;
  }

  @computed get items() {
    return this.props.value || []
  }

  @computed get error() {
    return this.dirty ? this.props.error : null;
  }

  @action setProps(props: BySizePriceInputProps) {
    this.props = props;
    if (!props.dirty) this._dirty = false;
  }

  @action onItemChange = (next: SizePriceModel) => {
    const value = this.items.map((item) => item.id === next.id ? {...item, ...next} : item);
    this.onChange(value);
  }
  @action addItem = ()=>{
    const id = randomId();
    this.autofocusItemId = id;
    this.onChange([...this.items, {id}]);
  }
  @action removeItem = (rem: SizePriceModel) => {
    const i = this.items.findIndex(item => item.id == rem.id);
    this.autofocusItemId = (this.items[i - 1] || this.items[i + 1])?.id || null;
    this.onChange(this.items.filter(item => item.id != rem.id));
  }
  @action moveItemUp = (item: SizePriceModel) => {
    const i = this.items.findIndex(x => x.id == item.id);
    this.onChange(switchElements(this.items, i, i - 1));
  }
  @action moveItemDown = (item: SizePriceModel) => {
    const i = this.items.findIndex(x => x.id == item.id);
    this.onChange(switchElements(this.items, i, i + 1));
  }
  @action onChange = (items: SizePriceModel[]) => {
    this._dirty = true;
    this.props.onChange && this.props.onChange(items)
  }


}

export const BySizePriceInput = observer(function BySizePriceInput(props: BySizePriceInputProps) {
  
  const self = useController(()=>new BySizePriceInputController(props), props);

  const newInputRef = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    // if (newInputRef.current) newInputRef.current.focus();
  }, [newInputRef.current, self.autofocusItemId]);
  
  return <div className={classNames(
    "BySizePriceInput relative rounded border border-solid p-2",
    props.className,
  )}>
    <table className="">
      <thead>
        <tr>
          <th className="flex items-center w-64">Size</th>
          <th><div className="flex content-start items-center w-64">
            Price 
            {' '}
            <button onClick={self.addItem} className="ml-4 focus:shadow-outline-blue focus:outline-none focus:border-blue-300"><Icon name="circlePlus" color="success" /></button>
          </div></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
      {
        self.items.map((item, i) => <tr key={item.id} className='my-2'>
            
            <td className="">
              <TextInput
                className="mr-2"
                dirty={props.dirty}
                ref={item.id === self.autofocusItemId ? newInputRef : undefined}
                maxLength={10}
                filter={cleanupCode}
                value={item.size || ''}
                onChange={size => self.onItemChange({...item, size})}
                errorFunc={val => val ? null : `Please enter a size`}
              />
            </td>       

            <td className="">
              { props.fixedPrice===undefined ?
                <FloatInput
                  dirty={props.dirty}
                  disabled={props.fixedPrice!==undefined}
                  currency
                  unitsPrefix="$"
                  maxLength={10}
                  value={props.fixedPrice===undefined ? item.price : props.fixedPrice}
                  onChange={price => self.onItemChange({...item, price})}
                  errorFunc={v=>positiveFloat(v)}
                />
                : null //formatDollars(props.fixedPrice)
              }
            </td>

            <td>
              <Icon 
                name="trash"
                color="danger"
                onClick={() => self.removeItem(item)}
              />
              {
                i < self.items.length - 1 &&
                <Icon 
                  name="circleDownArrow"
                  color="default"
                  onClick={() => self.moveItemDown(item)}
                />
              }
              { i > 0 &&
                <Icon 
                  name="circleUpArrow"
                  color="default"
                  onClick={() => self.moveItemUp(item)}
                />
              }
              
            </td>

        </tr>)
      }
      </tbody>
    </table>
    { self.error &&
      <div className="error">{self.error}</div>
    }
  </div>
})
