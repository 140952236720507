import React = require('react');
import { useState, useCallback } from 'react';
import Spinner = require('react-spinkit');
import { LinkText } from './LinkText';
import { Button } from './Button';
import { guardOnly, hasChild, isTypeOf, isValid } from '@root/shared/lib/x';
import { ButtonBar } from './ButtonBar';
type BaseProps = {
  name: string;
  logo: string | JSX.Element;
  loading: boolean;
  allowReconnect: boolean;
  connectedDate?: Date | null;
  description?: string;
};
type PropsWithAction = BaseProps & {onConnectAction: () => Promise<void>}
type PropsWithForm = BaseProps & {connectForm: React.ReactNode; onSaveForm: ()=>Promise<void>}

export type ConnectionListItemProps = PropsWithAction | PropsWithForm;

const isPropsWithAction = (p: ConnectionListItemProps): p is PropsWithAction => 
  guardOnly(isValid({onConnectAction: isTypeOf('function')}))(p);

const isPropsWithForm = (p: ConnectionListItemProps): p is PropsWithForm => 
  !isPropsWithAction(p)

export const ConnectionListItem = (props: ConnectionListItemProps)=>{

  const [ loading, setLoading ] = useState<boolean>(false);
  const [ showForm, setShowForm ] = React.useState<boolean>(false);
  
  const onConnectClick = useCallback(() => {
    if (isPropsWithAction(props)) {
      setLoading(true)
      props.onConnectAction().then(()=>setLoading(false)).catch(()=>setLoading(false))
    } else {
      setShowForm(true)
    }
  }, []);

  const onSaveForm = React.useCallback(() => {    
    setLoading(true);
    if (isPropsWithForm(props)) {
      props.onSaveForm().then(() => {
        setLoading(false);
        setShowForm(false);
      }).catch(e => {
        setLoading(false);
      })
    }
  }, [isPropsWithForm(props) && props.onSaveForm]);

  const onCancelForm = React.useCallback(() => {
    setShowForm(false);
  }, [isPropsWithForm(props) && props.onSaveForm]);

  const busy = loading || props.loading;

  const form = isPropsWithAction(props) ? null : showForm && <form onSubmit={onSaveForm}>
    {props.connectForm}
    <ButtonBar>
      <Button text="Cancel" outline onClick={onCancelForm} disabled={busy} />
      <Button text="Connect" onClick={onSaveForm} spinner={busy} />
    </ButtonBar>
  </form>;


  

  return <div className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
    <div className="flex items-center px-4 py-4 sm:px-6">
      <div className="min-w-0 flex-1 flex items-center">
        <div className="flex-shrink-0">
        
        {
          typeof props.logo === 'string'
          ? <img className="max-h-12 w-12" src={props.logo} alt="" />
          : <div className="max-h-12 w-12">{props.logo}</div>
        }

        </div>
        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
          <div className={props.description ? '' : 'flex items-center'}>
            <div className="text-sm leading-5 font-medium text-indigo-600 truncate">
              {props.name}
            </div>
            <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
              {props.description}
            </div>
          </div>
          <div className="hidden md:block">
            {
              props.loading
              ? <Spinner name="circle"/>
              : form && showForm
              ? form
              : props.connectedDate ?
                <div>
                  <div className="text-sm leading-5 text-gray-900">
                    Connected on <time dateTime={props.connectedDate.toDateString()}>{props.connectedDate.toDateString()}
                    </time>
                  </div>
                  <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
                    <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"/>
                    </svg>
                    <span>Connected</span> 
                    {
                      props.allowReconnect && 
                        <LinkText onClick={() => isPropsWithForm(props) ? setShowForm(true) : onConnectClick()} bold className="ml-2">
                          Reconnect
                        </LinkText>
                    }
                  </div>
                </div>
              : <div>
                  
                    <Button onClick={onConnectClick} text="Connect" outline color="secondary" />
                  
                </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
}
