//https://signin.sandbox.ebay.com/ws/eBayISAPI.dll?SignIn&runame=Abstract_Subjec-Abstract-object-uuphcbzr&SessID=<SESSION_ID>

import { WebDependencies } from "../WebDependencies";
import { computed, observable, autorun, action, flow } from "mobx";
import { UserStore } from "./UserStore";
import { queryAction, snapAction, snapData, upsertChanges } from "../util/util";
import { UserDropbox, UserEbay, RegisteredEbayCategory, FirewardOutput } from "@root/shared/lib/database";
import { authAutorun } from "./storeUtils";
import type { EbayTradingAPI } from "@root/shared/lib/clients/EbayTrading";
import { EbayCategoriesRequest } from "@root/shared/lib/CallableRoutes";
import { EbayCategory, EbayTradingStore } from "@root/shared/lib/clients/EbayTradingTypes";
import { ErrorStore } from "./ErrorStore";
import { AlertStore } from "./AlertStore";
import { boolean } from "mobx-state-tree/dist/internal";
import { GetSellerProfilesResponse, isEbayBusinessPoliciesErrorOutput } from "@root/shared/lib/clients/EbayBusinessPoliciesTypes";
import { errorToString } from "@root/shared/lib/x";

export class EbayStore {
  
  private readonly deps: WebDependencies;
  private readonly user: UserStore
  private readonly error: ErrorStore
  
  private readonly alert: AlertStore
  
  @observable private _data: null|undefined|UserEbay = undefined;

  @observable private _redirecting: boolean = false;

  @observable completing = false;

  @observable private registeredCats: RegisteredEbayCategory<FirewardOutput>[]|null|undefined = undefined;
  
  @observable private _policies: GetSellerProfilesResponse|null|undefined = undefined;

  @observable private _ebayStore: EbayTradingStore | undefined | null;
  
  
  
  constructor(deps: WebDependencies, user: UserStore, error: ErrorStore, alert: AlertStore) {
    this.deps = deps;
    this.user = user;
    this.error = error;
    this.alert = alert;
    user.onAuth(uid => {
      if (uid) {
        deps.fireCall('getEbayStore', {})
        .then(action(store => this._ebayStore = store || null))
        .catch(action(e => {
          this._ebayStore = null;
        }))
      }
    });
    user.onAuth(uid => {
      if (uid) {
          this._data = undefined;
          this._policies = undefined;
          return this.dataRef(uid)
            .onSnapshot( snapAction(snap => {
              this.setData(snapData<UserEbay>(snap));
              if (this.data) this.updatePolicies();
              else this._policies = null;
            }))
        } else this.setData(null)
      }
    );

    user.onAuth(uid => {
      if (uid) {
        this.registeredCats = undefined;
        return deps
          .firebase.firestore()
          .collection('users').doc(uid).collection('ebay-categories').limit(200)
          .onSnapshot(queryAction(snap => {

            this.registeredCats = this.registeredCats || [];
            upsertChanges(this.registeredCats, snap, c => c.categoryId.toString());

          }), e => {
            error.captureException(e, {tags: {place: 'listen to registered categories error'}});
            alert.error(['Database error: ' + e.message]);
          });
      } else {
        this.registeredCats = null
      }
    });
  }

  private dataRef(uid: string) {
    return this.deps
      .firebase.firestore()
      .collection('ebay').doc(uid);
  }
  @computed public get ebayStore(): EbayTradingStore | undefined | null {
    return this._ebayStore;
  }
  
  @computed get policySetupUrl() {
    return this.deps.config.stage === 'production'
      ? `https://www.bizpolicy.ebay.com/businesspolicy/manage`
      : `https://www.bizpolicy.sandbox.ebay.com/businesspolicy/manage`
  }

  @computed get data() {
    return this._data;
  }

  @computed get redirecting() {
    return this._redirecting
  }
  
  @computed get policies() {
    return this._policies;
  }

  @computed get registeredCategories() {
    return this.registeredCats
  }

  @action.bound private setData(data: UserEbay|null|undefined) {
    this._data = data;
  }

  @action private setPolicies = (data: GetSellerProfilesResponse|null|undefined) => {
    this._policies = data;
  }

  @action.bound async redirectToAuth() {
    this._redirecting = true;
    const r = await this.deps.fireCall('getEbaySessionID', {});
    this.deps.location.href = r.url;
  }
  @action.bound async completeConnection(username?: string){
    const r = await this.deps.fireCall('completeEbayConnection', {username});
    this.setData(r);
    return r;
  }

  @action.bound unregisterCategory(uid: string, categoryId: number) {
    
    if (!this.registeredCats) return;

    const cat = this.registeredCats.find(c=>c.categoryId==categoryId)
    if (cat) {
      return this.deps.firebase.firestore()
      .collection('users').doc(uid).collection('ebay-categories').doc(cat.categoryId.toString())
      .delete();
    } else {
      return Promise.resolve();
    }
  }

  listCategories = async (opt: EbayCategoriesRequest) => {
    
    const cats = await this.deps.fireCall('getEbayCategories', opt)
    return cats.Categories;
  }
  getSpecifics = (opt: {categoryId: number}) => {
    return this.deps.fireCall('getCategoryAspects', opt);
  }
  updatePolicies = () => {
    return this.deps.fireCall('getBusinessPoliciesFull', {profileType: ['SHIPPING']})
      .then(this.setPolicies)
      ;
  }
  
  registerCategory = (uid: string, ecat: RegisteredEbayCategory) => {

    // const cat: RegisteredEbayCategory = {
    //   categoryId: ecat.CategoryID,
    //   name: ecat.CategoryName,
    //   userId: uid,
    //   categoryLevel: ecat.CategoryLevel,
      
    // }
    
    return this.deps.firebase.firestore()
      .collection('users').doc(uid).collection('ebay-categories').doc(ecat.categoryId.toString())
      .set(ecat)
  }
}
