import { InputProps } from "./kit/inputTypes"
import classNames = require("classnames")
import React = require("react")
import { observer } from "mobx-react-lite"
import { action, observable, computed } from "mobx"
import { useController } from "../util/mobxUtils"
import { Icon } from "./kit/icons"
import { TextInput } from "./kit/TextInput"
import { ProductColorOption, FirewardInput, ProductSizePrice } from "@root/shared/lib/database"
import { Checkbox } from "./kit/Checkbox"
import { ClassValue } from "classnames/types"
import { Model } from "@root/shared/lib/x"

export type AvailableSizesInputProps = {
  disabledSizeIds?: string[]
  sizes: ProductSizePrice[]
  value?: string[]
  error?: string|null|false
  dirty?: boolean
  onChange?: (value: string[]) => void
  className?: ClassValue
}
class AvailableSizesInputController {
  @observable props: AvailableSizesInputProps;
  
  constructor(props: AvailableSizesInputProps) {
    this.props = props;
  }

  @action setProps = (props: AvailableSizesInputProps) => this.props = props;

  @action itemChange = (id: string, on: boolean) => {
    const without = this.value.filter(prev => prev !== id)
    this.props.onChange && this.props.onChange(
      on ? [...without, id] : without
    )
  }

  @computed get value() {
    return this.props.value || []
  }

  @computed get disabledSizeIds() {
    return this.props.disabledSizeIds || []
  }

  @computed get options() {
    return this.props.sizes.map(s=>({
      id: s.id,
      label: s.size,
      checked: this.value.indexOf(s.id) > -1,
      disabled: this.disabledSizeIds.indexOf(s.id) > -1 && this.value.indexOf(s.id) == -1
    }))
  }


}
export const AvailableSizesInput = observer(function AvailableSizesInput(props: AvailableSizesInputProps) {
  
  const self = useController(()=>new AvailableSizesInputController(props), props);

  return <div className={classNames('AvailableSizesInput', props.className)}>
    <div className="flex">
      {
        self.options.map(o => <div key={o.id} className="flex flex-col items-center w-6">
          <div className="label h-0 invisible">{o.label}</div>
          <Checkbox value={o.checked} disabled={o.disabled} onChange={checked => self.itemChange(o.id, checked)}/>
        </div>)
      }
    </div>
      {
        props.error && <div className="error text-xs">
          {props.dirty && props.error}
        </div>
      }
  </div>
})
