import React = require('react');
import { observer } from 'mobx-react-lite';
import { observable, action, autorun, computed, reaction } from 'mobx';
import { useController } from '../util/mobxUtils';
import classNames = require('classnames');
import { MainStore } from '../store/store';
import { useStore } from '../store/StoreContext';
import { ClassValue } from 'classnames/types';
import { Button } from './kit/Button';
import { Icon } from './kit/icons';
import { DesignQueueItem, FirewardOutput } from '@root/shared/lib/database';
import { errorToObject } from '@root/shared/lib/x';
import { SpinnerOverlay } from './kit/SpinnerOverlay';
import { Login } from './Login';
import { Spinner } from './kit/Spinner';


export type DesignQueuePageProps = {
  className?: ClassValue
}

type ItemStatus = 'tryAgain' | 'delete' | 'interrupt'

class DesignQueuePageController {

  @observable private started = false;
  @observable private props: DesignQueuePageProps;
  store: MainStore

  @observable itemBusy: Record<string, ItemStatus | undefined | false > = {}
  constructor(props: DesignQueuePageProps, store: MainStore) {
    this.props = props;
    this.store = store;
    
    reaction(() => this.user?.uid, uid => {
      if (uid) this.listen();
    }, {fireImmediately: true})
  }

  @action setProps = (props: DesignQueuePageProps) => {
    this.props = props;
  }

  @computed get user() {
    return this.store.user.user
  }

  @action listen = () => {
    const uid = this.user?.uid;
    if (!uid) return;
    this.started = true;
    this.store.listings.listenToDesignQueueItems(uid);
  }
  @computed get listening() {
    return !!this.store.listings.designQueueItems || this.started;
  }

  @computed get items() {
    return this.store.listings.designQueueItems;
  }

  @action deleteItem = (item: DesignQueueItem<FirewardOutput>) => {
    const uid = this.user?.uid;
    if (!uid) return;
    this.itemBusy[item.id] = 'delete';
    this.store.listings.deleteQueueItem(item, uid)
    .then(action(() => {
      this.itemBusy[item.id] = false;
    }))
    .catch(action(e => {
      this.itemBusy[item.id] = false;
      this.store.error.captureException(e);
      this.store.alert.error([errorToObject(e)])
    }))
  }

  @action interruptItem = (item: DesignQueueItem<FirewardOutput>) => {
    if (this.store.util.confirm(`Warning! Do not interrupt these items unless they have been stuck for a long time (over 15 minutes).`))
    this.updateItem({id: item.id, queuedForProcessing: false, processing: false}, 'interrupt')
  }

  @action updateItem = (item: Partial<DesignQueueItem<FirewardOutput>> & {id: string}, status: ItemStatus) => {
    const uid = this.user?.uid;
    if (!uid) return;
    
    this.itemBusy[item.id] = status;
    this.store.listings.updateQueueItem({...item, lastWrite: new Date()}, uid)
    .then(action(() => {
      this.itemBusy[item.id] = false;
    }))
    .catch(action(e => {
      this.itemBusy[item.id] = false;
      this.store.error.captureException(e);
      this.store.alert.error([errorToObject(e)])
    }))
  }
  @action tryAgain = (item: DesignQueueItem<FirewardOutput>) => {
    this.updateItem({
      id: item.id,
      error: null,
      queuedForProcessing: true
    }, 'tryAgain')
  }

}

export const DesignQueuePage = observer(function DesignQueuePage(props: DesignQueuePageProps) {
  const store = useStore();
  const self = useController(() => new DesignQueuePageController(props, store), props);
  if (self.user === undefined) {
    return <SpinnerOverlay/>
  }
  if (!self.user) {
    return <Login/>
  }
  return <div className={classNames('DesignQueuePage', props.className)}>
    {
      self.listening
      ? <div>
          {
            self.items ? <ul>
              {
                self.items.map(item => <li key={item.id} className="flex flex-col mb-2">
                  <div className="flex items-center">
                    <div className="w-8">
                      {
                        item.queuedForProcessing
                        ? (
                          item.processing 
                          ? <Spinner small />
                          : <Icon name="clock" color="default"/>
                        )
                        : item.error
                        ? <Icon name="circleExclamation" color="danger"/>
                        : <Icon name="circleQuestion" color="danger" title="Unexpected status."/>
                      }
                    </div>

                    <div className="mx-2 text-sm text-gray-600 mb-1">
                      {item.cloudDisplayPath} [{item.lastWrite?.toDate().toLocaleTimeString()}]
                    </div>
                    
                  </div>
                  {
                    !item.queuedForProcessing && <div className="flex flex-col cm-2 ml-8">
                      {item.error && <span className="error text-sm mb-1 pl-2">{item.error}</span>}
                      <div className="flex cm-2">
                        <Button noBorder disabled={!!self.itemBusy[item.id]} spinner={self.itemBusy[item.id] == 'tryAgain'} text="Try again" onClick={() => self.tryAgain(item)} />
                        <Button noBorder color="danger" disabled={!!self.itemBusy[item.id]} spinner={self.itemBusy[item.id] == 'delete'} text="Delete" onClick={() => self.deleteItem(item)} />
                      </div>
                    </div>
                  }
                  {
                    item.queuedForProcessing && <div className="flex cm-2 items-end">
                      <span className="w-8"/>
                      <Button noBorder color="danger" text="Interrupt" spinner={self.itemBusy[item.id] == 'interrupt'} onClick={() => self.interruptItem(item)} />
                    </div>
                  }
                </li>)
              }
            </ul> : <SpinnerOverlay/>            
          }
          {
            self.items?.length === 0 && <div className="text-sm text-gray-600">
              No DropBox files are currently being processed.
            </div>
          }
        </div>
      : <div>
          <div className="text-sm text-gray-600">
            This page does not activate by default to conserve your computer's resources. Press the button below to activate the listing.
          </div>
          <Button text="Start tracking queue items" onClick={self.listen} />
        </div>
    }
  </div>
});