export const EBAY_MARKETPLACES_WITH_DEF_CATEGORIES = {
  EBAY_AU: `eBay Australia (ebay.com/au)`,
  EBAY_AT: `eBay Austria (ebay.at)`,
  EBAY_BE: `eBay Belgium (benl.ebay.be)`,
  EBAY_CA: `eBay Canada (English) (ebay.ca)`,
  EBAY_FR: `eBay France (ebay.fr)`,
  EBAY_DE: `eBay Germany (ebay.de)`,
  EBAY_HK: `eBay Hong Kong (ebay.com.hk)`,
  EBAY_IN: `eBay India (ebay.in)`,
  EBAY_IE: `eBay Ireland (ebay.ie)`,
  EBAY_IT: `eBay Italy (ebay.it)`,
  EBAY_MY: `eBay Malaysia (ebay.com/my)`,
  EBAY_MOTORS_US: `eBay Motors US (ebay.com/motors)`,
  EBAY_NL: `eBay Netherlands (ebay.nl)`,
  EBAY_PH: `eBay Philippines (ebay.ph)`,
  EBAY_PL: `eBay Poland (ebay.pl)`,
  EBAY_RU: `eBay Russia (ebay.com/sch/Russia)`,
  EBAY_SG: `eBay Singapore (ebay.com/sg)`,
  EBAY_ES: `eBay Spain (ebay.es)`,
  EBAY_CH: `eBay Switzerland (ebay.ch)`,
  EBAY_GB: `eBay UK (ebay.co.uk)`,
  EBAY_US: `eBay US (ebay.com)`,
}

export type EbayTaxonomyMarketplaceName = keyof typeof EBAY_MARKETPLACES_WITH_DEF_CATEGORIES;

export type EbayTaxonomyItemAspectValue = {
  "localizedValue": "string";
  "valueConstraints": [
    {
      "applicableForLocalizedAspectName": string;
      "applicableForLocalizedAspectValues": string[];
    }
  ];
};

export type EbayTaxonomyItemAspect = { /* Aspect */
  aspectConstraint?: { /* AspectConstraint */
    "aspectApplicableTo"?: ('ITEM'|'PRODUCT')[],
    "aspectDataType": string|number|string[],
    "aspectEnabledForVariations": boolean,
    "aspectFormat": string,
    "aspectMaxLength": number,
    "aspectMode": 'FREE_TEXT'|'SELECTION_ONLY', // "AspectModeEnum : [FREE_TEXT,SELECTION_ONLY]",
    "aspectRequired": boolean,
    "aspectUsage": 'RECOMMENDED'|'OPTIONAL',//"AspectUsageEnum : [RECOMMENDED,OPTIONAL]",
    "expectedRequiredByDate": string,
    "itemToAspectCardinality": 'MULTI'|'SINGLE',//"ItemToAspectCardinalityEnum : [MULTI,SINGLE]"
  },
  "aspectValues": EbayTaxonomyItemAspectValue[],
  "localizedAspectName": string,
  "relevanceIndicator":
  { /* RelevanceIndicator */
    "searchCount": number // integer
  }
}
export type EbayTaxonomyErrorResponse = {
  "errors": [
    {
      "errorId": number,
      "domain": "API_TAXONOMY",
      "category": "REQUEST",
      "message": string,
      "parameters": {
        "name": string //"pathParam",
        "value": number // "category_tree_id"
      }[]
    }
  ]
}
export type EbayTaxonomyItemAspectsByCategory = { /* AspectMetadata */
  "aspects" : EbayTaxonomyItemAspect[]
}

export type EbayTaxonomyItemAspectsByCategoryResponse = EbayTaxonomyItemAspectsByCategory | EbayTaxonomyErrorResponse

export const isEbayTaxonomyErrorResponse = (t: EbayTaxonomyItemAspectsByCategory | EbayTaxonomyErrorResponse): t is EbayTaxonomyErrorResponse => 
  !!t && !!(t as any).errors && (t as any).errors.length > 0