import { WebDependencies, FirebaseUI } from "../WebDependencies";
import { User, AuthData } from "@root/shared/lib/database";
import type * as firebase from 'firebase';
import { queryAction, snapAction, snapData } from "../util/util";

import { observable, action, computed, runInAction, flow } from 'mobx'


type AuthUI = import('firebaseui').auth.AuthUI;
type AuthListenerHandler = (uid: string | undefined, auth: firebase.User|null) => (void | (() => void));

type AuthListener = {
  handler: AuthListenerHandler,
  off: (()=>void)|null
};
export class UserStore {

  private deps: WebDependencies;
  @observable private _user: User|null|undefined = undefined;
  @observable private _state: firebase.User|undefined|null = undefined
  @observable private ui: AuthUI|null = null

  @observable error: unknown = null
  private userListener: null|(()=>void) = null;

  private authListeners: AuthListener[] = [];

  constructor(deps: WebDependencies) {
    
    this.deps = deps;
    deps.firebase.app().auth().onAuthStateChanged(this.onAuthStateChanged.bind(this));
  }
  @action private clearListener = () => {
    if (this.userListener) {
      this.userListener();
      this.userListener = null;
    }
  }
  private onAuthStateChanged = flow(function*(this: UserStore, state: firebase.User|null) {
    this._state = state || null;

    if (state) {
      
      const uref = this.deps.firebase.firestore().collection('users').doc(state.uid);
      const snap = yield uref.get({source: 'server'});
      
      this._user = snapData(snap, null);

      if (!snap.exists) {
        yield this.createUser(state);
      }
      this.clearListener();
      this.userListener = uref.onSnapshot(snapAction(snap => this._user = snapData(snap, null)))
      
    } else {
      this.clearListener();
      this._user = null;
      this._state = null;
    }

    this.authListeners.forEach(this.callListener(state));
  })
  @action private callListener = (state: firebase.User|undefined|null) => {
    return (listener: AuthListener) => {
      if (state) listener.off = listener.handler( state.uid, state ) || null;
      else listener.handler(undefined, null);
    }
  }
  onAuth = (handler: AuthListenerHandler) => {
    const listener = { handler, off: null };
    this.authListeners.push(listener);
    if (this.state!==undefined) this.callListener(this.state)(listener);
  }
  offAuth = (handler: AuthListenerHandler) => {
    const i = this.authListeners.findIndex(x => x.handler === handler);
    if (i > -1) this.authListeners.splice(i, 1);
  }
  onAuthOnce = (handler: AuthListenerHandler) => {
    const _handler: AuthListenerHandler = (uid, state) => {
      if (uid) {
        handler(uid, state);
        this.offAuth(_handler)
      }
    };
    this.onAuth(_handler)
  }
  getUserId = (): Promise<string> => {
    return new Promise((res, rej) => {
      this.onAuthOnce(uid => { uid && res(uid) });
    })
  }
    

  @computed({keepAlive: true}) get user() {
    return this._user
  }
  @computed get state() {
    return this._state
  }
  @computed get initializing() {
    return this._state===undefined || !!this._state && this._user === undefined;
  }

  @action logout = ()=>{
    this.authListeners.forEach( action( item => {
      if (item.off) {
        item.off();
        item.off = null;
      }
    }));
    return this.deps.firebase.auth().signOut();

  }
  @action loginWithUI = (selector?: string)=>{
    if (!this.ui) this.ui = new this.deps.firebaseui.auth.AuthUI(this.deps.firebase.auth())
    this.ui.start(selector || '#firebaseui-auth-container', {
      signInOptions: [
        this.deps.firebase.auth.EmailAuthProvider.PROVIDER_ID
      ],
      callbacks: {
        signInSuccessWithAuthResult: (state)=>false
      }
    });
  }
  @action loginWithPwd = (o:{email: string, password: string}) => {
    return this.deps.firebase.auth().signInWithEmailAndPassword(o.email, o.password)
      .then( res => {
        return res;
        // res.additionalUserInfo == 
      }).catch(e=>{
        /// @ts-ignore
        window.eeee = e
        console.log(e)
        throw e;
      })
  }
  @action signupWithPwd = (o:{email: string, password: string}) => {
    return this.deps.firebase.auth().createUserWithEmailAndPassword(o.email, o.password)
      .then( res => {
        return res;
        // res.additionalUserInfo == 
      }).catch(e=>{
        /// @ts-ignore
        window.eeee = e
        console.log(e)
        throw e;
      })
  }
  @action resetPwd = (email: string) => {
    return this.deps.firebase.auth().sendPasswordResetEmail(email)
    .catch(e=>{
      /// @ts-ignore
      window.eeee = e
      console.log(e)
      throw e;
    })
  }

  @computed get loadingUser() {
    return this._user === undefined;
  }

  @computed get loadingAuth() {
    return this._state === undefined;
  }
  updateUser = (updates: Partial<User> & {uid: string}) => {
    return this.userRef(updates.uid).update(updates)
  }
  
  private async createUser(state: firebase.User) {
    

    const uid = state.uid;
    const deps = this.deps;

    const auth: AuthData = {
      uid,
      emailVerified: state.emailVerified || false,
      isAnonymous: state.isAnonymous || false,
    }
    
    if (state.email) auth.email = state.email;

    if (state.photoURL) auth.photoURL = state.photoURL;
    if (state.providerId) auth.providerId = state.providerId;
    if (state.phoneNumber) auth.phoneNumber = state.phoneNumber;
    if (state.tenantId) auth.tenantId = state.tenantId;
    if (state.displayName) auth.displayName = state.displayName;


    
    const user: User = {
      uid, auth
    }
    await this.userRef(uid).set(user)
    return user;
  }

  private userRef(uid: string) {
    return this.deps.firebase.firestore().collection('users').doc(uid);
  }
}
