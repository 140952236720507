import { EbayTradingListingDuration } from "../database";

export const ebayTradingListingDurations: Record<EbayTradingListingDuration, string> = {
  "Days_1": `This value is used to set the duration of the listing to one day. A one-day listing duration is typically only available to sellers with a Feedback score of 10 or higher, so sellers with a Feedback score of less than 10 may be restricted from using a one-day listing duration. The seller can call GetCategoryFeatures with ListingDurations included as a FeatureID value in the call request to see if the one-day listing duration is available.
  A one-day listing duration is generally applicable to an auction listing or to a Real Estate Classified Ad.
  `,

  "Days_10": `This value is used to set the duration of the listing to 10 days.`,
  "Days_120": `This value is used to set the duration of the listing to 120 days.
      An 120-day listing duration is typically only applicable to Classified Ad listings in specific categories.
  `,

  "Days_14": `This value is used to set the duration of the listing to 14 days.`,
  "Days_21": `This value is used to set the duration of the listing to 21 days.
  Note: A 21-day listing duration was typically only applicable to eBay Motors Local Market vehicle listings, but this listing duration is no longer valid/supported.
  `,


  "Days_3": `This value is used to set the duration of the listing to three days.`,
  "Days_30": `This value is used to set the duration of the listing to 30 days.
  Note: In October 2018, this listing duration was enabled for eBay Motors Local Listings, and replaced the formerly supported Days_21 enumeration value.
  `,


  "Days_5": `This value is used to set the duration of the listing to five days.`,
  "Days_60": `This value is used to set the duration of the listing to 60 days.
  A 60-day listing duration is typically only applicable to Classified Ad listings in specific categories.`,
  "Days_7": `This value is used to set the duration of the listing to seven days.`,
  "Days_90": `This value is used to set the duration of the listing to 90 days.
    A 90-day listing duration is generally only applicable to a Real Estate Classified Ad.
  `,

  "GTC": `This value is used to set the duration of the listing to 'Good 'Til Cancelled. This option is available for fixed-price and Classified Ad listings. "Good 'Til Cancelled" fixed-price listings will be relisted automatically each month until all inventory is sold out (e.g., Item.Quantity=0 in a single-variation fixed-price listing, or all occurences of Item.Variations.Variation.Quantity=0 in a multi-variation fixed-price listing), or the seller ends the fixed-price listing.`

}