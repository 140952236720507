import React = require("react")
import { observer } from "mobx-react-lite"
import { useStore } from "../store/StoreContext"
import { useRouting } from "../routing/routes"
import { useErrorState } from "../util/useErrorState"
import { EbayCategory, EbayCategorySpecific, NameRecommendation } from "@root/shared/lib/clients/EbayTradingTypes"
import { useState } from "react"
import { SpinnerOverlay } from "./kit/SpinnerOverlay"
import { Select } from "./kit/Select"
import { CheckboxGroup } from "./kit/CheckboxGroup"
import { action, computed, observable, values } from "mobx"
import { TextInput } from "./kit/TextInput"
import { Checkbox } from "./kit/Checkbox"
import { Button } from "./kit/Button"
import spinner = require("react-spinkit")
import { Assign, errorToString, keys, randomId, uniq } from "@root/shared/lib/x"
import { Login } from "./Login"
import { ButtonBar } from "./kit/ButtonBar"
import { MainStore } from "../store/store"
import { useController } from "../util/mobxUtils"
import { WebDependencies } from "../WebDependencies"
import { FirewardInput, FirewardOutput, RegisteredEbayCategory } from "@root/shared/lib/database"
import { Icon } from "./kit/icons"
import { RadioInput } from "./kit/Radio"
import { EbayTaxonomyItemAspect, EbayTaxonomyItemAspectsByCategory, isEbayTaxonomyErrorResponse } from "@root/shared/lib/clients/EbayTaxonomyTypes"

export type EbayCategoryEditorProps = {
  categoryId?: string
  catLevel?: string
}
type CatFormProps = {
  userId: string
  category: EbayCategory
  specifics: EbayTaxonomyItemAspectsByCategory
  previous?: RegisteredEbayCategory<Assign<FirewardOutput, {array: never}>>
  save(cat: RegisteredEbayCategory): Promise<void>|undefined
  cancel(): void
}

class CatFormController {
  props: CatFormProps
  store: MainStore

  @observable saving = false;
  @observable dirty = false;

  @observable sizeSpecificName: string;
  @observable colorSpecificName: string;

  @observable customTitle: string;


  @observable ebaySpecifics: Record<string, boolean> = {}
  @observable customSpecifics: {name: string, id: string, required: boolean}[];

  readonly customSpecificInit = "My Specific Name";
  
  constructor(props: CatFormProps, store: MainStore) {
    this.props = props;
    this.store = store;
    

    // const ebaySpecNames = props.specifics.Recommendations.NameRecommendation.map(r => r.Name);
    const ebaySpecNames = props.specifics.aspects.map(a => a.localizedAspectName);
    
    this.customSpecifics = props.previous?.specifics
      .filter(spec => !ebaySpecNames.includes(spec.name))
      .map(s => ({required: true, ...s})) 
      || []
    ;

    this.customTitle = props.previous?.customTitle || '';

    this.sizeSpecificName = props.previous?.sizeSpecificName ||
      this.props.specifics.aspects.find(aspect => aspect.localizedAspectName.toLowerCase() == 'size')?.localizedAspectName ||
      'Size';
      // this.props.specifics.Recommendations.NameRecommendation
      // .find(spec => spec.Name.toLocaleLowerCase() == 'size')?.Name || 'Size'
    ;

    this.colorSpecificName = props.previous?.colorSpecificName ||
      this.props.specifics.aspects.find(aspect => aspect.localizedAspectName.toLowerCase() == 'color')?.localizedAspectName || 'Color';
      // this.props.specifics.Recommendations.NameRecommendation
      // .find(spec => spec.Name.toLocaleLowerCase() == 'color')?.Name || 'Color'
    ;
  }

  @computed get colorSpecChoiceDisabled() {
    return !!this.props.specifics.aspects.find(aspect => aspect.localizedAspectName.toLowerCase() == 'color');
    // return !!this.props.specifics.Recommendations.NameRecommendation.find(spec => spec.Name.toLocaleLowerCase() === 'color')
  }
  @computed get sizeSpecChoiceDisabled() {
    return !!this.props.specifics.aspects.find(aspect => aspect.localizedAspectName.toLowerCase() == 'size');
    // return !!this.props.specifics.Recommendations.NameRecommendation.find(spec => spec.Name.toLocaleLowerCase() === 'size')
  }
  @action addCustomSpecific = () => {
    this.customSpecifics.push({name: this.customSpecificInit, id: randomId(), required: true});
  }
  @action removeCustomSpecific = (spec: {name: string, id: string}) => {
    this.customSpecifics = this.customSpecifics.filter(s => s.id !== spec.id);
    if (this.colorSpecificName === spec.name) 
      this.colorSpecificName = this.props.specifics.aspects.find(spec => spec.localizedAspectName !== this.sizeSpecificName)?.localizedAspectName || '';
    if (this.sizeSpecificName === spec.name) 
      this.sizeSpecificName = this.props.specifics.aspects.find(spec => spec.localizedAspectName !== this.colorSpecificName)?.localizedAspectName || '';
  }
  // firebase functions:config:set ebay.verification_token="check-your-mail-you-got-goodies-in-there" -P multi-lister-dev
  specificIncluded = (specificName: string) => {
    return this.customSpecifics.some(s => s.name === specificName) || !!this.ebaySpecifics[specificName]
  }

  @action toggleSpecific = (specificName: string, on: boolean) => {
    this.ebaySpecifics[specificName] = on;
  }

  @action setSizeSpecific = (name: string|undefined) => {
    if (name!==undefined && name !== this.colorSpecificName) this.sizeSpecificName = name;
  }
  
  @action setColorSpecific = (name: string|undefined) => {
    if (name!==undefined && name!==this.sizeSpecificName) this.colorSpecificName = name;
  }

  @computed get error() {
    return this.colorSpecificName == this.sizeSpecificName 
      ? `Color and Size specifics cannot have the same name.`
      : !this.sizeSpecificName || !this.colorSpecificName
      ? `Color and Size specifics are required.`
      : null
  }
  
  @action save = () => {

    const uid = this.store.user.user?.uid;
    if (!uid) return;
    this.dirty = true;

    console.log('colorspec', this.colorSpecificName)

    if (!this.colorSpecificName) return;
    if (!this.sizeSpecificName) return;
    if (this.sizeSpecificName == this.colorSpecificName) return;


    const ebaySpecs = this.props.specifics.aspects
      .map(aspect => ({ name: aspect.localizedAspectName, id: randomId(), required: true }))
    ;

    const specifics = uniq([
      ...this.customSpecifics.filter(s => !!s.name.trim()),
      ...ebaySpecs
    ], (a, b) => a.name === b.name);

    const cat: RegisteredEbayCategory = {
      customTitle: this.customTitle?.trim() || null,
      archived: false,
      categoryId: this.props.category.CategoryID,
      name: this.props.category.CategoryName,
      userId: uid,
      categoryLevel: this.props.category.CategoryLevel,
      colorSpecificName: this.colorSpecificName,
      sizeSpecificName: this.sizeSpecificName,
      specifics
    }

    this.saving = !!this.props.save(cat)
      ?.then(action(() => {
        this.dirty = false;
      }))
      .catch(action(e => {
        this.saving = false;
        this.store.alert.error([errorToString(e)], `Server returned an error.`);
      }))
    ;
    
    
  }
}

const CatForm = observer((props: CatFormProps) => {
  
  const store = useStore();
  const self = useController(()=>new CatFormController(props, store));

  if (!store.user.state) return <Login />

  return <div>
    <div>
      <TextInput
        placeholder={self.props.category.CategoryName}
        value={self.customTitle}
        onChange={val => self.customTitle = val || ''}
        label="Category Custom Title"
      />
    </div>
    <div>
      <TextInput
        readOnly value={props.category.CategoryID.toString()}
        label="Category ID"
      />
    </div>

    <div>
      <div className="my-4">
        <h3 className="mb-2 text-lg">Custom Specifics</h3>
        { self.customSpecifics.length > 0 &&
          <div className="flex text-sm label">
            <span className="w-20">Required</span>
            <span className="w-12">Size</span>
            <span className="w-12">Color</span>
            <span>Specific Name</span>
          </div>
        }
        <ul className="max-w-md mt-2 mb-3">
        {
          self.customSpecifics.map((spec, i) => <li key={spec.id} className="flex items-center content-around">
            <Checkbox 
              className="w-20 mr-3"
              disabled={self.sizeSpecificName == spec.name || self.colorSpecificName == spec.name} 
              value={spec.required || self.sizeSpecificName == spec.name || self.colorSpecificName == spec.name} 
              onChange={val => spec.required = val} 
              title="Indicates if this value is required. Size and color specifics are always required."
              help="required"
              />
            <div className="w-12 flex-shrink-0">
              <RadioInput<string> 
                disabled={self.sizeSpecChoiceDisabled} 
                group="size-specific" 
                onChange={val => { self.setSizeSpecific(val) }} 
                checked={self.sizeSpecificName === spec.name} 
                value={spec.name} 
              />
              {/* <input disabled={self.sizeSpecChoiceDisabled} type="radio" name="size-specific" onChange={e => { if (e.target.checked) self.setSizeSpecific(spec.name)}} checked={self.sizeSpecificName === spec.name}/> */}
              
            </div>
            <div className="w-12 flex-shrink-0">
              <RadioInput<string> 
                disabled={self.colorSpecChoiceDisabled} 
                group="color-specific" 
                onChange={val => { self.setColorSpecific(val)}} 
                checked={self.colorSpecificName === spec.name} 
                value={spec.name} 
                />
            </div>
            <TextInput
              value={spec.name}
              onChange={val => { 
                if (self.colorSpecificName==spec.name) self.colorSpecificName = val || '';
                if (self.sizeSpecificName==spec.name) self.sizeSpecificName = val || '';
                spec.name = val || '';
              }}
              errorFunc={val => val ? null : `value required`}
              dirty={self.dirty}
            />
            
            <div className="ml-2 flex items-center">
              <Icon name="trash" onClick={()=>self.removeCustomSpecific(spec)} color="danger" />
            </div>
          </li>)  
        }
        </ul>
        {
          self.error && self.dirty && <div className="error text-xs">
            {self.error}            
          </div>
        }
        <Button onClick={self.addCustomSpecific} icon="circlePlus" text="New Custom Specific" disabled={self.saving} outline />
      </div>
      
      { props.specifics.aspects.length > 0 &&
        <div>
          <h3 className="text-lg mb-2 mt-3">eBay Required Specifics</h3>
          <div className="flex text-sm label mb-2">
            <span className="w-12">Size</span>
            <span className="w-12">Color</span>
            <span>Specific</span>
          </div>
          {
            props.specifics.aspects.map(aspect => 
              <div className="mb-4 flex" key={aspect.localizedAspectName}>

                <div className="w-12 flex-shrink-0"><input disabled={self.sizeSpecChoiceDisabled} type="radio" name="size-specific" onChange={e => { if (e.target.checked) self.setSizeSpecific(aspect.localizedAspectName)}} checked={self.sizeSpecificName === aspect.localizedAspectName}/></div>
                <div className="w-12 flex-shrink-0"><input disabled={self.colorSpecChoiceDisabled} type="radio" name="color-specific" onChange={e => { if (e.target.checked) self.setColorSpecific(aspect.localizedAspectName)}} checked={self.colorSpecificName === aspect.localizedAspectName}/></div>
                <div className="small">
                  <label className="label">{aspect.localizedAspectName} {aspect.aspectConstraint?.aspectRequired ? `(${aspect.aspectConstraint.expectedRequiredByDate})` : 'Not Required'}</label>
                  <p className="muted">
                    {/* {aspect.HelpText} */}
                  </p>
                </div>
              </div>
            )
          }
        </div>
      }
    </div>

    <ButtonBar>
      <Button text="Cancel" color="secondary" outline disabled={self.saving} onClick={props.cancel} />
      <Button icon="plus" text="Save" onClick={self.save} spinner={self.saving} />
    </ButtonBar>
  </div>
})


export const EbayCategoryEditorForm = observer(function EbayCategoryEditor(props: EbayCategoryEditorProps) {
  
  const store = useStore();
  const routing = useRouting();

  const [ loadingCat, setLoadingCat ] = useState<boolean>(false);
  const [ loadingSpec, setLoadingSpec ] = useState<boolean>(false);
  const [ spec, setSpec ] = useState<EbayTaxonomyItemAspectsByCategory|null>(null);
  const [ error, setError ] = useErrorState();
  const [ cat, setCat ] = useState<EbayCategory|null>(null);
  
  const [ saving, setSaving ] = useState<boolean>(false);

  const uid = store.user.user && store.user.user.uid;
  
  const catId = parseInt(props.categoryId || '')

  React.useEffect(() => {
    
    const levelLimit = parseInt(props.catLevel || '');

    if (!levelLimit || !catId) {
      setError('Oops! there is something wrong with this URL.')
      store.error.captureEvent({message: 'EbayCategoryEditor no level or id in props.', extra:{props}})
      return;
    }
    
    setLoadingCat(true);
    setLoadingSpec(true);
    setError(null);
    setCat(null);

    store.ebay.listCategories({levelLimit, categoryParent: catId})
    .then(r => {
      setCat(r[0] || null);
      setLoadingCat(false);
    })
    .catch(e => {
      setLoadingCat(false);
      setError(e)
    });

    store.ebay.getSpecifics({categoryId: catId})
    .then(spec => {

      setLoadingSpec(false);

      if (isEbayTaxonomyErrorResponse(spec)) {
        console.log('Errors getting specifics', spec.errors);
        setError(spec.errors.map(e => e.message).join('\n'))
      } else {
        console.log('getting item aspects', spec)
        spec.aspects = spec.aspects.filter(a => a.aspectConstraint?.aspectRequired)
        // spec.Recommendations.NameRecommendation = 
        //   spec.Recommendations.NameRecommendation
        //     .filter(spec => spec.ValidationRules.UsageConstraint === 'Required');
  
        setSpec(spec)
      }
    });
  }, [catId]);

  const loadingPrevious = store.ebay.registeredCategories === undefined;
  const previous = store.ebay.registeredCategories?.find(cat => cat.categoryId == catId);

  const saveRegisteredCategory = React.useCallback((cat: RegisteredEbayCategory, uid: string) => {
    if (cat && uid) {
      setSaving(true)
      return store.ebay.registerCategory(uid, cat)
      .then(()=>{
        setSaving(false)
        store.alert.add({type: 'success', message: 'Category registered successfully.'})
        routing.ebayRegisteredCategories.navigate()
      })
      .catch(e=>{
        setSaving(false);
        store.alert.error([e])
        store.error.captureException(e, {extra: {cat}})
      })
    }
    
  }, [cat && cat.CategoryID, uid]);
  
  if (!uid) return <Login/>;
  
  return <div className="min-h-16">
    {
      (loadingCat || loadingSpec || loadingPrevious === undefined) && <SpinnerOverlay/>
    }
    {
      error && <div className="text-red-500 small">{errorToString(error)}</div>
    }
    {
      cat && spec && uid && <div className="max-w-sm m-auto">
        <CatForm
          userId={uid}
          category={cat}
          specifics={spec}
          previous={previous}
          save={cat => saveRegisteredCategory(cat, uid)}
          cancel={() => routing.ebayRegisteredCategories.navigate()}
        />
      </div>
    }
  </div>
})