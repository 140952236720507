// This file contains awesome functional functions for functional programming. And also normal utility functions.

/**
 * An alternative to catching JSON.parse errors.
 * @param s string might contain json.
 * @param def default value to return if s fails to parse
 */
export const tryJson = <T>(s:string, def: T):T => {
  try {
    return JSON.parse(s)
  } catch (e) {
    return def
  }
}
/**
 * Convert a callback to a promise
 */
export const promisify = <V>(cb:(cb2: (e:any, v:V)=>void)=>void)=>{
  
  const p = new Promise<V>((res, rej)=>{
    cb((e,v)=>e ? rej(e) : res(v))
  });
  
  return p;
}

export const keys = <T extends {}>(o:T):(keyof T)[]=>Object.keys(o) as any;
export const values = <V, T extends number|string|symbol>(o: Record<T,V>): V[] =>
  keys(o).map(k=>o[k]);

export function first<T>(arr:T[]):T|undefined
export function first<T, U>(arr:T[], def: U):T|U
export function first<T>(arr:T[], def: any = undefined) { return arr.length > 0 ? arr[0] : def };

export function last<T>(arr:T[]):T|undefined
export function last<T, U>(arr:T[], def: U):T|U
export function last<T>(arr:T[], def: any = undefined) { return arr.length > 0 ? arr[arr.length - 1] : def };


// function filterUndefined<T extends {}>(obj: T) {
//   const o = {...obj};
//   keys(o).forEach(k => {
//     if (o[k] === undefined) delete o[k];
//   });
//   return o;
// }

export type ReturnType<T> = T extends (...args: any[]) => infer R ? R : unknown;
export type UnboxPromise<T> = T extends Promise<infer R>  ? R : unknown

export function parseQuery(q: string) {
  if (q.trim()=='') return {};
  
  const items = q.replace(/^\?/,'').split('&').map(x=>x.split('='));
  const params: Record<string, string> = {};
  items.forEach(([name, val])=>params[name] = decodeURIComponent(val))
  return params;
}
export function makeQuery(q: Record<string, string>, prepend = '?'): string {
  const s = keys(q).map(k=>`${k}=${encodeURIComponent(q[k])}`).join('&');
  return s==`` ? s : `${prepend}${s}`
}
export function delay(f: ()=>any, ms: number, _setTimeout = setTimeout, _clearTimeout = clearTimeout) {
  let enabled = true;
  _setTimeout(()=>enabled && f(), ms);
  return ()=>enabled = false;
}
export function memthrottle<T>(fn: ()=>T, ms: number, _setTimeout = setTimeout):(()=>T) {
  let val:{v:T}|null = null;
  return function () {
    if (!val) {
      _setTimeout(() => val = null, ms);
      const v = fn()
      val = {v};
      return v;
    } else {
      return val.v
    }
  }
}
// @ts-ignore
const areSetsEqual = (a: Set<any>, b: Set<any>) => a.size === b.size && [...a].every(value => b.has(value));
export function sameItems<T>(a: T[], b: T[]) {
  const sa = new Set(a);
  const sb = new Set(b);
  return areSetsEqual(sa, sb)
}
type Obj = {[k in (string|number)]: unknown};
export function onlyHasKeys<C extends Obj, P extends Obj>(c: C, p: P) {
  return keys(c).every(ck=>keys(p).every(pk=>pk as (keyof C)===ck))
}

export const isDate = (o: unknown): o is Date => o instanceof Date
export const isBoolean = (o: unknown): o is boolean => typeof o === 'boolean'
export const isNumber = (o: unknown): o is number => typeof o === 'number' && !isNaN(o);
export const isString = (o: unknown): o is string => typeof o === 'string';
export const hasString = <K extends (string|number)>(o: unknown, k:K, err = false): o is {[k in K]: string}=>{
  
  if (!isObject(o)) throw new ValidationMapError(`not an object`);
  if (!hasChild(k, isUnknown)(o)) throw new ValidationMapError(`does not have a child ` + k);
  const p = o[k];
  if (typeof p !== 'string') throw new ValidationMapError(`is not a string`);
  const q: string = p;
  return true;
}

export const isUnknown: Pred<unknown> = (o: unknown): o is unknown => true;
export const isNull = (o: unknown): o is null => o === null;
export const hasNumber = <K extends (string|number)>(o: any, k:K): o is {[k in K]: number}=>{
  const p = o && o[k];
  if (!p || typeof p !== 'number') return false;
  const q: number = p;
  return true;
}

export const isInt = (o: unknown): o is number => isNumber(o) && (Math.floor(o) === o)

export const isLiteral = <K extends string | number | symbol>(rec: Record<K, any>) => (o: unknown): o is K => keys(rec).some(k => k === o)

export const validateNonEmptyString = (o: unknown): o is string => {
  if (isString(o) && !!o) return true;
  throw new ValidationMapError('is required but blank')
}
export const validateNumber = (o: unknown): o is number => {
  if (typeof o === 'number' && isNaN(o)) throw new ValidationMapError('has illegal value (NaN)');
  if (isNumber(o)) return true;
  if (typeof o !== 'number') throw new ValidationMapError(`has type "${typeof o}" instead of "number"`);
  throw new ValidationMapError(`is invalid`);
}
  

export const hasBoolean = <K extends (string|number)>(o: any, k:K): o is {[k in K]: boolean}=>{
  const p = o && o[k];
  if (typeof p !== 'boolean') return false;
  const q: {[k in K]: boolean} = o;
  return true;
}
export type Pred<A extends B, B = unknown> = (b: B) => b is A
export const hasChild = <T, K extends string|number|symbol>(k:K, pred: Pred<T>) => (o: unknown): o is {[k in K]: T} => {
  if (typeof o !== 'object') return false;
  if (!o) return false;
  if (!(k in o)) return false;
  if (!pred((o as {[k in K]: T})[k])) return false;
  return true;
}
export const hasOptionalChild = <T, K extends string|number|symbol>(k:K, pred: Pred<T>) => (o: unknown): o is {[k in K]?: T} => {
  if (hasChild(k, pred)(o)) return true;
  if (!isObject(o)) return false;
  if (!(k in o)) return true;
  return typeof (o as {[k in K]?: unknown})[k] === 'undefined';
}
export const isArray = (o: unknown): o is Array<unknown> =>
  Object.prototype.toString.call(o) === "[object Array]";
export const isArrayOf = <T>(p: Pred<T>, minLen?: number) => (o: unknown): o is Array<T> =>
  isArray(o) && o.every(p) && (typeof minLen=='undefined' || o.length >= minLen);

export const tryPred = <T>(pred: Pred<T>) => (v:unknown): ValidationMapError|null => {
  try {
    return pred(v) ? null : new ValidationMapError(`is not valid`)
  } catch (error) {
    if (isValidationMapError(error)) return error;
    else throw error;
  }
}
export const validateArrayOf = <T>(p: Pred<T>, minLen?: number) => (o: unknown): o is Array<T> => {
  if (!isArray(o)) throw new ValidationMapError(`is not array`);
  const errors = o.map(tryPred<T>(p)).filter(notVoid);
  if (errors.length > 0) {
    throw new ValidationMapError({errors})
  }
  if (!o.every(p)) throw new ValidationMapError(`not all items are valid`);
  if (typeof minLen == 'number' && o.length < minLen) throw new ValidationMapError(
    minLen > 1 
      ? `must contain at least ${minLen} elements`
      : `must contain at least 1 element`
  );
  const q:T[] = o;
  return true;
}
export const isMapOf = <T, K extends string|number|symbol>(p: Pred<T>, pk: Pred<K>) => 
  (o: unknown): o is {[k in K]?: T} => 
    isObject(o) && !keys(o).some(k => !p(o[k]))
export const notUndefined = <T>(o: T|undefined): o is T => {
  return o !== undefined;
}
export const notVoid = <T>(o: T|undefined|null): o is T => {
  return o!==undefined && o!=null
}
export const notNull = <T>(o: T|null): o is T => {
  return o !== null;
}


export function filterUndefined<T>(o: T, maxLevel = 20): T {
  if (!o) return o;
  if (maxLevel < 1) return o;
  // @ts-ignore
  if (isArray(o)) return o.map(v => filterUndefined(v, maxLevel - 1));
  if (isObject(o)) return mapObject(o, v => filterUndefined(v, maxLevel - 1));
  return o;
}


/**
 * A hack to get a list of types. They don't seem to be defined in the d.ts files.
 * The return types ensures we have listed all of the types in the @see {TypeOfTypeMap}
 */
function getTypeOf(x: unknown): keyof TypeOfTypeMap { return typeof x };
export type TypeOfType = ReturnType<typeof getTypeOf>; 
export type TypeOfTypeMap = {
  string: string 
  number: number 
  bigint: bigint 
  boolean: boolean 
  symbol: symbol 
  undefined: undefined 
  object: object 
  function: Function 
}

export function isTypeOf(type: "string"): Pred<string>
export function isTypeOf(type: "number"): Pred<number>
export function isTypeOf(type: "bigint"): Pred<bigint>
export function isTypeOf(type: "boolean"): Pred<boolean>
export function isTypeOf(type: "symbol"): Pred<symbol>
export function isTypeOf(type: "undefined"): Pred<undefined>
export function isTypeOf(type: "object"): Pred<object>
export function isTypeOf(type: "function"): Pred<Function>
export function isTypeOf(type: string): (o: unknown) => boolean {
  return (o: unknown) => typeof o === type
}


export const isObject = (o: unknown): o is object => {
  if (typeof o !== 'object' || !o) return false;
  const x: object = o;
  return true;
}
export function hasKey<K extends string|number|symbol>(o: unknown, k: K): o is {[p in K]: unknown} {
  if (!isObject(o)) return false;
  return k in o;
}
export function orUndefined<T>(pred: Pred<T>): Pred<T|undefined> {
  return (x: unknown): x is T|undefined => {
    if (!pred(x) && x!==undefined) return false;
    return true;
  }
}
type Unpred<T extends Pred<any>[]> = T extends Pred<infer R>[] ? R : T
// export function isEither<A, B>(isA: Pred<A>, isB: Pred<B>): Pred<A|B> {
export function isEither<T extends Pred<any>[]>(...preds: T): Pred<Unpred<T>> {
  return (v:unknown): v is Unpred<T> => preds.some(p=>p(v));
  // return (v:unknown): v is (A|B) => isA(v) || isB(v);
}
export function isAlfaNum(v: unknown): v is (string|number) {
  if (!isEither(isString, isNumber)(v)) return false;
  const x: number|string = v;
  return true;
}
export function hasAll<O, T>(o: unknown, keys_: (keyof O)[], pred: Pred<T>): o is {[k in keyof T]: T} {
  if (!isObject(o)) return false;
  return keys_.every(k => hasChild(k, pred)(o))
}
export function hasAllOf<O extends {[k in string|number|symbol]: T}, T>(sample: O, isT: Pred<T>) {
  return (o: unknown): o is O => {
    if (!isObject(o)) return false;
    return hasAll(o, keys(sample), isT);
  }
}
export function formatDollars(n: number) {
  return n.toFixed(2);
}

// export function memoize<A, R>(fn: (a:A)=>R): (a: A)=>R
// export function memoize<A, B, R>(fn: (a:A, b:B)=>R): (a: A, b:B)=>R
// export function memoize<A, B, C, R>(fn: (a:A, b:B, c:C)=>R): (a: A, b:B, c:C)=>R
export function memoize<R, Args extends any[]>(fn: (...a:Args) => R) {
  
  const sameEls = (xs:unknown[], ys: unknown[]) => xs.length === ys.length && xs.every((x, i) => ys[i] === x)
  
  const cache: {a: Args, r: R}[] = []

  return function(...as: Args) {
    const c = cache.find(x => sameEls(x.a, as));
    if (c) return c.r;
    else {
      const c = {a: as, r: fn(...as)};
      cache.push(c);
      return c.r;
    }
  }
}

export const foldl = <T, U>(reducer: (accumulator: U, value: T) => U, arr: T[], start: U) =>
  arr.reduce<U>(reducer, start)

export function debounce<A>(fn: (a: A) => void, ms: number) {
  let t: any = null;
  let queue: {a:A} | null = null
  const exec = ()=>{
    t = null;
    if (queue) {
      t = setTimeout(()=>{
        exec();
      }, ms);
      fn(queue.a);
    }
    queue = null;
  }
  return function(a: A) {
    queue = {a};
    if (t == null) {
      exec();
    };
  }
}
/**
 * Return a new array with elements that are unique
 * @param arr 
 * @param eq optional parameter to determine equality
 */
export function uniq<T>(arr: T[], eq: (a:T, b:T) => boolean = (a:T, b:T) => a === b): T[] {
  return arr.reduce<T[]>( (acc, val) => acc.some(x => eq(x, val)) ? acc : [...acc, val], [])
}

export function slugify(s: string) {
  return s.replace(/ /g, '-').toLocaleLowerCase()
}

export function commaWithAnd(items: string[]) {
  if (items.length < 3) return items.join(' and ')
  const [..._items] = items;
  _items.reverse();
  const [last, ...init] = _items;
  init.reverse();
  
  return init.join(', ') + ' and ' + last
}
/**
 * returns a human-readable file size representation.
 * @param n byte
 */
export function humanBytes(n: number) {
  if (n < 1000) return `${n} bytes`;
  if (n < 1000000) return `${Math.round(n/1000)} Kb`;
  if (n < 1000000000) return `${Math.round(n/1000000)} Mb`;
  if (n < 1000000000000) return `${Math.round(n/1000000000)} Gb`;
  if (n < 1000000000000000) return `${Math.round(n/1000000000)} Tb`;
  if (n < 1000000000000000000) return `${Math.round(n/1000000000)} Petabytes`;
}
/**
 * Capitalizes every word in a string
 * @param s words separated by a space
 */
export function capitalize(s: string) {
  return s
    .split(' ')
    .map(w => w.split(''))
    .map(([h, ...t]) => (h||'').toUpperCase() + t.join(''))
    .join(' ');
}

export function flatten<T>(arr: T[][]): T[] {
  return arr.reduce((a,v)=>[...a, ...v], [])
}

export function groupBy<T, G = string>(arr:T[], fn: (val: T) => G, eq: (a: G, b: G) => boolean = (a,b) => a === b) {
  type Group = {id: G, items: T[]}
  
  return arr.reduce<Group[]>((acc, v) => {
    const id = fn(v);
    const group = acc.find(v => eq(v.id, id)) || acc[acc.push({id: id, items: []}) - 1];
    group.items.push(v);
    return acc;
  },[])
}

type Z<T> = T extends {} ? 1 : 2;
let x: Z<number[]>

export type ValidationErrorMap<T> = {
  [k in keyof T]-?: undefined extends T[k] ? [Pred<T[k]>] : Pred<T[k]>
}

const isPredOption = <T>(o: Pred<T> | [Pred<T>]): o is [Pred<T>] => {
  return isArray(o);
}
const isNotPredOption = <T>(o: Pred<T> | [Pred<T>]): o is Pred<T> => {
  return !isArray(o);
}



type ValidationMapItem = [string[], string]
export class ValidationMapError { // NB: we cannot extend the error class unfortunately because at this time (ES2018, 2020) the Error class cannot be extended. This did not work: https://stackoverflow.com/questions/41102060/typescript-extending-error-class
  kind = 'ValidationMapError'
  items: ValidationMapItem[]
  constructor(init: string)
  constructor(init: {errors: ValidationMapError[]})
  constructor(init: ValidationMapItem[])
  constructor(init: {errors: ValidationMapError[]}|ValidationMapItem[]|string) {
    this.items = uniq(
      isString(init) 
      ? [[[], init]] 
      : isArray(init)
      ? init
      : flatten(init.errors.map(x=>x.items))
      
      , (a,b) => this.text(a) === this.text(b)
    );
  }
  addKey = (key: string) => {
    this.items = this.items.map( ([ks, m]) => [[key, ...ks], m] );
    return this
  }
  private text = (item: ValidationMapItem, labels: Record<string, string> = {}) => 
    this.label(item[0], labels) + ' ' + item[1];

  private label = (keys: string[], labels: Record<string, string> = {}) => {
    return (labels || {})[keys.join('.')] || keys.join('.')
  }
  messages = (labels?: Record<string, string>) => this.items.map(x => this.text(x, labels))

  static is = (o: unknown): o is ValidationMapError => isValidationMapError(o)
}


export const isValidationMapError = (o: unknown): o is ValidationMapError => 
  hasChild('kind', isString)(o) && o.kind === 'ValidationMapError';

/**
 * 
 * @param map 
 * @param defaults MUTATES!! the object with provided defaults if values are not present. Useful to enforce schema changes.
 * @throws {Error}
 */
export const isValid = <T>(map: ValidationErrorMap<T>, defaults?: Partial<T>) => (o: unknown): o is T => {
  
  if (!isObject(o)) throw new ValidationMapError('not an object');
  
  if (defaults) {
    mapObject(defaults, (v, k) => {
      // @ts-ignore
      if (!hasKey(o, k)) o[k] = v 
    })
  }
  const errors = keys(map).map(key => {
    const p = map[key];
    try {
      if (isPredOption(p)) {
        if (!hasOptionalChild(key, p[0])(o)) throw new ValidationMapError('is not valid')
      } else if (isNotPredOption(p)) {
        if (!hasKey(o, key)) throw new ValidationMapError('is required but missing');
        if (!hasChild(key, p)(o)) throw new ValidationMapError('is not valid');
      }
    } catch (e: unknown) {
      if (isValidationMapError(e)) return e.addKey(String(key))
      else throw e;
    }
  }).filter(notVoid);

  if (errors.length == 0) return true;

  throw new ValidationMapError({errors});
}

export function guardOnly<T extends B, B = unknown>(p: Pred<T, B>): Pred<T, B> {
  const g = (o: B): o is T => {
    try {
      return p(o)
    } catch (error) {
      if (isValidationMapError(error)) return false;
      else throw error;
    }
  }

  return g;
}

export function validateWith<T>(p: Pred<T>, msg: string): Pred<T> {
  return (o: unknown): o is T => {
    if (p(o)) return true;
    else throw new ValidationMapError(msg)
  }
}
  



export const find = <T>(o: Record<any, T>, pred: (v: T)=>boolean): T | undefined => {
  const k = keys(o).find(k => pred(o[k]));
  return k && o[k]
}
export const clone = <T>(o:T):T => {
  if (!o) return o;
  if (isArray(o)) {
    // @ts-ignore
    return [...o].map(clone);
  }
  if (!isObject(o)) return o;
  
  return mapObject(o, clone)
  // const n = {...o};
  // keys(n).forEach(key => n[key] = clone(n[key]));
  // return n;
}
export const deepEqual = <T>(a:T, b:T): boolean =>{
  if (!isObject(a)) return a === b;
  if (isArray(a) && isArray(b) && a.length !== b.length) return false;
  
  return keys(a).some(ak=>deepEqual(a[ak], b[ak]))
      && keys(b).some(bk=>deepEqual(a[bk], b[bk]))
  
}
export const deepFind = (o: unknown, pred: (val: unknown) => boolean): {value: unknown}|null => {
  if (pred(o)) return {value: o};
  if (isArray(o)) return o.reduce<{value: unknown}|null>((acc, v) => acc || deepFind(v, pred), null);
  if (isObject(o)) return keys(o).reduce<{value: unknown}|null>((acc, v) => acc || deepFind(o[v], pred), null)
  return null;
}
export const withValue = <T, R>(t: T, fn: (t: T)=>R): R => fn(t);
/**
 * Generates a random value between min and max parameters
 * @param min 
 * @param max 
 * @param int output integer value. Defaults to `true`.
 */
export const rnd = (min: number, max: number, int = true)=>
  Math.floor(Math.random() * (max - min) + min)
export const randomEl = <T>(o: Array<T>)=>
  o[rnd(0, o.length, true)]

export const getExtension = (s: string)=>
  s.replace(/.*\.(\w+)$/, '$1');

export const sum = (acc: number, val: number) => acc + val;

export const twoDecimals = (n: number) => Math.round(n * 100) / 100;
export const formatDecimal = (n: number, decimalPrecision: number) => 
  [Math.floor(n) || '0', (n - Math.floor(n)) ? (n - Math.floor(n)).toPrecision(2) : null].filter(isString).join('.') 

export  const mapObject = <I extends {}, O extends {[v in keyof I]: any}>(i: I, fn: <K extends keyof I>(v: I[K], k: K)=>O[K]): O => {
  return keys(i).reduce((acc, val)=>({...acc, [val]: fn(i[val], val)}), {} as O)
}
export const omit = <T extends {}, K extends keyof T>(_o:T, ...ks: K[]): Omit<T, K> =>{
  const o = clone(_o)
  ks.forEach(k=>delete o[k])
  return o;
}
export const pick = <T extends {}, K extends keyof T>(_o:T, ...ks: K[]): Omit<T, K> =>{
  const o = clone(_o);

  keys(o).forEach(k=>ks.find(kk=>k==kk) || delete o[k])
  return o;
}

export const isError = (v: unknown): v is Error => !!v && isObject(v) && hasChild('message', isString)(v) && hasOptionalChild('stack', isString)(v);
/**
 * A safe function to turn an error instance into a plain object. Meant for reporting purposes.
 * @param e 
 */
export const errorToObject = (e:unknown)=>{
  try {
    if (e instanceof Error) {
      const o:any = {
        /// @ts-ignore
        code: e.code,
        stack: e.stack,
        message: e.message,
        name: e.name,
        isErrorInstance: true
      };
      keys(e).forEach(k=>o[k] = e[k]);
      return o
    } else if (isObject(e)) {
      const o: any = {};
      keys(e).forEach(k=>o[k] = e[k]);
      return o;
    } else {
      return e
    };
  } catch (_) {
    return e;
  }
}

export const errorToString = (e:unknown): string => {
  if (typeof e === 'string') return e;
  if (hasChild('friendly', isString)(e)) return e.friendly;
  if (hasChild('details', isString)(e)) return e.details;
  if (hasChild('details', isArrayOf(isString))(e)) return e.details.join('\n');
  if (hasChild('message', isString)(e)) return e.message;
  if (e instanceof Error) return e.message;
  try {
    return (e as any).toString()
  } catch (_) {
    return `${e}`
  }
}
/**
 * Generate array of given size
 * @param size size of the result array
 */
export const array = (size: number) => (new Array(size)).fill(1);

export const alphabet = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"] as const;
export const digits = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

export function randomId(o: {prefix?: string, length?: number} = {}) {
  
  const chars = [...alphabet, ...digits.map(d => d.toString())];
  
  return (o.prefix || '') + array(o.length || 11)
    .map(()=>chars[rnd(0, chars.length - 1, true)])
    .join('')
    ;
}

const trace = (item: any, label = 'trace')=>{console.log(label, item); return item;}


export type WithOptional<T, Keys extends keyof T> = {
  [k in keyof T]: k extends Keys ? T[k] | undefined : T[k]
}

export type TypeKeys<T, N> = {
  [P in keyof T]: T[P] extends N ? P : never
}[keyof T];

export type NotTypeKeys<T, N> = {
  [P in keyof T]: T[P] extends N ? never : P
}[keyof T];

/**
 * A mega-clever way of mapping some types into optional and some into non-optional
 */
export type PartialExcept<T, Not> =
{
  [ P in keyof Omit<T, TypeKeys<T, Not>> ]?: Omit<T, TypeKeys<T, Not>>[P]
}
&
{
  [ P in keyof Omit<T, NotTypeKeys<T, Not>> ]: PartialExcept<Omit<T, NotTypeKeys<T, Not>>[P], Not>
}

type ObjectKeys<T> = {
  [P in keyof T]: T[P] extends Array<any> ? never : T[P] extends object ? P : never
}[keyof T];

type ArrayKeys<T> = {
  [P in keyof T]: T[P] extends Array<any> ? P : never
}[keyof T];

type SimpleKeys<T> = Exclude<keyof T, ArrayKeys<T> | ObjectKeys<T>>

type ArrayModel<T> = T extends (infer R)[] ? ArrayModel<R>[] : T extends object ? Model<T> : T

/**
 * If a child is an object, map to required. If a child is an array, map to optional.
 */
export type Model<T> = 
{
  [ P in SimpleKeys<T> ]?: T[P]
} & {
  [ P in ArrayKeys<T> ]-?: ArrayModel<T[P]>
} & {
  [ P in ObjectKeys<T> ]-?: Model<T[P]>
}

export type Assign<T, R> = R & Omit<T, keyof R>

export const getFilename = (path: string) => {
  const parts = path.replace(/\/$/, '').split('/');
  return parts[parts.length - 1];
}
export const getExt = (path: string) => {
  if (path[0]=='.') return null;
  if (path[path.length - 1] == '.') return null;
  const parts = path.split('.')
  return parts.length > 1 ? parts.pop() : null;
}
export const basename = (path: string) => {
  const filename = getFilename(path);
  const ext = getExt(filename);
  return ext ? filename.replace(new RegExp(`\\.${ext}$`), '') : filename;
}

export function intersection<T>(a: T[], b: T[]): T[] {
  const s = new Set(b);
  return [...new Set(a.filter(v=>s.has(v)))]
}

/**
 * Insert a new element or update the previous one *IN-PLACE* inside the given array.
 * @param arr array into which the value is upserted
 * @param getId function that extracts the identifier by which to compare the values
 * @param id identifier used to compare the values
 * @param val value to upsert
 * @param gt an optional sorter function to insert items into an ascending order. Insertion index will be after the first i where gt(arr[i], val).
 */
export function upsert<T, U = string>(arr: T[], getId: (t:T)=>U, id: U, val: T|null, gt?: (a: T, b: T) => boolean) {
  
  const prevIndex = arr.findIndex(item => getId(item) === id);
  if (prevIndex > -1) {
    if (val) arr[prevIndex] = val;
    else arr.splice(prevIndex, 1);
  } else if (val) {
    if (gt) {
      const index = arr.findIndex(prev => gt(prev, val));
      if (index === -1) arr.push(val);
      else arr.splice(index, 0, val);
    } else {
      arr.push(val);
    }
  }
  // return arr;
}


/**
 * Return a correctly-typed tuple with the arguments.
 * @param args any number of arguments to appear in the tuple in the same order
 * @example tuple(1, 'a') // results in: [1, 'a']
 */
export function tuple<T extends any[]>(...args: T):T { return args }

export function cartesian<T,U>(å: T[], ø: U[]): [T, U][] {
  return flatten(å.map( åß => ø.map( øß => tuple(åß, øß))))
}

export const delayPromise = (ms: number, _setTimeout = setTimeout)=>
  new Promise(res=>_setTimeout(res, ms))

/**
 * Retries an operation a specified number of times
 * @param fn function that returns a promise to be possibly retried
 * @param attemptSeconds array of seconds to wait between corresponding retries
 */
export const retryPromise = <T>(fn: (count: number) => Promise<T>, attemptSeconds: number[], opt?: {
  setTimeout?: typeof setTimeout,
  bailOn?: (e: unknown) => boolean,
  trace?: (e: unknown) => any
}): Promise<T> =>
  fn(attemptSeconds.length)
    .catch(e => attemptSeconds.length > 0 && !(opt?.bailOn && opt.bailOn(e)) && ((opt?.trace ? opt.trace(e) || true : true))
      ? delayPromise(attemptSeconds[0] * 1000, opt?.setTimeout).then(() => retryPromise(fn, attemptSeconds.slice(1)))
      : Promise.reject(e)
      );

export const containBox = (bounds: {w: number, h: number}, obj: {w: number, h: number}) => {
  const oRatio = obj.w / obj.h;
  const w = Math.min(bounds.w, bounds.h * oRatio);
  const h = w / oRatio;
  return {w, h}
}

export const itself = <T>(item: T) => item;

export const isTuple = <A, B>(isa: Pred<A>, isb: Pred<B>) => (x: unknown): x is [A, B] => 
  isArray(x) ? x.length == 2 && isa(x[0]) && isb(x[1]) : false;



export type CompletedSuccess<T> = {result: T, kind: 'success'};
export type CompletedFailure<E = any> = {error: E, kind: 'error'}
/**
 * Waits for all promises to succeed or fail, delivering either result in combination to extra data optionally passed with every promise.
 * @param ps {[Promise<T>, U?][]} an array of tuples (Promise, extra data). The extra data will be injected into the resulting success or error object.
 */
export function completeAll<T>(ps: Promise<T>[]): Promise<(CompletedFailure | CompletedSuccess<T>)[]>
export function completeAll<T, U extends {}>(ps: [Promise<T>, U?][]): Promise<((CompletedFailure | CompletedSuccess<T>) & U)[]>
export function completeAll<T, U extends {} = {}>(ps: [Promise<T>, U?][] | Promise<T>[]): Promise<((CompletedFailure | CompletedSuccess<T>) & U)[]> { 
  
  const isTupled = (x: [Promise<T>, U?][] | Promise<T>[]): x is [Promise<T>, U?][] => 
    isArrayOf((el): el is [Promise<T>, U?] => isArray(el))(x);

  const complete = (p: Promise<T>, extra: {}) => p.then(result => ({result, kind: 'success' as const}))
    .catch(error => ({error, kind: 'error' as const}))
    .then(item => ({...(extra || {}), ...item}));
  
  if (isTupled(ps)) {
    return Promise.all(ps.map(([p, extra]) => complete(p, extra || {})))
  } else {
    return Promise.all(ps.map((p) => complete(p, {})))
  }

  // return Promise.all(
  //   // @ts-expect-error
  //   ps.map((v) => {
  //     const p: Promise<T> = isArray(v) ? v[0] : v;
  //     const extra: U = isArray(v) ? v[1] : {};
      

  //   } ) )
}