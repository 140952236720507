import { observer } from "mobx-react-lite";
import classNames = require("classnames");
import { ClassValue } from "classnames/types";
import { ProductCanvasModel } from "../store/ProductStore";
import React = require("react");
import { observable, action, computed } from "mobx";
import { useController } from "../util/mobxUtils";
import { randomId, keys, flatten } from "@root/shared/lib/x";
import { FloatInput } from "./kit/FloatInput";
import { ProductSizePrice, PositioningRule, ProductCanvas } from "@root/shared/lib/database";
import { AvailableSizesInput } from "./AvailableSizesInput";
import { Icon } from "./kit/icons";
import { Select } from "./kit/Select";
import { isProductCanvas } from "../../../shared/lib/schemas/productGuards";
import { CanvasPlacementInput, CanvasPlacementInputModel } from "./CanvasPlacementInput";
import { positiveFloat } from "@root/shared/lib/schemas/errorValidators";

const POSITIONING_RULES: Record<PositioningRule, string> = {
  'center': 'Center',
  'top': 'Align to Top – 100% width',
  'top-11.5': 'Align to Top - width 11.5”',
  'top-12': 'Align to Top - width 12”',
  'top-12.5': 'Align to Top - width 12.5”',
  'top-13': 'Align to Top - width 13”',
  'mirrored': 'Duplicate - Mug - 63mm from center'
};
const rulesOptions = keys(POSITIONING_RULES)
  // .filter(r => r!=='mirrored') // doesn't work for imgix
  .map(value=>({value, label: POSITIONING_RULES[value]}))

export type ProductCanvasInputProps = {
  className?: ClassValue
  value?: ProductCanvasModel[]
  onChange?: (value: ProductCanvasModel[])=>void
  sizes: ProductSizePrice[]
  previewImageUrl?: string|null
  dirty?: boolean
}

class ProductCanvasInputController {

  @observable props: ProductCanvasInputProps;


  constructor(props: ProductCanvasInputProps) {
    this.props = props;
  }

  @action setProps = (props: ProductCanvasInputProps) => {
    this.props = props;
  }
  
  @action addItem = () => {
    this.onChange([...this.items, {
      id: randomId(), 
      productSizeIds: [], 
      positioningRule: 'top'
    }]);
  }
  
  @computed get items() {
    return this.props.value || []
  }

  

  

  // @computed get positioningError() {
  //   const complete = !!this.props.value && this.props.value.every(x => x.positioning);
    
  //   return complete || !this.props.dirty
  //     ? null :
  //     `Please position all the canvases.`
  // }
  
  @computed get coverageError() {
    const coveredSizes = flatten(this.items.map(item => item.productSizeIds));
    const covered = this.props.sizes.every(size => coveredSizes.indexOf(size.id) > -1);
    return covered ? null : `Please select one canvas per size.`
  }

  disabledSizeIds = (item: ProductCanvasModel) => {
    return flatten(this.items.filter(x => x.id != item.id).map(x => x.productSizeIds))
  }
  

  onItemChange = (item: ProductCanvasModel) => {
    this.onChange(this.items.map(prev => prev.id == item.id ? item : prev))
  }

  @action removeCanvas = (item: ProductCanvasModel) => {
    this.onChange(this.items.filter(prev => prev.id != item.id))
  }

  private onChange = (items: ProductCanvasModel[]) => {
    this.props.onChange && this.props.onChange(items)
  }

  availableSizesError = (item: ProductCanvasModel) => {
    return item.productSizeIds.length == 0 
      ? `please select a size`
      : null
  }
  
}

export const ProductCanvasInput = observer(function ProductCanvasInput(props: ProductCanvasInputProps) {

  const self = useController(()=>new ProductCanvasInputController(props), props);
  return <div className={classNames("ProductCanvasInput", props.className)}>
    <table>
      <thead>
        <tr>
          <th>
            Canvas Size
          </th>
          <th className="label text-left">
            {
              props.sizes.map(s => <span key={s.id} className="w-6 text-center inline-block text-xs font-light">{s.size}</span>)
            }
          </th>
          <th>
            <div className="flex flex-no-wrap">
              <div>Positioning Rules</div>
              <button onClick={self.addItem} className="ml-4 focus:shadow-outline-blue focus:outline-none focus:border-blue-300"><Icon name="circlePlus" color="success" /></button>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {
          self.items.map(item => <tr key={item.id}>
            <td>
              <div className="flex items-center">
                <FloatInput
                  dirty={props.dirty}
                  value={item.width}
                  onChange={width => self.onItemChange({...item, width})}
                  errorFunc={positiveFloat}
                  unitsPostfix="W in"
                />
                <span className='mx-3'><Icon name="x" color="secondary"/></span>
                <FloatInput
                  dirty={props.dirty}
                  value={item.height}
                  onChange={height => self.onItemChange({...item, height})}
                  errorFunc={positiveFloat}
                  unitsPostfix="H in"
                />
              </div>
            </td>
            <td>
              <AvailableSizesInput
                error={self.availableSizesError(item)}
                value={item.productSizeIds}
                disabledSizeIds={self.disabledSizeIds(item)}
                // dirty={props.dirty}
                sizes={props.sizes}
                onChange={productSizeIds => self.onItemChange({...item, productSizeIds})}
              />
            </td>
            <td>
              <Select
                options={rulesOptions}
                value={item.positioningRule}
                onChange={positioningRule => positioningRule && self.onItemChange({...item, positioningRule})}
              />
            </td>
            <td>
              <Icon className="ml-3" name="trash" color="danger" onClick={() => self.removeCanvas(item)} />
            </td>
          </tr>)
        }
      </tbody>
    </table>
    {
      self.coverageError && props.dirty && <div className="error text-sm">
        {self.coverageError}
      </div>
    }

    
  </div>
})
