import classNames = require("classnames")
import { ClassValue } from "classnames/types"
import React = require("react")
import { colorClass, ColorType } from "./kitTypes"

type AsteriskProps = {
  className?: ClassValue
  color?: ColorType
}
export const Asterisk = (props: AsteriskProps) => <span className={classNames(
  "text-sm", 
  props.color ? colorClass(props.color) : "error", 
  props.className)}>*</span>