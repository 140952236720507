
import {FirewardOutput, Product, ProductCanvas, ProductColorOption, ProductDesign, ProductGroup, ProductListingSKUs, ProductSizePrice} from './database';
import { capitalize, cartesian, flatten, notVoid, randomId } from './x';

type ListingSKUResult = {
  product: Product<FirewardOutput>;
  size: ProductSizePrice<FirewardOutput>;
  color: ProductColorOption<FirewardOutput>;
  canvas: ProductCanvas<FirewardOutput>;
  previewCanvas: ProductCanvas<FirewardOutput>;
  design: ProductDesign<FirewardOutput>;
  skus: ProductListingSKUs<FirewardOutput>;
};

// Order Desk SKU [DESIGN-SKU]_[PRODUCT-CODE]_[COLOR-CODE]_[SIZE-CODE]
// Print SKU (shirts) [PRODUCT-CODE]_[COLOR-CODE]_[SIZE-CODE]
// Ebay SKU  [Design-Code]_[PRODUCT-CODE]_[Color-Code]_[Size-Code]

/**
 * Removes forbidden characters from string for SKU purposes.
 * @param str input string
 */
export const cleanupCode = (str: string) => str.replace(/[^a-zA-Z0-9\-_]/g, '');

export function generateProductSKUs(group: ProductGroup<FirewardOutput>, product: Product<FirewardOutput>, design: ProductDesign<FirewardOutput>): ListingSKUResult[] {
  const join = (...items: [string, ...string[]]) => items.join('_');
  const unwords = (...items: [string, ...string[]]) => items.join(' - ');
  
  const designCode = design.designCode;
  

  const groupColors = flatten( group.productColors
    .filter(c=>c.productId == product.productId)
    .map(g => g.colorIds.map(id => product.colors.find(c => c.id == id)).filter(notVoid))
  );

  switch (product.skuAlgorithm) {
    case 'shirts':

      const ebayCustomLabel = join(designCode, product.code)

      return cartesian(product.sizes, groupColors).map(([size, color]) => {
        const ebaySKU = join(ebayCustomLabel, size.size, color.code);
        return {
          product, size, color, design, 
          canvas: product.canvases.find(c => c.productSizeIds.indexOf(size.id) > -1) || product.canvases[0],
          previewCanvas: product.canvases.find(c => c.id == product.previewCanvasOptions.canvasId) || product.canvases[0],
          skus: {
            ebayCustomLabel,
            ebaySKU: ebaySKU,
            orderDeskSKU: ebaySKU,
            orderDeskName: unwords(design.designName, product.name, color.name, size.size),
            printSKU: join(product.code, color.code, size.size)
          }
        }
      });

    case 'manual':

      return product.manualSkus.map( (m,i) => {

        const size = product.sizes.find(s => s.id == m.productSizeId);
        const color = groupColors.find(c => c.id == m.productColorOptionId);

        const productMasterSKU = i.toString();
        
        if (!color || !size) return null;
        
        const ebayCustomLabel = join(designCode, productMasterSKU)
        
        const ebaySKU = join(designCode, m.sku).toUpperCase();
        
        return {
          product, size, color, design, 
          canvas: product.canvases.find(c => c.productSizeIds.indexOf(size.id) > -1) || product.canvases[0],
          previewCanvas: product.canvases.find(c => c.id == product.previewCanvasOptions.canvasId) || product.canvases[0],
          skus: {
            ebayCustomLabel,
            ebaySKU: ebaySKU,
            orderDeskSKU: ebaySKU,
            orderDeskName: unwords(design.designName, product.name, color.name, size.size),
            printSKU: m.sku
          }
        }

      }).filter(notVoid);

  }
}

type DesignSKUResult = {
  group: ProductGroup<FirewardOutput>;
  product: Product<FirewardOutput>;
  design: ProductDesign<FirewardOutput>;
};

export function generateSKUs(designs: DesignSKUResult[]) {
  return flatten(
    designs.map(({group, product, design}) => generateProductSKUs(group, product, design))
  )
}

/**
 * Converts a human-readable string to an SKU component by:
 *   - capitalizing
 *   - replacing words with first few characters for...
 *   - constraining the length
 * @param str a human-readable string
 */
export function skufyString(str: string, maxLen = 20) {
  const parts = str.trim()
    .replace(/[^a-zA-Z0-9\-\s]/g, '')
    .split(/\s+/).filter(x=>x!='')
  ;

  const map = (ps: string[], nx: string[], len: number): string[] => 
    (nx.length == 0 || len >= maxLen)
    ? nx
    :
    [  ((nx[0] || '') + (notVoid(ps[0][0]) ? ps[0][0] : '')), 
    ...map(ps.slice(1), nx.slice(1), len + (notVoid(ps[0][0]) ? 1 : 0))
    ]
    ;

  const pick = (ps: string[], next: string[] = ps.map(p => '')): string[] => 
    ps.every(p => !p) || next.filter(p=>!!p).join('-').length >= maxLen
    ? next
    : (pick(ps.map(p => p.slice(1)), map(ps, next, next.filter(p=>!!p).join('-').length || Math.min(Math.floor(maxLen/2), ps.length - 1))))
  ;


  return pick(parts)
    .filter(p => p != '')
    .join('_')
    .toLocaleUpperCase()
}