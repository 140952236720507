import { ClassValue } from "classnames/types"
import React = require("react")
import classNames = require("classnames")


export type CanvasImageProps = {
  className?: ClassValue
  checkSize?: number
  width: number|string
  height: number|string
  style?: React.CSSProperties
  // ref?: React.Ref<HTMLDivElement>
}

export const CanvasImage = React.forwardRef<HTMLDivElement, CanvasImageProps>(function CanvasImage({
  className, style = {}, checkSize = 10, 
  width, height,
  ...props}: CanvasImageProps, ref) {

  const grey = '#888888';
  const checkers: React.CSSProperties = {
    backgroundImage: `linear-gradient(45deg, ${grey} 25%, transparent 25%), linear-gradient(-45deg, ${grey} 25%, transparent 25%), linear-gradient(45deg, transparent 75%, ${grey} 75%), linear-gradient(-45deg, transparent 75%, ${grey} 75%)`,
    backgroundSize: `${checkSize*2}px ${checkSize*2}px`,
    backgroundPosition: `0 0, 0 ${checkSize}px, ${checkSize}px -${checkSize}px, -${checkSize}px 0px`,
    width: width,
    height: height,
  };

  

  return <div 
    ref={ref}
    className={classNames('CanvasImage opacity-50', className)} 
    style={{...style, ...checkers}}
    />
})
