import { observer } from "mobx-react-lite";
import React = require("react");
import { useStore } from "../store/StoreContext";
import { useRouting } from "../routing/routes";
import { TextInput } from "./kit/TextInput";
import { MainStore } from "../store/store";
import { action, computed, observable } from "mobx";
import { useController } from "../util/mobxUtils";
import { ButtonBar } from "./kit/ButtonBar";
import { Button } from "./kit/Button";
import { string } from "mobx-state-tree/dist/internal";
import { errorToString } from "@root/shared/lib/x";
import { SpinnerOverlay } from "./kit/SpinnerOverlay";
import { ClassValue } from "classnames/types";
import classNames = require("classnames/dedupe");

class LoginController {
  

  @observable login = {
    email: "", password: "", dirty: false
  }

  @observable signup = {
    email: "", password: "", confirm: "", dirty: false
  }


  @observable busy: boolean = false;

  store: MainStore
  constructor(store: MainStore) {
    this.store = store;
  }
  @computed get auth() {
    return this.store.user.state
  }

  @action.bound signInWithPwd(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.login.dirty = true;
    if (!this.login.email || !this.login.password) return;
    this.busy = true;
    this.store.user.loginWithPwd(this.login)
    .catch(action(e => {
      this.busy = false;
      this.store.alert.error([errorToString(e)], "Login Error")
    }))
  }
  @action.bound signUpWithPwd(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.signup.dirty = true;
    if (!this.signup.email || !this.signup.password) return;
    this.busy = true;
    this.store.user.signupWithPwd(this.signup)
    .catch(action(e => {
      this.busy = false;
      this.store.alert.error([errorToString(e)], "Signup Error")
    }))
  }
  @action.bound resetPwd() {
    if (!this.login.email) return;
    this.store.user.resetPwd(this.login.email)
    .then(action(()=>{
      this.store.alert.add({type: 'success', message: `Password reset email is sent to ${this.login.email}.`})
    }))
    .catch(e=>{
      this.busy = false;
      this.store.alert.error([errorToString(e)], "Signup Error")
    })
  }



}
export const Login = observer(function Login(props: {className?: ClassValue}) {

  const store = useStore();
  const self = useController(()=>new LoginController(store));
  const routing = useRouting();
  
  if (self.auth === undefined) return <SpinnerOverlay /> ;

  return <div className={classNames("my-2 max-w-sm", props.className)}>
    {/* <div id="LoginMount"/> */}
    <SpinnerOverlay show={self.auth===undefined}/>
    {
      !self.auth
      ? <>
        <form className="my-4 border-t" onSubmit={self.signInWithPwd} >
          <h3 className="text-lg font-light">Log in</h3>
          <TextInput autoFocus error={self.login.email ? null : `Required`} dirty={self.login.dirty} type="email" value={self.login.email || ''} label="Email" onChange={val => self.login.email = val || ''} />
          <TextInput error={self.login.password ? null : `Required`} dirty={self.login.dirty} type="password" value={self.login.password || ''} label="Password" onChange={val => self.login.password = val || ''} />
          <ButtonBar left>
            <Button type="submit" spinner={self.busy} text="Login" />
            <Button text="Reset" outline onClick={self.resetPwd} disabled={!self.login.email || self.busy} />
          </ButtonBar>
        </form>
        <form onSubmit={self.signUpWithPwd} >
          <h3 className="text-lg font-light">Sign up</h3>
          <TextInput error={self.signup.email ? null : `Required`} dirty={self.signup.dirty} type="email" value={self.signup.email || ''} label="Email" onChange={val => self.signup.email = val || ''} />
          <TextInput error={self.signup.password ? null : `Required`} dirty={self.signup.dirty} type="password" value={self.signup.password || ''} label="Password" onChange={val => self.signup.password = val || ''} />
          {/* <TextInput error={!self.signup.confirm ? `Required` : self.signup.confirm!=self.signup.password ? `Does not match password` : null} dirty={self.signup.dirty} type="password" value={self.signup.confirm || ''} label="Confirm Password" onChange={val => self.signup.confirm = val || ''} /> */}
          <ButtonBar left>
            <Button type="submit" spinner={self.busy} text="Sign up" />
          </ButtonBar>
        </form>
      </>
      : null
    }
      
    
  </div>
})
