import * as React from 'react';
// import {route} from 'navi';
import { LandingPage } from '../components/LandingPage';
import { LinkedAccountsPage } from '../components/LinkedAccountsPage';
// import { r, createRouting } from './typicalRoute';
import { EbayLinkFinishPage } from '../components/EbayLinkFinishPage';
import { EbayCategoryPicker } from '../components/EbayCategoryPicker';
import { EbayCategoryEditorForm } from '../components/EbayCategoryEditorForm';
import { EbayCategoryRegistrar } from '../components/EbayCategoryRegistrar';
import { EbayRegisteredCategories } from '../components/EbayRegisteredCategories';
import { ProductGroupsHome } from '../components/ProductGroupsHome';
import { ProductGroupEditor } from '../components/ProductGroupEditor';
import { ProductList } from '../components/ProductList';
import { ProductEditor } from '../components/ProductEditor';
import { ProductsPage } from '../components/ProductsPage';
import { PendingListingsPage } from '../components/PendingListingsPage';
import { StandardSettingsPage } from '../components/StandardSettingsPage';
import { Assign, keys, mapObject } from '@root/shared/lib/x';
import { Route, Routes, useNavigate, useParams, Link as RLink, LinkProps, useLocation, matchPath } from 'react-router-dom';
import { UserAccountPage } from '../components/UserAccountPage';
import { DesignQueuePage } from '../components/DesignQueuePage';


function route<T extends {}|null = null>(item: RouteItem<T>) {
  return item
}

function RenderRoute<Params extends {}|null = null>(props: {route: RouteItem<Params>}) {
  const params = useParams();
  
  return props.route.view(params as any)
}
type RouteItem<T extends {}|null> = {
  title: string
  path: string
  href: T extends null ? () => string : (obj: T) => string
  view: T extends null ? () => React.ReactElement : (obj: T) => React.ReactElement
}

type RouteParams<T extends RouteItem<any>> = T extends RouteItem<infer R> ? R : never

const routes = {
  home: route({
    title: '',
    path: '/',
    href: () => `/`,
    view: () => <LandingPage/>
  }),
  linkedAccounts: route({
    title: 'Linked Accounts',
    path: '/linked-accounts',
    href: () => `/linked-accounts`,
    view: () => <LinkedAccountsPage/>
  }),
  ebayConnection: route({
    title: 'Connecting to eBay',
    path: `/ebay-auth`,
    href: () => `/ebay-auth`,
    view: () => <EbayLinkFinishPage/>
  }),
  ebayRegisteredCategories: route({
    title: `Registered Ebay Categories`,
    path: `/ebay/categories`, 
    href: () => `/ebay/categories`,
    view: () => <EbayRegisteredCategories/>,
  }),

  ebayCategoryPicker: route({
    title: `Choose a category to begin`,
    path: `/ebay/categories/select`,
    href: () => `/ebay/categories/select`,
    view: () => <EbayCategoryRegistrar/>,
  }),
  ebayCategoryEditor: route<{categoryId: string|number, catLevel: string|number}>({
    title: 'Category Editor',
    path: `/ebay/categories/setup/:categoryId/:catLevel`, 
    href: (params) => `/ebay/categories/setup/${params.categoryId}/${params.catLevel}`,
    view: (params) => <EbayCategoryEditorForm categoryId={params.categoryId?.toString()} catLevel={params.catLevel?.toString()} />
  }),

  productGroups: route({
    title: 'Product Groups',
    path: `/groups`, 
    href: () => `/groups`,
    view: () => <ProductGroupsHome/>
  }),
  productGroupsCreator: route<{}>({
    title: 'Product Group Editor',
    path: `/groups/new`, 
    href: () => `/groups/new`,
    view: () => <ProductGroupEditor />
  }),
  productGroupsEditor: route<{groupId: string}>({
    title: 'Product Group Editor',
    path: `/groups/edit/:groupId`, 
    href: params => `/groups/edit/${params.groupId}`,
    view: params => <ProductGroupEditor productGroupId={params.groupId}/>
  }),
  products: route({
    title: 'Products',
    path: `/products`,
    href: () => `/products`,
    view: () => <ProductsPage/>
  }),
  productCreate: route({
    title: 'Product Editor',
    path: `/products/new`, 
    href: () => `/products/new`,
    view: () => <ProductEditor/>
  }),
  productEdit: route<{productId: string}>({
    title: 'Product Editor',
    path: `/products/edit/:productId`, 
    href: params => `/products/edit/${params.productId}`,
    view: params => <ProductEditor productId={params.productId}/>
  }),
  pendingListings: route({
    title: "Pending Listings",
    path: `/pending`,
    href: () => `/pending`,
    view: () => <PendingListingsPage/>
  }),
  standardSettings: route({
    path: `/standard-settings`,
    href: () => `/standard-settings`,
    title: "Standard Settings",
    view: () => <StandardSettingsPage/>
  }),
  userAccount: route({
    path: `/account`,
    href: () => `/account`,
    title: 'Account Info',
    view: () => <UserAccountPage/>
  }),
  designQueue: route({
    path: `/queue`,
    href: () => `/queue`,
    title: 'Upload Queue',
    view: () => <DesignQueuePage/>
  })
  
}

export const RouteSchema = <Routes>
  {
    keys(routes).map(name => <Route key={name} path={routes[name].path} element={<RenderRoute route={routes[name] as any} />}/>)
  }
</Routes>

type RouteMap  = typeof routes;

export const useRouting = (): {
  [K in keyof RouteMap]: {
    navigate: RouteParams<RouteMap[K]> extends null ? () => void : (params: RouteParams<RouteMap[K]>) => void
    href: RouteParams<RouteMap[K]> extends null ? () => string : (params: RouteParams<RouteMap[K]>) => string
  }
} => {
  const nav = useNavigate();
  function make<P extends {}|null>(r: RouteItem<P>) {
    return {
      navigate: (params: P) => nav(r.href(params as any)),
      href: (params: P) => r.href(params as any),
    }
  }
  return mapObject(routes, r => make(r as any) as any)
}

export function usePathInfo() {
  const location = useLocation();
  const r = keys(routes).map(name => ({name, r: routes[name]})).find(r => matchPath(r.r.path, location.pathname));
  return r ? {
    title: r?.r.title
  } : null
} 

export function Link({href, disabled, children, ...props}: Omit<Assign<LinkProps, {href: LinkProps['to'], disabled?: boolean}>, 'to'>) {
  return disabled ? <span {...props}>{children}</span> : <RLink {...props} to={href}>{children}</RLink>
}

