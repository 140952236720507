import { WardFieldValue, FirewardInput } from "./database";
import { hasChild, isString, Assign } from "./x";

export type NoFieldValues<T> = {
  [ k in keyof T]: WardFieldValue extends T[k] ? Exclude<T[k], WardFieldValue> : T[k] extends object ? NoFieldValues<T[k]> : T[k]
}

export function isFieldValue(o: unknown): o is WardFieldValue {
  return hasChild('lc', isString)(o)
}

export function noFieldValue<T>(t: WardFieldValue|T, def: T): T {
  return isFieldValue(t) ? def : t;
}