import React = require("react")
import { ForceUpdateHook, observer } from "mobx-react-lite"
import { useStore } from "../store/StoreContext";
import { Login } from "./Login";
import { ButtonBar } from "./kit/ButtonBar";
import { UserImage } from "./kit/UserImage";
import { Icon } from "./kit/icons";
import { Button } from "./kit/Button";
import { SpinnerOverlay } from "./kit/SpinnerOverlay";
import { LinkText } from "./kit/LinkText";
import { Link, useRouting } from "../routing/routes";
import classNames = require("classnames");
import { MainStore } from "../store/store";
import { useController } from "../util/mobxUtils";
import { action, observable, toJS } from "mobx";
import { FirewardOutput, FirewardInput, FirewardTypes, ProductColorOption, ProductGroup, ProductGroupColor } from "@root/shared/lib/database";
import { Spinner } from "./kit/Spinner";
import { errorToString } from "@root/shared/lib/x";
import { Modal } from "./kit/Modal";
import { ToggleInput } from "./kit/ToggleInput";
import { NoArraySentinelInput } from "@root/shared/src/schemas/productGuards";

type GroupBusyIndicator = 'archiving';

class ProductGroupsHomeController {
  private store: MainStore

  @observable groupBusy: Record<string, null|undefined|false|GroupBusyIndicator> = {};
  @observable unarchiverVisible = false;
  @observable archivedGroups: ProductGroup<FirewardOutput>[] | null = null;

  constructor(store: MainStore) {
    this.store = store;
  }

  @action showUnarchiver = () => {
    const uid = this.store.user.user?.uid;
    if (!uid) return;

    this.archivedGroups = null;
    this.unarchiverVisible = true;
    this.store.groups.loadArchived(uid)
    .then(action(groups => this.archivedGroups = groups))
    .catch(action(e => {
      this.unarchiverVisible = false;
      this.store.alert.error([errorToString(e)]);
      this.store.error.captureException(e, {extra: {place: 'productGroupHome::showUnarchiver::catch'}});
    }))
  }

  @action hideUnarchiver = () => {
    this.unarchiverVisible = false;
  }

  @action toggleArchiveGroup = (group: ProductGroup<FirewardOutput>, archived: boolean) => {
    const i2o = (p: ProductGroupColor<FirewardOutput>): ProductGroupColor<NoArraySentinelInput> => ({
      ...p // that this is necessary seems like a bug. you can do this inline, but I wanted to try different things.
    });
    
    this.updateGroup({...group, archived, lastWrite: new Date(), productColors: group.productColors.map(i2o)}, 'archiving')
    ?.then(action(() => {
      const next = this.archivedGroups?.find(g => g.id == group.id);
      if (next) next.archived = archived;
    }))
  }

  @action updateGroup = (group: ProductGroup<NoArraySentinelInput>, indicator: GroupBusyIndicator) => {
    const uid = this.store.user.user?.uid;
    if (!uid) return;

    this.groupBusy[group.id] = indicator;
    return this.store.groups.saveGroup(group, uid)
    .then(action(()=>{
      this.groupBusy[group.id] = false;
    }))
    .catch(action(e => {
      this.groupBusy[group.id] = false;
      this.store.alert.error([errorToString(e)]);
      this.store.error.captureException(e, {extra: {place: 'productGroupHome::updateGroup::catch', group}});
      return Promise.reject(e)
    }))
  }
}

export const ProductGroupsHome = observer(function ProductGroupsHome(props: {}) {

  const store = useStore();
  const routing = useRouting();

  const self = useController(() => new ProductGroupsHomeController(store));

  if (!store.user.state) return <Login/>;

  if (!store.products.productList) {
    return <SpinnerOverlay/>
  } else if (store.products.productList.length === 0) {
    return <div>Please <Link href={routing.products.href()}><LinkText>create some products</LinkText></Link> first.</div>
  }

  return <div className="ProductGroupHome">
    <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
    {
      store.groups.productGroups?.map( group => <li key={group.id} className="col-span-1 bg-white rounded-lg shadow">
        <div className="w-full flex items-center justify-between p-6 space-x-6">
          <div className="flex-1 truncate">
            <div className="flex items-center space-x-3">
              <h3 className="text-gray-900 text-sm leading-5 font-medium truncate">{group.name}</h3>
            </div>
          </div>
        </div>
        <div className="border-t border-gray-200">
          <div className="-mt-px flex">
            <div className="w-0 flex-1 flex border-r border-gray-200">
              <Link disabled={!!self.groupBusy[group.id]} href={routing.productGroupsEditor.href({groupId: group.id})} className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150">
                <Icon name="pencil" color="default" />
                <span className="ml-3">Edit</span>
              </Link>
            </div>
            <div className="-ml-px w-0 flex-1 flex">
              <div 
                onClick={() => self.groupBusy[group.id] || self.toggleArchiveGroup(group, true)}
                className={classNames(
                "relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5  font-medium border border-transparent rounded-br-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150",
                {
                  "text-gray-400 cursor-not-allowed": self.groupBusy[group.id],
                  "text-gray-700 cursor-pointer": !self.groupBusy[group.id],
                }
              )}>
                { self.groupBusy[group.id] === 'archiving'
                  ? <Spinner/>
                  : <Icon name="trash" color="danger" />
                }
                <span className="ml-3">Archive</span>
              </div>
            </div>
          </div>
        </div>
      </li>)
    }
    </ul>
    <ButtonBar left>
      <Button onClick={() => routing.productGroupsCreator.navigate({})} icon="plus" text="New Product Group" />
      <Button onClick={() => self.showUnarchiver()} noBorder text="Unarchive" />
    </ButtonBar>
    {
      self.unarchiverVisible && 
      <Modal
        title="Archived Groups"
        onOK={() => self.hideUnarchiver()}
        onXClose={self.hideUnarchiver}
        okColor="default"
        okButton="Close"  
        icon="clipboardList"
      >
        {
          self.archivedGroups
          ? <div>
              {
                self.archivedGroups.length > 0 && <div className="Header">
                  <span>Archived</span>
                </div>
              }
              <ul style={{maxHeight: '400px'}} className="overflow-auto">
              {
                self.archivedGroups.map(group => <li key={group.id} className="flex items-center mb-2 p-3">
                  <ToggleInput
                    disabled={!!self.groupBusy[group.id]}
                    className="mr-3"
                    value={group.archived}
                    onChange={archived => self.toggleArchiveGroup(group, archived)}
                  /> <span className="label text-sm">{group.name}</span>
                </li>)
              }
              </ul>
              {
                self.archivedGroups.length === 0 && <p className="text-sm text-gray-700">
                  No archived groups found.
                </p>
              }
            </div>
          : <Spinner/>
        }

      </Modal>
    }
  </div>
});
