import React = require('react');
import { observer } from 'mobx-react-lite';
import { observable, action, autorun, computed } from 'mobx';
import { useController } from '../util/mobxUtils';
import classNames = require('classnames');
import { ClassValue } from 'classnames/types';
import { MainStore } from '../store/store';
import { useStore } from '../store/StoreContext';
import { ProductModel, ProductImageModel } from '../store/ProductStore';
import { ToggleInput } from './kit/ToggleInput';
import { randomId } from '@root/shared/lib/x';
import { ImageUloadInput } from './kit/ImageUploadInput';
import { UserImage } from './kit/UserImage';
import { Icon } from './kit/icons';
import { Button } from './kit/Button';


export type ProductImagesInputProps = {
  dirty?: boolean
  className?: ClassValue
  value: ProductImageModel[]
  onChange: (value: ProductImageModel[]) => void
  error?: string|null|false
  max?: number
}

class ProductImagesInputController {
  
  @observable private props: ProductImagesInputProps;

  @observable urls: Record<string, string> = {}
  
  store: MainStore

  constructor(props: ProductImagesInputProps, store: MainStore) {
    this.props = props;
    this.store = store;
  }

  @computed get items() {
    return this.props.value
  }
  @action setProps = (props: ProductImagesInputProps) => {
    this.props = props;
  }

  @action addItem = (path: string, url: string) => {
    this.onChange([...this.items, {id: randomId(), path}])
  }
  @action removeItem = (item: ProductImageModel) => {
    if (!this.store.util.confirm(`Delete image?`)) return;

    const next = this.items
      .filter(prev=>prev.id !== item.id)
      ;

    this.onChange(next)
  }

  @action moveItem = (item: ProductImageModel, newIndex: number) => {
    const i = this.items.findIndex(prev => prev.id == item.id);
    const next = [...this.items];
    next.splice(i, 1);
    next.splice(newIndex, 0, item);

    console.log('moving item', this.items.map(x => x.id), next.map(x => x.id), i, newIndex)
    this.onChange(next);
  }

  @action onItemChange = (item: ProductImageModel) => {

    const next = {
      ...item, 
    }

    this.onChange(
      this.items.map(prev => prev.id === next.id ? next : {
        ...prev, 
      })
    );
    
  }
  @action private onChange = (items: ProductImageModel[]) => {
    this.props.onChange(items)
  }
}

export const ProductImagesInput = observer(function ProductImagesInput(props: ProductImagesInputProps) {
  
  const store = useStore();
  const self = useController(()=>new ProductImagesInputController(props, store), props);

  return <div className={classNames('ProductImagesInput', props.className)}>
    <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
    
    {
      self.items.map((item, itemIndex) => <li key={item.id} className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow">
        <div className="flex-1 flex flex-col p-8 bg-center bg-no-repeat bg-contain relative">
          <UserImage 
            className="absolute inset-0 w-full h-full z-0 mr-2"
            path={item.path}
            sizing='contain'
          />

          <ImageUloadInput
            className="h-full w-full z-10"
            onUpload={val => self.onItemChange({...item, path: val.image.path})}
            accept='image/*'
            type='product'
          />
          
        </div>
        <div className="border-t border-gray-200">
          <div className="-mt-px flex">
            <div className="w-0 flex-1 flex border-r border-gray-200">
              <div className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150">
                {/* <input type="radio" checked={!!item.primary} onChange={e => self.onItemChange({...item, primary: e.target.checked})} /> */}
                {/* <span className="ml-3">Primary</span> */}
                  <Button disabled={itemIndex == 0} noBorder onClick={() => self.moveItem(item, itemIndex - 1)} icon="circleLeftArrow" color="default" className="mr-3" />
                  <Button disabled={itemIndex > self.items.length - 2} noBorder onClick={() => self.moveItem(item, itemIndex + 1)} icon="circleArrowRight" color="default" />
              </div>
            </div>
            <div className="-ml-px w-0 flex-1 flex">
              <div onClick={() => self.removeItem(item)} className="cursor-pointer relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150">
                <Icon name="trash" color="danger" />
                <span className="ml-3">Delete</span>
              </div>
            </div>
          </div>
        </div>
      </li>
      )
    }
    {
      (!props.max || self.items.length < props.max) &&
      <li className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow">
        <div className="flex-1 flex flex-col p-8 bg-center bg-no-repeat bg-contain relative">
          <ImageUloadInput
            className="h-full w-full"
            onUpload={item => self.addItem(item.image.path, item.url)}
            accept='image/*'
            type='product'
          />
        </div>
      </li>
    }
    </ul>
    {props.error && props.dirty && <div className="error mt-1 text-sm">{props.error}</div>}
  </div>
});
