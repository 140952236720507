import React = require('react');
import { observer } from 'mobx-react-lite';
import { observable, action, autorun, computed, reaction } from 'mobx';
import { useController } from '../util/mobxUtils';
import classNames = require('classnames');
import { ClassValue } from 'classnames/types';
import { MainStore } from '../store/store';
import { useStore } from '../store/StoreContext';
import { errorToString, isValidationMapError, keys, Model, randomId, uniq } from '@root/shared/lib/x';
import { EbayTradingListingDuration, FirewardOutput, FirewardInput, PrintProvider, StandardSettings } from '@root/shared/lib/database';
import { NoSentinelOutput } from '@root/shared/lib/schemas/productGuards';
import { Login } from './Login';
import { SpinnerOverlay } from './kit/SpinnerOverlay';
import { TextInput } from './kit/TextInput';
import { TextArea } from './kit/TextArea';
import { Select } from './kit/Select';
import { Spinner } from './kit/Spinner';
import {ebayTradingListingDurations} from '@root/shared/lib/api/ebayTypes';
import { ButtonBar } from './kit/ButtonBar';
import { Button } from './kit/Button';
import { validateStandardSettings } from '../store/SettingsStore';
// import { Link } from 'react-navi';
import { LinkText } from './kit/LinkText';
import { Link, useRouting } from '../routing/routes';
import { Icon } from './kit/icons';
import { Asterisk } from './kit/Asterisk';


export type StandardSettingsPageProps = {
  className?: ClassValue
}

type StandardSettingsModel = {
  description: StandardSettings<NoSentinelOutput>['description']
  ebayListingDuration: EbayTradingListingDuration | null
  paymentPolicy: {
    name: string
    ebayPolicyId: number
  } | null;
  returnPolicy: {
    name: string
    ebayPolicyId: number
  } | null;
  providers: PrintProvider[]
}

const toModel = (settings: StandardSettings<FirewardOutput>): StandardSettingsModel => {
  return {
    providers: [],
    ...settings
  }
}


class StandardSettingsPageController {
  
  @observable private props: StandardSettingsPageProps;
  private store: MainStore;

  @observable model?: StandardSettingsModel

  @observable dirty = false;

  @observable saving = false;

  constructor(props: StandardSettingsPageProps, store: MainStore) {
    this.props = props;
    this.store = store;
    
    store.user.onAuth(uid => {
      if (!uid) this.model = undefined;
    });
    
    reaction(()=>store.ebay.data, acct => acct && store.ebay.updatePolicies(), {fireImmediately: true});
    reaction(()=>store.settings.standardSettings, settings => this.model = settings ? toModel(settings) : {
      description: {
        feedback: '',
        contactUs: ''
      },
      ebayListingDuration: 'GTC',
      paymentPolicy: null,
      returnPolicy: null,
      providers: []
    })

    store.settings.getStandardSettings()
      .then(action(settings => this.model = settings ? toModel(settings) : {
        description: {
          feedback: '',
          contactUs: ''
        },
        ebayListingDuration: 'GTC',
        paymentPolicy: null,
        returnPolicy: null,
        providers: []
      }));
  }

  @action setProps = (props: StandardSettingsPageProps) => {
    this.props = props;
  }

  @computed get initializing() {
    return this.store.settings.standardSettings === undefined;
  }

  @computed get paymentPolicyOptions() {
    
    return this.store.ebay.policies?.paymentProfiles.map(p => ({
      label: p.profileName,
      value: p.profileId
    }))
  }

  @computed get returnPolicyOptions() {
    return this.store.ebay.policies?.returnPolicyProfiles.map(p => ({
      label: p.profileName,
      value: p.profileId
    }))
  }

  @computed get listingDurationOptions() {
    const num = (v: string|undefined) => v ? parseInt(v) : NaN
    return keys(ebayTradingListingDurations).map(value => ({
      value, label: value.split('_').join(' ')
    })).sort((a,b) => num(a.value.split('_').pop()) > num(b.value.split('_').pop()) ? 1 : -1)
  }

  @action setReturnPolicy = (ebayPolicyId: number|undefined|null) => {
    if (this.model) {
      this.model.returnPolicy = ebayPolicyId 
        && this.store.ebay.policies?.returnPolicyProfiles
          .map(p => ({ebayPolicyId: p.profileId, name: p.profileName}))
          .find(p => p.ebayPolicyId == ebayPolicyId) || null
    }
  }
  @action setPaymentPolicy = (ebayPolicyId: number|undefined|null) => {
    if (this.model) {
      this.model.paymentPolicy = ebayPolicyId 
        && this.store.ebay.policies?.paymentProfiles
          .map(p => ({ebayPolicyId: p.profileId, name: p.profileName}))
          .find(p => p.ebayPolicyId == ebayPolicyId) || null
    }
  }

  @action addProvider = () => {
    if (!this.model) return;
    this.model.providers = this.model.providers || [];
    this.model.providers.push({
      code: '',
      name: '',
      id: randomId('provider-')
    })
  }

  @action removeProvider = (provider: StandardSettingsModel['providers'][0]) => {
    if (!this.model) return;
    this.model.providers = this.model.providers || [];
    this.model.providers = this.model.providers.filter(prev => prev.id != provider.id);
  }

  @action save = () => {

    const uid = this.store.user.user?.uid;
    if (!this.model || !uid) return;
    
    const value = {...this.model};
    this.dirty = true;
    
    if (!value.description.feedback || !value.description.contactUs) return;

    if (uniq(this.model.providers?.map(p => p.code)).length != this.model.providers?.length) return;
    if (uniq(this.model.providers?.map(p => p.name)).length != this.model.providers?.length) return;
    if (!this.model.providers.every(p => p.shipFromPostalCodeDefault?.trim())) return;

    try {
      if (!validateStandardSettings(value)) return;
    } catch (error) {
      this.store.alert.error(
        isValidationMapError(error) ? error.messages() : [errorToString(error)],
        'Please fix errors'
      )
      return;
    }

    value.providers.forEach( p => {
      if (!p.shipFromLocation) delete p.shipFromLocation;
      if (!p.shipFromPostalCodeDefault) delete p.shipFromPostalCodeDefault;
    })
    
    this.saving = true;

    this.store.settings.saveStandardSettings(value, uid)
    .then(action(() => {
      this.dirty = false;
      this.saving = false;
      this.store.alert.success('Settings saved.')
    }))
    .catch(action(e => {
      this.saving = false;
      this.store.alert.error([errorToString(e)], "Server returned an error");
    }))

    ;

  }

  // private fromModel = (model: StandardSettingsModel): StandardSettings<FirewardOutput> => {
  //   return {
  //     description: model.description,
  //     ebayListingDuration: 
  //   }
  // }
}

export const StandardSettingsPage = observer(function StandardSettingsPage(props: StandardSettingsPageProps) {
  
  const store = useStore();
  const routing = useRouting();
  const self = useController(()=>new StandardSettingsPageController(props, store), props);
  
  if (!store.user.user) {
    return <Login />
  }

  if (store.ebay.data === undefined) {
    return <div className="py-32">
      <SpinnerOverlay text="loading eBay account" />
    </div>
  }

  if (store.ebay.data === null) {
    return <div className="text-sm text-gray-500">
      Please <Link href={routing.linkedAccounts.href()}>
        <LinkText>connect your eBay</LinkText>
      </Link> account before editing the settings.
    </div>
  }

  
  if (!self.model) {
    return <div className="py-32">
      <SpinnerOverlay text="loading model" />
    </div>
  }

  return <div className={classNames('StandardSettingsPage', props.className)}>
    <TextArea
      className="my-4"
      label="Description — Feedback"
      value={self.model.description.feedback}
      onChange={val => { if (self.model) self.model.description.feedback = val || '' }}
      dirty={self.dirty}
      errorFunc={val => val ? null : `please enter feedback description`}
    />
    <TextArea
      className="my-4"
      label="Description — Contact Section"
      value={self.model.description.contactUs}
      onChange={val => { if (self.model) self.model.description.contactUs = val || '' }}
      dirty={self.dirty}
      errorFunc={val => val ? null : `please enter contact description`}
    />

    {
      self.returnPolicyOptions
      ? <Select
          className="my-4"
          label="Return Policy"
          value={self.model.returnPolicy?.ebayPolicyId}
          onChange={val => self.setReturnPolicy(val)}
          options={self.returnPolicyOptions}
          error={self.returnPolicyOptions.length === 0 ? `none set up with eBay` : !self.model.returnPolicy ? `please select one` : null}
          dirty={self.dirty}
      />
      : <Spinner/>
    }

    {
      self.paymentPolicyOptions
      ? <Select
          className="my-4"
          label="Payment Policy"
          value={self.model.paymentPolicy?.ebayPolicyId}
          onChange={val => self.setPaymentPolicy(val)}
          options={self.paymentPolicyOptions}
          error={self.paymentPolicyOptions.length === 0 ? `none set up with eBay` : !self.model.returnPolicy ? `please select one` : null}
          dirty={self.dirty}
        />
      : <Spinner/>
    }

    {/* <Select
      label="Listing Duration"
      value={self.model.ebayListingDuration}
      onChange={val => { if (self.model) self.model.ebayListingDuration = val || null}}
      options={self.listingDurationOptions}
      dirty={self.dirty}
      error={self.listingDurationOptions ? null : `please select one`}
    /> */}
    {
      self.model.ebayListingDuration && <div className="text-xs text-gray-500 my-2">
        {ebayTradingListingDurations[self.model.ebayListingDuration]}
      </div>
    }

    <h3 className="label text-lg mb-2 mt-4">Print Providers</h3>
    {
      !self.model.providers?.length && self.dirty && <div className="text-red-600 text-xs -mt-2 mb-3">
        Please set up at least one provider.
      </div>
    }
    <table>
      <thead className="text-sm label">
        <tr>
          <th>Code <Asterisk/></th>
          <th>Name <Asterisk/></th>
          <th>Default Zip <Asterisk/></th>
          <th>Location <span className="text-xs text-gray-600 font-normal">optional description</span></th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        {
          self.model.providers?.map(p => <tr key={p.id} className="mb-3">
            <td className="pr-2 w-24">
              <TextInput
                value={p.code}
                onChange={val => p.code = val || ''}
                errorFunc={val => !(val||'').trim() ? `required` : self.model?.providers.find(o => o.id != p.id && o.code.trim() && o.code == val?.trim()) ? `code already exists` : null}
                dirty={self.dirty}
              />
            </td>
            <td className="pr-2">
              <TextInput
                value={p.name}
                onChange={val => p.name = val || ''}
                errorFunc={val => !(val||'').trim() ? `required` : self.model?.providers.find(o => o.id != p.id && o.name.trim() && o.name == val?.trim()) ? `name already exists` : null}
                dirty={self.dirty}
              />
            </td>
            <td className="pr-2 w-24">
              <TextInput
                value={p.shipFromPostalCodeDefault || ''}
                onChange={val => p.shipFromPostalCodeDefault = val || ''}
                errorFunc={val => !(val||'').trim() ? `required` : null}
                dirty={self.dirty}
              />
            </td>
            <td className="pr-2">
              <TextInput
                value={p.shipFromLocation || ''}
                onChange={val => p.shipFromLocation = val || ''}
                hint="Optional text decription of ship-from location for eBay."
                dirty={self.dirty}
              />
            </td>
            <td className="pr-2">
              <Icon name="trash" color="danger" title="remove" onClick={() => self.removeProvider(p)}/>
            </td>
          </tr>)
        }
      </tbody>
    </table>
    <div className="mt-2 mb-4">
      <Button noBorder icon="circlePlus" text="add" onClick={self.addProvider} />
    </div>

    <ButtonBar left>
      <Button spinner={self.saving} onClick={self.save} text="Save"/>
    </ButtonBar>
    


  </div>
});