import React = require("react")
import { ClassValue } from "classnames/types"
import classNames = require("classnames")
import { randomId } from "@root/shared/lib/x"
import { action } from "mobx"

export type RadioInputProps<T> = {
  className?: ClassValue
  label?: string|JSX.Element
  help?: string
  inputId?: string
  disabled?: boolean
  readOnly?: boolean
  onChange?: (value: T) => void
  title?: string
  value: T
  checked?: boolean
  group: string
  inputClassName?: ClassValue
  error?: string|null|undefined
  dirty?: string
}

export const RadioInput = function RadioInput<T>(props: RadioInputProps<T>) {
  
  const [ inputId ] = React.useState<string>(()=>props.inputId || randomId('RadioInput'));

  const [ dirty, setDirty ] = React.useState<boolean>(false);

  const error = (dirty || props.dirty) && props.error

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.checked && props.onChange && action(props.onChange)(props.value);
    setDirty(true)
  }
  React.useEffect(() => {
    if (!props.dirty) setDirty(false);
  }, [props.dirty]);

  return <div className={classNames(["relative flex items-center", props.className])}>
    <div className="flex items-center h-5">
      <input 
        title={props.title}
        checked={props.checked || false} 
        onChange={props.readOnly ? undefined : onChange} 
        disabled={props.disabled} 
        readOnly={props.readOnly}
        id={inputId} 
        type="radio"
        name={props.group}
        className={classNames([
          "form-radio h-4 w-4 transition duration-150 ease-in-out",
          {
            "text-indigo-400": props.disabled, 
            "text-indigo-600": !props.disabled, 
            "cursor-not-allowed": props.disabled || props.readOnly
          },
          props.inputClassName
        ])}/>
    </div>
    {
      props.label &&
      <div className="ml-3 small">
        <label htmlFor={inputId} className={classNames(["label", {muted: props.disabled}])}>{props.label}</label>
        {
          props.help && 
          <p className="muted">{props.help}</p>
        }
      </div>
    }
    {
      error && <p className="mt-2 text-sm text-red-600" id={inputId + `email-error`}>
      {error}
    </p>
    }
  </div>
}
