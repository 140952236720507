import { FirewardOutput, Product, ProductCanvas } from "./database";
import {IMAGE_WIDTHS} from './schemas/constants';
import { ImgixMetadata } from "./imgixTypes";
import { containBox } from "./x";
// import type * Imgix = require('imgix-core-js');

export type ImageDetail = {
  path: string
  info: {PixelWidth: number, PixelHeight: number}
}

type Imgix = {
  buildURL(path: string, options?: object): string
}
/**
 * Build a preview image by layering the design over the blank product
 * @param backgroundPath path to the background product preview image (i.e. blank shirt)
 */
export const buildProductPreview = ({
  imgix,
  canvas,
  canvasPositioning,
  designImg,
  blankProductImg 
}:{
  imgix: Imgix
  canvas: ProductCanvas<FirewardOutput>
  canvasPositioning: Product<FirewardOutput>['previewCanvasOptions']['positioning']
  designImg: ImageDetail
  blankProductImg: ImageDetail
}) => {

  //
  // Imgix allows us to set the blend width in fraction of full width. Frac represents here the fraction of the blank product image.
  //

  const productImgInfo = blankProductImg.info;
  const designImageInfo = designImg.info;

  const desRatio = designImageInfo.PixelWidth / designImageInfo.PixelHeight;
  const prevRatio = productImgInfo.PixelWidth / productImgInfo.PixelHeight;
  
  const canvasDivisor = canvas.positioningRule === 'mirrored'
    ? 2 : 1;
  
  const canvRatio = canvas.width / canvas.height / canvasDivisor;
  

  const canvWidthFrac = canvasPositioning.scale;

  const canvHeightFrac = canvWidthFrac
    / canvRatio
    * prevRatio // the preview ratio is here because the we need the fraction of the preview's height, not width, as it would've been without this multiplier
  ;


  const constWidthInches = IMAGE_WIDTHS[canvas.positioningRule]; // width constraint

  
  const maxDesignWidthFrac = canvWidthFrac * (constWidthInches ? constWidthInches / canvas.width : 1);
  const boxed = containBox(
    {w: maxDesignWidthFrac, h: canvHeightFrac / prevRatio}, // Because we can't have w and h in different units here, the units `h` here is expressed in are fraction of preview width. 
    {w: designImg.info.PixelWidth, h: designImg.info.PixelHeight}
  );
  
  
  const desWidthFrac = boxed.w;
  const desHeightFrac = desWidthFrac 
    / desRatio 
    * prevRatio;

  
  const shiftUp = (canvas.positioningRule ==='mirrored' || canvas.positioningRule === 'center') ? (canvHeightFrac - desHeightFrac) / 2 : 0;
  const shiftRight = (canvWidthFrac - desWidthFrac) / 2;
  
  return imgix.buildURL(blankProductImg.path, {
    'blend': imgix.buildURL(designImg.path),
    'blend-x': (canvasPositioning.left + shiftRight) * productImgInfo.PixelWidth,
    'blend-y': (canvasPositioning.top + shiftUp) * productImgInfo.PixelHeight,
    'blend-w': desWidthFrac,
    'blend-h': desHeightFrac,
    'blend-fit': 'clip',
    'blend-mode': 'normal'
    // 'blend-align' does not work here
  })
}