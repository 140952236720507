import { action, observable, observe } from "mobx";
import { observer } from "mobx-react-lite";
import React = require("react");
import { Link, useRouting } from "../routing/routes";
import { Button } from "./kit/Button";
import { useStore } from "../store/StoreContext";
import { Icon } from "./kit/icons";
import { Spinner } from "./kit/Spinner";
import { Login } from "./Login";
import { LinkText } from "./kit/LinkText";
import { WebDependencies } from "../WebDependencies";
import { useController } from "../util/mobxUtils";
import { MainStore } from "../store/store";
import { RegisteredEbayCategory, FirewardOutput } from "@root/shared/lib/database";
import { errorToString } from "@root/shared/lib/x";


class EbayRegisteredCategoriesController {
  @observable catBusy: Record<string, boolean> = {}

  store: MainStore
  constructor(store: MainStore) {
    this.store = store;
  }
  archiveCategory(cat: RegisteredEbayCategory<FirewardOutput>): void {
    const uid = this.store.user.user?.uid;
    if (!uid) return;
    if (!this.store.util.confirm(`Archive the category ${cat.name}?`)) {
      return;
    }

    this.catBusy[cat.categoryId] = true;
    this.store.ebay.registerCategory(uid, {...cat, archived: true})
    .then(action(() => {
      cat.archived = true;
      this.catBusy[cat.categoryId] = false;
    }))
    .catch(action((e) => {
      this.catBusy[cat.categoryId] = false;
      this.store.alert.error([errorToString(e)]);
      this.store.error.captureException(e, {extra: {place: 'archiveCategory'}});
    }))
  }
}

export const EbayRegisteredCategories = observer(function EbayRegisteredCategories(){
  const [ saving, setSaving ] = React.useState<boolean>(false);

  const store = useStore();
  const self = useController(() => new EbayRegisteredCategoriesController(store));

  const routing = useRouting();
  const user = store.user.user;

  if (!user) return <Login />
  
  if (!store.ebay.data) return <div>
    <p className="text">Please <Link href={routing.linkedAccounts.href()}>
        <LinkText>link your ebay account</LinkText>
      </Link> first.
    </p>
  </div>;
  
  return <div>
    <ul>
      {
        !store.ebay.registeredCategories
        ? <Spinner text="loading categories" />
        : store.ebay.registeredCategories.filter(cat => !cat.archived)
          .map( cat => <li key={cat.categoryId} className="mb-2">
            <div className="label">{cat.customTitle || cat.name}</div>

            <div className="small muted flex items-center">ID {cat.categoryId} Level {cat.categoryLevel} <Icon 
              className="ml-2" name="pencil" color="default" 
              onClick={() => routing.ebayCategoryEditor.navigate({catLevel: cat.categoryLevel, categoryId: cat.categoryId})}
            /> <Button
              className="ml-2" icon="trash" color="danger" noBorder
              onClick={() => self.archiveCategory(cat)}
            />
            
            </div>

          </li>)
      }
    </ul>
    <div className="mt-8 border-t border-gray-200 pt-5">
      <Link href={routing.ebayCategoryPicker.href()}>
        <Button icon="plus">Add a new category</Button>
      </Link>
    </div>
  </div>
})
