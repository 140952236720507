import { UserStore } from "./UserStore";
import { WebDependencies } from "../WebDependencies";
import { DropBoxStore } from "./DropBoxStore";
import { EbayStore } from "./EbayStore";
import { ErrorStore } from "./ErrorStore";
import { AlertStore } from "./AlertStore";
import { ProductGroupStore } from "./ProductGroupStore";
import { ProductStore } from "./ProductStore";
import { ImageStore } from "./ImageStore";
import { action, runInAction } from "mobx";
import { ListingsStore } from "./ListingsStore";
import { OrderDeskStore } from "./OrderDeskStore";
import { SettingsStore } from "./SettingsStore";
import { ModalStore } from "./ModalStore";

import scrollIntoView from 'scroll-into-view-if-needed';
import { guardOnly, isNull, isNumber, isString, isValid, Pred } from "@root/shared/lib/x";
import { number } from "mobx-state-tree/dist/internal";
import { ImgixMetadata } from "@root/shared/src/imgixTypes";

export function createStore(deps: WebDependencies) {

  return runInAction(()=>{

    const user = new UserStore(deps);
    const error = new ErrorStore(deps, user);
    const alert = new AlertStore(deps, user);
    
    const images = new ImageStore(deps, user, error, alert);
    const store = {
      user,
      error,
      modal: new ModalStore(deps),
      listings: new ListingsStore(deps, user, error, alert),
      groups: new ProductGroupStore(deps, user, error, alert),
      products: new ProductStore(deps, user, error, alert),
      images: images,
      dropbox: new DropBoxStore(deps, user),
      ebay: new EbayStore(deps, user, error, alert),
      orderDesk: new OrderDeskStore(deps, user, error, alert),
      alert: alert,
      settings: new SettingsStore(deps, user, error),
      fieldValue: {
        serverTimestamp: () => deps.firebase.firestore.FieldValue.serverTimestamp()
      },
      browser: {
        scrollIntoView: (selector: string) => {
          const el = document.querySelector(selector)
          el && scrollIntoView(el, { behavior: 'smooth' })
        }
      },
      util: {
        alert: window.alert.bind(window),
        confirm: confirm.bind(window),
        wait: (delayMS: number) => new Promise(res => setTimeout(res, delayMS)),
        interval: (intervalMS: number, callback: (count: number) => void) => {
          let count = 0;
          const t = setInterval(() => callback(count++), intervalMS);
          return () => clearInterval(t);
        },
        getImageResolution: (path: string, highPrecision = false): Promise<ImgixMetadata> => fetch(deps.imgix.buildURL(path, highPrecision ? undefined : { fm: 'json'}))
        .then(res => res.json())
      }
    }
    /// @ts-ignore
    window.store = store;
    return store;
  });

}

export type MainStore = ReturnType<typeof createStore>;

export type ModelStorage<T> = {
  value: T;
  time: number;
  appVersion: string
};

export const isModelStorage = <T>(pred: Pred<T>) => guardOnly(isValid<ModelStorage<T>>({
  value: [pred] as any,
  time: isNumber,
  appVersion: isString,
}))