import { WebDependencies } from "../WebDependencies";
import { computed, observable, autorun, action, flow } from "mobx";
import { UserStore } from "./UserStore";
import { snapAction, snapData } from "../util/util";
import { UserDropbox } from "@root/shared/lib/database";
// import { flowed } from "../util/mobxUtils";
import { authAutorun } from "./storeUtils";

export class DropBoxStore {

  private deps: WebDependencies;
  private auth: UserStore
  
  @observable private _data: null|undefined|UserDropbox = undefined;
  @observable private _redirecting = false; 

  constructor(deps: WebDependencies, auth: UserStore) {
    this.deps = deps;
    this.auth = auth;
    
    auth.onAuth( uid => {
      if (uid) {
        this._data = undefined;
        return deps.firebase.firestore().collection('dropbox').doc(uid).onSnapshot(snapAction(snap => {
          this._data = snapData<UserDropbox>(snap)
        }))
      } else {
        this._data = null;
      }
    })
  }
  private getAuthLink = (uid: string) => {
    const state = JSON.stringify({u: uid, h: this.deps.config.baseURL});
    return this.deps.dropbox.getAuthenticationUrl(
      this.deps.config.baseURL + `/api/dropbox/oauth`, 
      state, 'code',
      undefined
      );
  }
  @computed get redirecting() {
    return this._redirecting;
  }
  @action redirectToAuth = async  () => {
    const uid = this.auth.state && this.auth.state.uid;
    if (!uid) throw new Error(`Please log in first.`);
    const link = this.getAuthLink(uid);
    this._redirecting = true;
    this.deps.location.href = link;
  }
  @computed get data() {
    return this._data
  }
  // @flowed *createReadmeFile(this: DropBoxStore) {
  //   console.log('initiating')
  //   try {
  //     const res = yield this.deps.firebase.functions().httpsCallable('createDropboxReadme')({})
  //   } catch (error) {
  //     console.log('error', error)
  //   }

  // }
}
