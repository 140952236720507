import React = require('react');
import { observer } from 'mobx-react-lite';
import { observable, action, autorun, computed, toJS } from 'mobx';
import { useController } from '../util/mobxUtils';
import classNames = require('classnames');
import { ClassValue } from 'classnames/types';
import { CanvasImage } from './CanvasImage';
import { Assign, hasNumber, hasChild, hasOptionalChild, isValid, isNumber, isString, Pred, containBox } from '@root/shared/lib/x';
import { ProductCanvasModel, ProductModel } from '../store/ProductStore';
import { FirewardOutput, PositioningRule, ProductCanvas, ProductCanvasPositioning } from '@root/shared/lib/database';
import { isPositionRule, NoSentinelOutput, isProductCanvasPositioning } from '../../../shared/lib/schemas/productGuards';
import { Size, useSize } from '../util/util';
import { Icon } from './kit/icons';

type PositioningModel = ProductCanvasPositioning<NoSentinelOutput>
export type CanvasPlacementInputModel = ProductModel['previewCanvasOptions']['positioning']

export type CanvasPlacementInputProps = {
  className?: ClassValue
  previewImageUrl: string
  canvas: ProductCanvas<FirewardOutput>
  value: CanvasPlacementInputModel
  onChange?: (value: CanvasPlacementInputModel)=>void
}
const MIN_SCALE = Number.MIN_VALUE;
const DEFAULT_SCALE = .5;
const SCALE_STEP = 0.02;
const POS_STEP = 0.009;

/**
 * A few key points: 
 * - scale is measured as fraction of image width
 * - top is measured as fraction of image height
 * - left is measured as fraction of image width
 */
class CanvasPlacementInputController {
  
  @observable private props: CanvasPlacementInputProps;
  @observable imgSize: Size|null = null;
  @observable canSize: Size|null = null;


  constructor(props: CanvasPlacementInputProps) {
    this.props = props;

    autorun(() => {
      if (!this.props.value && this.state) {
        this.move(0, 0)
      }
    }, {delay: 100})
  }

  @action setProps = (props: CanvasPlacementInputProps) => {
    this.props = props;
  }

  @action onImageSize = (s: Size) => {
    this.imgSize = s;
  }
  @action onCanvasSize = (s: Size) => {
    this.canSize = s;
  }


  
  private onChange = (positioning: PositioningModel) => {
    console.log('placement input change', positioning, this.props.value)
    this.props.onChange && this.props.onChange({
      ...this.props.value, ...positioning 
    })
  }

  @action moveLeft = () => {
    this.move(0, -POS_STEP);
  }

  @action moveUp = () => {
    this.move(-POS_STEP, 0);
  }
  @action moveBottom = () => {
    this.move(POS_STEP, 0);
  }

  @action moveRight = () => {
    this.move(0, POS_STEP);
  }

  @action grow = () => this.resize(SCALE_STEP);
  @action shrink = () => this.resize(-SCALE_STEP);

  @action private resize = (scaleDelta: number) => {
    
    if (!this.state) return;
    
    const prev = this.state.scale;
    const scale = this.constrainScale(this.state.scale + scaleDelta, this.state.img);
    if (prev === scale) return;

    this.onChange({
      scale: scale,
      top: this.state.top,
      left: this.constrainLeft(this.state.left - scaleDelta / 2, this.state.width)
    })
  }

  @action private move = (topDelta: number, leftDelta: number) => {
    
    if (!this.state) return;

    const next = {
      top: this.constrainTop(this.state.top + topDelta, this.state.height),
      left: this.constrainLeft(this.state.left + leftDelta, this.state.width),
      scale: this.state.scale
    }

    this.onChange(next);
  }

  private constrainTop = (val: number, height: number) => {
    return Math.min(Math.max(0, val), 1 - height)
  }
  private constrainLeft = (val: number, width: number) => {
    return Math.min(Math.max(0, val), 1 - width)
  }
  private constrainScale = (scale: number, imgSize: Size) => {
    
    
    const canvasRatio = this.canvas.width / this.canvas.height;  
    const imgRatio = imgSize.width / imgSize.height;
    const {w} = containBox({w: 1, h: 1 * canvasRatio}, {w: 1, h: 1 * imgRatio});
    
    const max = w;

    return Math.max(MIN_SCALE, Math.min(max, scale))
  }

  @computed get halved() {
    return this.props.canvas.positioningRule === 'mirrored'
  }
  @computed get canvas() {
    return this.halved
      ? {width: this.props.canvas.width / 2, height: this.props.canvas.height}
      : {width: this.props.canvas.width    , height: this.props.canvas.height}
  }
  
  @computed get state() {
    
    const img = this.imgSize;
    if (!img) return null;

    const canRatio = this.canvas.width / this.canvas.height;  
    
    const imgRatio = img.width / img.height;
    const positioning = this.props.value || {
      scale: DEFAULT_SCALE, top: undefined, left: undefined
    }
    
    const scale = this.constrainScale( // fraction of image width
      positioning.scale || DEFAULT_SCALE, img
    );

    
    const width = Math.max(scale, MIN_SCALE);
    const height = Math.max(width / canRatio * imgRatio, MIN_SCALE);

    const top = this.constrainTop(
      positioning.top !== undefined
        ? positioning.top
        : (1 - height) / 2
      , height)
        ;

    const left = this.constrainLeft(
      positioning.left !== undefined
        ? positioning.left
        : (1 - width) / 2
      , width)
    ;

    const checkSize = 8
    
    return {
      checkSize,
      top, left,
      height, width,
      scale, 
      img
    }  
  }
}

export const CanvasPlacementInput = observer(function CanvasPlacementInput(props: CanvasPlacementInputProps) {
  
  const self = useController(()=>new CanvasPlacementInputController(props), props);

  const imgRef = useSize<HTMLImageElement>(self.onImageSize);
  const canRef = useSize<HTMLImageElement>(self.onCanvasSize);

  return <div className={classNames('CanvasPlacementInput', props.className)}>
    <div className="flex flex-col select-none">
      <div className="top h-8 flex justify-center"> 
        <Icon onClick={self.moveUp} className="cursor-pointer" name="chevronUp" color="default" size={6} /> 
      </div>
      <div className="middle flex">
        <div className="left w-8 flex flex-col justify-center">
          <Icon onClick={self.moveLeft} className="cursor-pointer" name="chevronLeft" color="default" size={6} />
        </div>
        <div className="relative">
          {
            self.state && <CanvasImage
              ref={canRef}
              className="inline-block z-10 opacity-75 absolute" 
              checkSize={self.state.checkSize} 
              width={100 * self.state.width + '%'}
              height={100 * self.state.height + '%'}
              style={{top: 100 * self.state.top + '%', left: 100 * self.state.left + '%'}}
            />
          }
          <img ref={imgRef} className="" src={props.previewImageUrl}/>
        </div>
        <div className="right w-8 flex flex-col justify-center">
          <Icon onClick={self.moveRight} className="cursor-pointer" name="chevronRight" color="default" size={6} />
        </div>
      </div>
      <div className="bottom h-8 flex justify-between items-center">
        <Icon onClick={self.shrink} className="cursor-pointer" name="circleMinus" color="default" size={6} />
        <Icon onClick={self.moveBottom} className="cursor-pointer self-end" name="chevronDown" size={6} color="default" />
        <Icon onClick={self.grow} className="cursor-pointer" name="circlePlus" color="default" size={6} />
      </div>
    </div>
    {
      self.halved && <div className="text-xs text-gray-600 ml-6 mr-6">
        Note: the <em>preview</em> canvas shown is half of what the print canvas will be. This is to reflect duplication.
      </div>
    }
  </div>
});
