import React = require("react")
import classNames = require("classnames")
import { ClassValue } from "classnames/types"

export type LinkProps = React.PropsWithChildren<{
  text?: string
  bold?: boolean
  onClick?: ()=>void,
  className?: ClassValue
}>
export const LinkText = (props: LinkProps)=>{
  
  return <span onClick={props.onClick} className={classNames({
    "cursor-pointer": true,
    "text-sm leading-5 text-indigo-600 truncate": true,
    "font-medium": props.bold,
    "font-light": !props.bold
  }, props.className)}>
    {props.children || props.text}
  </span>
}
