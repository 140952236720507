import { InputProps } from "./inputTypes"
import { observer } from "mobx-react-lite"
import React = require("react")
import { observable, action, autorun, reaction } from "mobx";
import { useController } from "../../util/mobxUtils";

import classNames = require("classnames");

import { MainStore } from "../../store/store";
import { useStore } from "../../store/StoreContext";
import { SpinnerOverlay } from "./SpinnerOverlay";
import type { ClassValue } from "classnames/types";


export type UserImageProps = {
  path?: string
  imageClasses?: ClassValue  
  sizing?: 'contain'|'cover'
  repeat?: boolean
  version?: string|number
  edge?: number
} & InputProps;

class UserImageController {
  
  @observable private props: UserImageProps;
  @observable url: string|null = null;

  private store: MainStore;

  @observable loading = false;

  constructor(props: UserImageProps, store: MainStore) {
    
    this.props = props;
    this.store = store;

    reaction(() => [this.props.path, this.props.version, this.props.edge], ()=>{

      this.props.path && this.store.images.getImageUrl(this.props.path, this.props.version || '', this.props.edge)
        .then(action(url => this.url = url))
    })
  }

  @action setProps = (props: UserImageProps) => {
    this.props = props;
  }

  @action onLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    this.loading = false
  }
}

export const UserImage = observer(function UserImage(props: UserImageProps) {

  const store = useStore();
  const self = useController(()=>new UserImageController(props, store), props);

  const bg = props.sizing && 
    <div style={{backgroundSize: props.sizing, backgroundImage: `url(${self.url})`}} 
      className={classNames("absolute inset-0 w-full h-full", props.imageClasses, {
        'bg-no-repeat': !props.repeat,
        'bg-center': true
      })}></div>;

  const tag = !props.sizing && self.url &&
    <img src={self.url} onLoad={self.onLoad} className={classNames('w-full max-h-full', props.imageClasses)}/>;


  return <div className={classNames('UserImage', props.className || 'relative')}>
    {
      self.url 
      ? (bg || tag)
      : <SpinnerOverlay show={self.loading} />
    }
  </div>
})
