import React = require("react")

export type NotFoundDetailProps = {
  children?: React.ReactChildren
  title?: string
  text?: string[]|string
}

export const NotFoundDetail = function NotFoundDetail(props: NotFoundDetailProps) {
  return <div className="small error NotFoundDetail NotFound">
    {props.title && <h2>{props.title}</h2>}
    {props.text && <p>{props.text}</p>}
    {props.children}
  </div>
}
