import {ISimpleType, IMaybe, IMaybeNull, types} from 'mobx-state-tree';
import { flow } from 'mobx';
import type { DropBoxStore } from '../store/DropBoxStore';
import React = require('react');
import { useLocalStore } from 'mobx-react-lite';
import { CancellablePromise } from 'mobx/lib/api/flow';

type NU<T> = T extends undefined ? never : T
type NN<T> = T extends null ? never : T

export type ToSimpleType<M> = {
  [k in keyof M]-?: 
  undefined extends M[k] ? IMaybe<ISimpleType<NU<M[k]>>> : ISimpleType<M[k]>
}

export const createModel = <T>(name: string, o:ToSimpleType<T>)=>types.model(name, o)


declare function 
  zflow<R, Args extends any[]>(
    generator: (...args: Args) => Generator<any, R, any> | AsyncGenerator<any, R, any>
  ): (...args: Args) => CancellablePromise<R>;
  

// export function flowed<T, R, Args extends any[]>(
//   generator: (this: T, ...args: Args) => Generator<any, R, any> | AsyncGenerator<any, R, any>
// ): (...args: Args) => CancellablePromise<R> {
//   /// @ts-ignore
//   return flow(generator.bind(this))
// };


// export function flowed<T, Args extends unknown[]>(
//   target: T, // prototype of the method's class 
//   name: string,
//   // descriptor: TypedPropertyDescriptor<()=>Generator|AsyncGenerator>
//   // descriptor: PropertyDescriptor
//   descriptor: TypedPropertyDescriptor<(this: T, ...args: Args) => any>
// ) {
//   console.log('stren', target, name)
//   if (descriptor.value) {
//     // descriptor.value = flow(descriptor.value.bind(this))
//     const f = descriptor.value;
//     // @ts-ignore
//     descriptor.value = target[name] = function(...args:any[]) {
//       console.log('fafafa', name, this)
//     // @ts-ignore
//       flow(f?.bind(this))(...args)
//     }
//   }
// }

export function useController<T>(init: () =>T): T
export function useController<T extends {setProps: (p: P)=>void}, P>(init: () =>T, props?: P): T
export function useController<T extends {setProps: (p: P)=>void}, P>(init: () =>T, props?: P): T {

  const value = useLocalStore(init)

  React.useEffect(() => {
    props && value.setProps(props)
  }, [props]);

  return value;
}
