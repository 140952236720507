import { UserStore } from "./UserStore";
import { ErrorStore } from "./ErrorStore";
import { AlertStore } from "./AlertStore";
import { WebDependencies } from "../WebDependencies";
import { authAutorun } from "./storeUtils";
import { snapData } from "../util/util";
import { hasString, hasKey, Assign, Model } from "@root/shared/lib/x";
import { observable, action, computed } from "mobx";
import { UserImage, FirewardOutput, UserImageType, FirewardInput } from "@root/shared/lib/database";
import { NoSentinelOutput } from "../../../shared/lib/schemas/productGuards";
import ImgixClient = require("imgix-core-js");


export class ImageStore {

  readonly imgix: Readonly<ImgixClient>
  private readonly user: UserStore
  private readonly error: ErrorStore

  private readonly alert: AlertStore
  private readonly deps: WebDependencies;

  @observable private _images: UserImage[]|null|undefined = null;

  private urlCache: Record<string, string> = {}

  constructor(deps: WebDependencies, user: UserStore, error: ErrorStore, alert: AlertStore) {

    this.deps = deps;
    this.user = user;
    this.error = error;
    this.alert = alert;

    this.imgix = deps.imgix;

    user.onAuth( uid =>{
      if (uid) {
        this._images = undefined
        return this.collection(uid).onSnapshot(
          snap=>snap.docs.forEach(d=>this.syncImage(d.id, snapData<UserImage<FirewardOutput>>(d))),
          e=>{
            error.captureException(e, {tags: {place: `list images`}});
            alert.error([e.message]);
          }
        );
      }
      else this._images = null
    }
      
    );    
  }

  private collection(uid: string) {
    return this.deps
      .firebase.firestore()
      .collection('users').doc(uid)
      .collection('images')
  }
  @action private syncImage = (id: string, img: UserImage<FirewardOutput> | null) => {
    if (!this._images) this._images = [];
    const i = this._images.findIndex(prev => prev.id == id)
    if (!img) {
      this._images = this._images.filter(prev => prev.id == id);
    } else {
      if (i == -1) this._images.push(img);
      else this._images[i] = img;
    }
  }

  uploadNewImage = async (o:{uid: string, type: UserImageType, ext: string, data: Blob | Uint8Array | ArrayBuffer | File}) => {
    const ref = this.collection(o.uid).doc();
    const id = ref.id;
    const d: UserImage<FirewardInput> = {
      created: this.deps.firebase.firestore.FieldValue.serverTimestamp(),
      id, 
      type: o.type, 
      ext: o.ext, 
      path: o.uid + `/` + id + '.' + o.ext
    }
    const mime = o.ext.toLowerCase() == 'jpg' ? 'jpeg' : o.ext.toLowerCase()
    
    await ref.set(d);
    const task = this.deps.firebase.storage().ref(d.path).put(o.data, {contentType: 'image/' + mime});
    const progress = (fn: (percent: number, complete: boolean)=>void) => {
      task.on(
        this.deps.firebase.storage.TaskEvent.STATE_CHANGED, 
        state=>fn(Math.ceil(state.bytesTransferred/state.totalBytes * 100), 
        state.state===this.deps.firebase.storage.TaskState.SUCCESS)
      );
      task.then(()=>fn(100, true))
    }
    
    return {
      image: d,
      progress,
      url: (fn: (url: string) => void) => task.then(r=>this.getImageUrl(d.path)).then(fn)
    }
  }

  @action getImageUrl = async (path: string, version: string|number = 0, width = 800): Promise<string> => {
    return this.deps.imgix.buildURL(path, {'max-w': width, 'max-h': width})
    // const cacheKey = path.toLocaleLowerCase() + version;
    // if (this.urlCache[cacheKey]) return this.urlCache[cacheKey];
    
    // return this.deps.firebase.storage().ref(path).getDownloadURL().then(action(url=>{
    //   this.urlCache[cacheKey] = url;
    //   return url;
    // }));
  }
}
