import React = require('react');
import { observer } from 'mobx-react-lite';
import { observable, action, autorun, computed } from 'mobx';
import { useController } from '../../util/mobxUtils';
import classNames = require('classnames');
import { ClassValue } from 'classnames/types';
import { UserImage, FirewardInput } from '@root/shared/lib/database';
import { FileDropzoneInputProps, FileDropzoneInput } from './FileDropzoneInput';
import { MainStore } from '../../store/store';
import { useStore } from '../../store/StoreContext';
import { isArray } from '@root/shared/lib/x';
import { NoSentinelOutput } from '../../../../shared/lib/schemas/productGuards';
import { SpinnerOverlay } from './SpinnerOverlay';


export type ImageUloadInputProps = {
  
  className?: ClassValue
  disabled?: boolean
  
  onUpload: (value: {image: UserImage<FirewardInput>, url: string}) => void

  type: UserImage['type']
  accept: FileDropzoneInputProps['accept']

  multiple?: boolean
  onProgress?: (imageId: string, percent: number, complete: boolean)=>void
}

class ImageUloadInputController {
  @observable uploadingCount = 0;
  @observable private props: ImageUloadInputProps;
  @observable progress: number | null = null;

  private store: MainStore;
  constructor(props: ImageUloadInputProps, store: MainStore) {
    this.props = props;
    this.store = store;
  }
  @computed get uid() {
    return this.store.user.state?.uid
  }
  
  @action setProps = (props: ImageUloadInputProps) => {
    this.props = props;
  }
  
  @action onDrop = (files: File[]|File) => {
    const items = isArray(files) ? files : [files];
    items.forEach(this.upload)
  }

  @action upload = (file: File) => {
    
    if (this.uid) {

      this.uploadingCount ++;

      this.store.images.uploadNewImage({
      
        uid: this.uid, type: this.props.type, data: file, ext: file.name.replace(/.*\.([^.]+)$/, '$1')

      }).then(action(r => {

        const image = r.image;

        r.progress(action((percent, complete) => {
          this.progress = percent;
          this.props.onProgress && this.props.onProgress(r.image.id, percent, complete);
        }));

        return r.url(action(url => {
          console.log('finished', url)
          this.progress = null;
          this.props.onUpload({image, url});
          this.uploadingCount--;
        }));

        

      }))
        .catch(e=>{
          this.uploadingCount--;
          this.store.error.captureException(e);
          this.store.alert.error([e.message], 'Failed to upload')
        })
    }
  }
}

export const ImageUloadInput = observer(function ImageUloadInput(props: ImageUloadInputProps) {
  const store = useStore();
  const self = useController(()=>new ImageUloadInputController(props, store), props);
  return <div className={classNames('ImageUloadInput relative', props.className)}>
    <FileDropzoneInput
      onDrop={self.onDrop}
      accept={props.accept}
      multiple={props.multiple}
      disabled={props.disabled}
    />
    <SpinnerOverlay show={self.uploadingCount > 0} text={typeof self.progress === 'number' ? self.progress + '%' : null}/>
  </div>
});
