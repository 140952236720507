import { guardOnly, isNull, isNumber, isValid, keys } from '@root/shared/lib/x';
import classNames = require('classnames');
import { ClassValue } from 'classnames/types';
import React = require('react');

type MouseOverProps = React.PropsWithChildren<{
  className?: ClassValue
  contentClassName?: ClassValue
  content?: React.ReactNode
  toggle?: 'hover' | 'click'
}>

export const MouseOver = function MouseOver(props: MouseOverProps) {
  const nodeRef = React.useRef<HTMLDivElement>(null);
  const popupRef = React.useRef<HTMLDivElement>(null);
  const [ overNode, setOverNode ] = React.useState<boolean>(false);
  const [ overPopup, setOverPopup ] = React.useState<boolean>(false);

  const [ pinned, setPinned ] = React.useState<boolean>(false);
  
  type Rect = {
    top: number;
    left: number;
    bottom: number;
    right: number;
  }

  type Placement = {
    rect: Rect
    offsetX: number;
    offsetY: number;
  };

  const [ placement, setPlacement ] = React.useState<Placement | null>(null);

  const x = placement && placement.rect.left > placement.rect.right ? 'right' : 'left';
  const y = placement && placement.rect.top > placement.rect.bottom ? 'bottom' : 'top';

  React.useEffect(() => {
    if (!overPopup && !overNode && !pinned) {
      setPlacement(null);
      return;
    }
    if (placement) return;

    const int = setInterval(() => {

      if (placement) return clearInterval(placement);

      const _rect = nodeRef.current?.getBoundingClientRect().toJSON();
      
      const scrollTop = nodeRef.current?.scrollTop;
      const scrollLeft = nodeRef.current?.scrollLeft;

      if (!_rect || scrollTop === undefined || scrollLeft === undefined) return;
      const rect: Rect = {..._rect};
      
      rect.top += scrollTop;
      rect.left += scrollLeft;
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      
      rect.right = vw - rect.left;
      rect.bottom = vh - rect.top;
      const nudge = (n?: number) => n ? n - 6 : n
      const next = {
        offsetX: nudge(nodeRef.current?.clientWidth),
        offsetY: nudge(nodeRef.current?.clientHeight),
        rect: rect,
      };
      
      if (guardOnly(isValid<Placement>({
        offsetX: isNumber, offsetY: isNumber,
        rect: guardOnly(isValid<Rect>({
          top: isNumber, left: isNumber, right: isNumber, bottom: isNumber
        }))
      }))(next)) {
        setPlacement(next);
      }

    }, 50);
    return () => clearInterval(int);
  }, [placement, overNode, overPopup, pinned]);

  const toggle = props.toggle || 'hover';

  return <div style={{
    overflow: 'visible',
    position: 'relative',
    }} 
    onClick={toggle=='click' ? e => {e.preventDefault(); setOverNode(!overNode)} : undefined}
    onMouseEnter={toggle=='hover' ? () => setOverNode(true) : undefined} 
    onMouseLeave={toggle=='hover' ? () => setOverNode(false) : undefined} 
    ref={nodeRef} 
    className={classNames('MouseOver', props.className)}>
    {props.children}
    {
      (overNode || overPopup || pinned) && props.content && 
      <div 
        className={classNames(props.contentClassName)}
        style={{
          maxHeight: '50vh',
          opacity: placement ? 1 : 0,
          position: 'absolute', 
          [x]: placement ? placement.offsetX + 'px' : 0, 
          [y]: placement ? placement.offsetY + 'px' : 0, 
          zIndex: 9999
        }}
        onClick={e => {e.preventDefault(); e.stopPropagation(); if (toggle=='hover') setPinned(!pinned)}} // get it "stuck" until hovers again
        onMouseEnter={() => { if (toggle=='hover') {setOverPopup(true); } }} onMouseLeave={() => { if (toggle=='hover') setOverPopup(false) }} ref={popupRef}
        >
        {props.content}
      </div>
      
    }
  </div>
}