/**
 * The generic string input component. While it can be used standalone, it is meant to be
 * extended by higher order components. E.g. @see {TextInput}.
 */
import cx = require('classnames');
import React = require('react');
import { ClassValue } from 'classnames/types';
// import { randomId } from '../../util/util';
import { action, values } from 'mobx';
import { InputProps } from './inputTypes';
import { randomId } from '@root/shared/lib/x';
import classNames = require('classnames');

export type StringInputProps = {
  value: string
  filter?: (s: string)=>(string|undefined)
  type: InputType
  /**
   * the `id` attribute of the <input> element. If not provided, will be randomly generated.
   */
  inputId?: string

  onChange?: (t: string|undefined)=>void
  label?: string
  redAsterisk?: boolean
  error?: string | null | undefined
  errorFunc?: (t: string|undefined)=>string|null|undefined
  help?: string
  hint?: string
  readOnly?: boolean
  disabled?: boolean
  placeholder?: string
  unitsPrefix?: string
  unitsPostfix?: string
  className?: ClassValue
  labelClassName?: ClassValue
  inputClassName?: ClassValue
  dirty?: boolean
  maxLength?: number
  ref?: React.Ref<HTMLInputElement>
  autoFocus?: boolean
} & InputProps & React.PropsWithRef<{}>
export type InputType 
  = `text`
  // | `checkbox`
  // | `radio`
  // | `file`
  | `password`
  | `search`
  | `email`
  | `url`
  | `tel`
  | `number`
  | `range`
  | `date`
  | `month`
  | `week`
  | `time`
  | `datetime`
  | `datetime-local`
  | `color`;
export const StringInput = React.forwardRef<HTMLInputElement, StringInputProps>(function StringInput(props: StringInputProps, ref) {

  const value = props.value;
  const filter = (val: string) => {
    const next = props.filter ? props.filter(val) : val;
    return next && props.maxLength ? next.substr(0, props.maxLength) : next;
  }
  

  const prefixRef = React.useRef<HTMLDivElement>(null);
  const postfixRef = React.useRef<HTMLDivElement>(null);

  const [ dirty, setDirty ] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!props.dirty) setDirty(false);
  }, [props.dirty]);

  const showError = dirty || props.dirty;
  const error = showError && (props.error || props.errorFunc && props.errorFunc(value));

  const onChange = (v: string)=>{
    props.onChange && action(props.onChange)(filter(v));
    setDirty(true)
  }
  
  const [ inputId ] = React.useState<string>(()=>props.inputId || randomId({length: 8}));
  
  const [ inputStyle, setInputStyle ] = React.useState<React.CSSProperties|undefined>(undefined);

  React.useEffect(() => {
    
    const css: React.CSSProperties = {};
    
    if (prefixRef.current) {
      css.paddingLeft = (prefixRef.current.clientWidth + 3) + 'px';
    }
    
    if (postfixRef.current) {
      css.paddingRight = (postfixRef.current.clientWidth + 3) + 'px';
    }

    setInputStyle(css);

  }, [prefixRef.current && prefixRef.current.clientWidth, postfixRef.current && postfixRef.current.clientWidth]);


  return <div className={cx('StringInput', props.className)}>
    {
      props.label && 
      <div className="flex justify-between items-center mr-2 my-1">
        <label htmlFor={inputId} className={classNames("block text-sm font-medium leading-5 text-gray-700", props.labelClassName)}>{props.label}{props.redAsterisk && <span className="text-red-400 ml-1">*</span>}</label>
        { props.hint && <span className="text-sm leading-5 text-gray-500" id="email-optional">{props.hint}</span>}
      </div>
    
    }  
    <div className="relative rounded-md shadow-sm w-full">
      {
        props.unitsPrefix && 
        <div ref={prefixRef} className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm sm:leading-5">
            {props.unitsPrefix}
          </span>
        </div>
      }
      <input 
        type={props.type}
        autoFocus={props.autoFocus}
        value={props.value} 
        readOnly={props.readOnly} 
        disabled={props.disabled}
        onChange={props.readOnly ? undefined : e => onChange(e.target.value) }
        id={inputId} 
        style={inputStyle}
        ref={ref}
        className={cx([
          "form-input block w-full sm:text-sm sm:leading-5",
          {
            "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red": !!error,
            "text-gray-500 cursor-not-allowed": !!props.disabled,
            // "pr-10": !!props.
          },
          props.inputClassName
        ])} 
        placeholder={props.placeholder} 
        aria-invalid={!!props.error} 
        aria-describedby={error ? props.type + "-error" : ''} 
      />
      {
        !error && props.unitsPostfix && 
        <div ref={postfixRef} className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm sm:leading-5">
            {props.unitsPostfix}
          </span>
        </div>
      }
      {
        error && 
        <div style={{boxShadow: '-2px 0px 1px 2px white'}} className="absolute inset-y-0 right-0 m-1.5 pr-2 pl-1 py-2 mr-1 ml-1 mt-1 mb-1 flex items-center pointer-events-none bg-white">
          <svg className="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
          </svg>
        </div>
      }

      {
        !!error && <p style={{top: '-0.5rem'}} className="text-right text-xs relative z-10 text-red-600 pr-3 h-0 max-w-full overflow-visible">
          <span className="bg-white p-1">{error}</span>
        </p>
      }
    </div>
    {
      props.help && <p className="mt-2 text-sm text-gray-500">{props.help}</p>
    }
    
  </div>
})
