
import * as React from 'react';

import { observer } from "mobx-react-lite"
import { useStore } from "../store/StoreContext"
import Spinner = require('react-spinkit');
import { Login } from "./Login";
import { dateFromStamp } from '../util/util';


import ebayLogo from '../../../../img/ebay-logo-256.png';
import orderDeskLogo from '../../../../img/order-desk-logo.png';
import { ConnectionListItem } from './kit/ConnectionListItem';
import { errorToString, notVoid } from '@root/shared/lib/x';
import { TextInput } from './kit/TextInput';
import { MainStore } from '../store/store';
import { useController } from '../util/mobxUtils';
import { action, autorun, computed, observable, reaction, toJS } from 'mobx';
import { FirewardOutput, FirewardInput, isTimestamp, OrderDeskAccount } from '@root/shared/lib/database';
import odHelpImg from '../../../../img/order-desk-api-keys-ss.jpg'

const orderDeskOutput = (o: OrderDeskAccount<FirewardOutput>): OrderDeskAccount<FirewardInput> => ({
  ...o,
  connectedOnTimestamp: o.connectedOnTimestamp || new Date()
});

class LinkedAccountsPageController {
  
  @observable orderDeskBusy: boolean = false;

  @observable orderDeskForm: Pick<OrderDeskAccount, 'apiKey' | 'storeId'>

  store: MainStore
  
  constructor(store: MainStore) {
    this.store = store;
    this.orderDeskForm = this.getOrderDeskAccount(store.orderDesk.account);

    reaction(() => this.store.orderDesk.account, (acct) => {
      this.orderDeskForm = this.getOrderDeskAccount(acct);
    }, {fireImmediately: false})
    
  }
  getOrderDeskAccount = (prev: OrderDeskAccount<FirewardOutput>|null|undefined): Pick<OrderDeskAccount, 'apiKey' | 'storeId'> => {
    return prev ? orderDeskOutput(prev) : {
      apiKey: '', 
      // connectedOnTimestamp: this.store.fieldValue.serverTimestamp(),
      storeId: ''
    };
  }
  redirectToDropbox = () => {
    const store = this.store
    return store.dropbox.redirectToAuth().catch(e=>{
      store.error.captureException(e, {extra: {place: 'redirectToDropbox'}})
      store.alert.error([errorToString(e)], 'DropBox error')
    })
  }
  @computed get orderDeskConnectedDate() {
    return dateFromStamp(this.store.orderDesk.account?.connectedOnTimestamp)
  }
  @computed get orderDeskLoading() {
    return this.store.orderDesk.account === undefined;
  }
  @computed get uid() {
    return this.store.user.user?.uid
  }
  @action setOrderDeskStoreId = (val: string|undefined) => {
    this.orderDeskForm.storeId = notVoid(val) ? val : ''
  }
  @action setOrderDeskStoreAPIkey = (val: string|undefined) => {
    this.orderDeskForm.apiKey = notVoid(val) ? val : ''
  }
  @action connectOrderDesk = () => {
    const uid = this.store.user.state?.uid;
    if (!uid) return Promise.reject(new Error(`Not logged in.`));

    this.orderDeskBusy = true;
    
    const acct = toJS(this.orderDeskForm);
    return this.store.orderDesk.connectAccount(acct, uid)
    .then(action(()=>{
      this.orderDeskBusy = false;
      this.store.alert.success(`OrderDesk connected.`)
    }))
    .catch(action(e => {
      this.orderDeskBusy = false;
      console.error('order desk connect error', e)
      this.store.alert.error([errorToString(e)], `Error connecting to OrderDesk.`);
      return Promise.reject()
    }))
  }
}

export const LinkedAccountsPage:React.StatelessComponent = observer(()=>{
  const store = useStore();
  const self = useController(()=>new LinkedAccountsPageController(store));
  
  if (!self.uid) return <Login/>;
  
  return <div className="LinkedAccountsPage">
    <div>
    
      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <ul>
          <li>
            <ConnectionListItem
              allowReconnect={true}
              loading={store.dropbox.redirecting || store.ebay.data===undefined}
              logo={<svg className="maestro-nav__logo" aria-label="Home" xmlns="http://www.w3.org/2000/svg" role="img" width="100%" height="100%" viewBox="0 0 32 32" ><title></title><path d="M8 2.4l8 5.1-8 5.1-8-5.1 8-5.1zm16 0l8 5.1-8 5.1-8-5.1 8-5.1zM0 17.7l8-5.1 8 5.1-8 5.1-8-5.1zm24-5.1l8 5.1-8 5.1-8-5.1 8-5.1zM8 24.5l8-5.1 8 5.1-8 5.1-8-5.1z"></path></svg>}
              name="DropBox"
              onConnectAction={self.redirectToDropbox}
              connectedDate={store.dropbox.data && dateFromStamp(store.dropbox.data.connectedOnTimestamp)}
            />
          </li>

          <li className="border-t border-gray-200">
            <ConnectionListItem 
              allowReconnect={true}
              loading={store.ebay.redirecting || store.ebay.data===undefined}
              logo={ebayLogo}
              name="eBay"
              connectedDate={store.ebay.data && dateFromStamp(store.ebay.data.connectedOnTimestamp)}
              onConnectAction={store.ebay.redirectToAuth}
            />
          </li>

          <li className="border-t border-gray-200">
            <ConnectionListItem 
              allowReconnect={true}
              loading={self.orderDeskLoading || self.orderDeskBusy}
              logo={orderDeskLogo}
              name="OrderDesk"
              connectedDate={self.orderDeskConnectedDate}
              connectForm={
                <div>
                    <h3 className="text-sm label">Store Access Credentials</h3>
                    
                    <img className="w-full" src={odHelpImg} />

                    <TextInput 
                      maxLength={255} 
                      onChange={self.setOrderDeskStoreId}
                      value={self.orderDeskForm.storeId}
                      label="Store ID"
                    />

                    <TextInput 
                      maxLength={255} 
                      onChange={self.setOrderDeskStoreAPIkey}
                      value={self.orderDeskForm.apiKey}
                      label="API Key"
                    />
                   
                </div>
              }
              onSaveForm={self.connectOrderDesk}
            />
          </li>

        </ul>
      </div>
    </div>
  </div>
})
