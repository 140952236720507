import { MainStore } from "../store/store";
import React = require("react");
import { Spinner } from "./kit/Spinner";
// import { Link } from "react-navi";
import { Link, useRouting } from "../routing/routes";
import { LinkText } from "./kit/LinkText";
import { keys, clone } from "@root/shared/lib/x";

export const SetupIncompleteMsg = function SetupIncompleteMsg(store: MainStore, routing: ReturnType<typeof useRouting>) {
  
  const policies = store.ebay.policies;
  // if (policies) console.log('data', clone(policies), keys(policies).length, keys(policies).some(k => policies[k].length === 0))
  
  const noPolicies = !policies || keys(policies).some(k => policies[k].length === 0) || keys(policies).length < 3;
  
  const setupIncompleteMsg = 
    store.ebay.data && !noPolicies
    ? null
    : store.ebay.data === undefined || store.ebay.policies === undefined
    ? <Spinner text="loading eBay data" />
    : !store.ebay.data
    ? <div>Please <Link href={routing.linkedAccounts.href()}><LinkText>link your ebay account</LinkText></Link> first</div>
    : noPolicies
    ? <div>Please set up all 3: shipping, payment and refund policies before creating products. You can do so by clicking this link: <a target="_blank" href={store.ebay.policySetupUrl}><LinkText>{store.ebay.policySetupUrl}</LinkText></a></div>
    : null
    ;
  return setupIncompleteMsg
}
