import { UserStore } from "./UserStore";
import { ErrorStore } from "./ErrorStore";
import { AlertStore } from "./AlertStore";
import { WebDependencies } from "../WebDependencies";
import { authAutorun } from "./storeUtils";
import { queryAction, snapData, upsertChanges } from "../util/util";
import { hasString, hasKey, Assign, Model, errorToString, isValid, isLiteral, isArrayOf, isArray, isString, isNumber, notVoid, isBoolean } from "@root/shared/lib/x";
import { APP_VERSION } from "@root/shared/lib/schemas/constants";
import { observable, action, computed } from "mobx";

import { Product, FirewardInput, FirewardOutput, ProductSKU, ProductCanvas, PositioningRule, ProductColorOption, ProductImage, ProductCanvasPositioning, SKUAlgorithm, PrintProvider } from "@root/shared/lib/database";
import { NewProduct, NoSentinelOutput } from "../../../shared/lib/schemas/productGuards";
import { SizePriceModel } from "../components/BySizePriceInput";


export type ManualSKUModel = Assign<ProductSKU<NoSentinelOutput>, {sku?: string}>

export type ProductCanvasModel = Assign<Model<ProductCanvas<NoSentinelOutput>>, {
  id: string,
  positioningRule: PositioningRule
}>

export type ProductModel = Assign<Model<Product<NoSentinelOutput>>, {
  skuAlgorithm: SKUAlgorithm,
  sizes: SizePriceModel[],
  onePrice?: number
  dims: {width?: number, height?: number, depth?: number}
  manualSkus: ManualSKUModel[]
  canvases: ProductCanvasModel[]
  colors: ColorOptionsModel[]
  images: ProductImageModel[]
  irregularPackage: boolean
  specifics: {name: string, value?: string, required: boolean}[]
  shipFromPostalCode: string
  shipFromLocation: string
  printProvider: PrintProvider<FirewardInput> | null
  previewCanvasOptions: {
    canvasId: string|null
    positioning: ProductCanvasPositioning<FirewardOutput>|null
  }
  printLocation: string
  ebayTitle: string
}>

const validateSizePriceModel = isValid<SizePriceModel>({
  id: isString,
  price: [isNumber],
  size: [isString]
});
const validateProductCanvasModel = isValid<ProductCanvasModel>({
  id: isString,
  height: [isNumber],
  // positioning: [isValid<ProductCanvasModel['positioning']>({
  //   left: isNumber,
  //   top: isNumber,
  //   scale: isNumber,    
  // })],
  productSizeIds: isArrayOf(isString),
  positioningRule: isLiteral<PositioningRule>({"top-11.5":1, "top-12":1, "top-12.5":1, "top-13":1, center:1, top:1, mirrored: 1}),
  width: [isNumber]
})
// export const validateProductModel = isValid<ProductModel>({
//   skuAlgorithm: isLiteral<SKUAlgorithm>({manual: 1, shirts: 1}),
//   sizes: isArrayOf(validateSizePriceModel),
//   canvases: isArrayOf(validateProductCanvasModel),
//   categoryId: [isNumber],
//   onePrice: [isNumber],
//   code: [isString],
//   description: [isString],
//   dims: isValid<ProductModel['dims']>({width: [isNumber], height: [isNumber], depth: [isNumber]}),
//   colors: isArrayOf(isValid<ProductModel['colors'][0]>({
//     id: isString,
//     code: [isString],
//     imagePath: [isString],
//     name: [isString],
//     sizeIds: isArrayOf(isString)
//   })),
//   archived: [isBoolean],
//   colorSpecificName: [isString],
//   images: isArrayOf(),
//   irregularPackage: [isBoolean],
//   manualSkus: isArrayOf(isValid<ProductModel['manualSkus']>({

//   })),
//   name: isString,
//   packageType: isString,
//   previewCanvas: isValid<ProductModel['previewCanvas']>({}),
//   pricing: ''

// })
type ModelCache<T> = {model?: T, appVersion?: string}

export type ColorOptionsModel = Assign<Model<ProductColorOption<NoSentinelOutput>>, {id: string}>

export type ProductImageModel = Assign<Model<ProductImage<NoSentinelOutput>>, {
  id: string, path: string
}>

export class ProductStore {

  private readonly user: UserStore
  private readonly error: ErrorStore

  private readonly alert: AlertStore
  private readonly deps: WebDependencies;

  @observable private _products: Product<FirewardOutput>[]|null = null


  constructor(deps: WebDependencies, user: UserStore, error: ErrorStore, alert: AlertStore) {
    this.deps = deps;
    this.user = user;
    this.error = error;
    this.alert = alert;

    user.onAuth( uid => {
      this._products = null;
      
      if (uid) {
        return this.productCollRef(uid)
          .where('archived', '==', false)
          .onSnapshot(queryAction(snap => {
              this._products = this._products || [];
              upsertChanges(this._products, snap, p => p.productId)
            }),
            e => {
              error.captureException(e, {tags: {place: `list products`}});
              alert.error([e.message]);
            }
          )
      } 
    });
  }

  getProduct = (productId: string, userId: string) => {
    return this.productCollRef(userId).doc(productId).get()
      .then(snap => snapData<Product<FirewardOutput>>(snap))
  }
  updateProduct = (update: Partial<Product<FirewardInput>> & {productId: string}, uid: string) => {
    return this.productRef(uid, update.productId).update(update)
  }

  @action public async saveProduct(userId: string, product: Product<FirewardInput>|NewProduct) {
    
    const ref = this.productRef(userId, hasKey(product, 'productId') ? product.productId : undefined);

    const obj: Product<FirewardInput> = hasKey(product, 'productId') ? product : {
      ...product, productId: ref.id,
      shipFromPostalCode: product.shipFromPostalCode || '', // this is to account for old transitional data schema that didn't require the postal code
      shipFromLocation: product.shipFromLocation || null
    }

    return ref.set(obj)
      .then(action(() => {
        return obj;
      }))
      .catch(action(e=>{
        return Promise.reject(e)
      }))
  }

  @computed get productList() {
    return this._products
  }
  
  
  getArchivedProducts = (uid: string) => {
    return this.productCollRef(uid).where('archived', '==', true)
      .get().then(snaps => snaps.docs.map(snap => snapData<Product<FirewardOutput>>(snap)).filter(notVoid))
  }
  
  private productRef(uid: string, productId?: string) {
    // if productId is undefined, Firestore will generate a new id
    return productId ? this.productCollRef(uid).doc(productId) : this.productCollRef(uid).doc()
  }


  private productCollRef(userId: string) {
    return this.deps.firebase.firestore().collection('users')
    .doc(userId).collection(`products`)
  }


  saveModelToStorage = (model: ProductModel) => {
    const cache: ModelCache<ProductModel> = {model, appVersion: APP_VERSION};
    const data = JSON.stringify(cache);
    this.deps.localStorage.setItem(this.getStorageKey(model.productId || null), data);
  }
  deleteModelStorage = (id: string|null) => {
    this.deps.localStorage.removeItem(this.getStorageKey(id))
  }
  getModelFromStorage = (id: string|null): ProductModel | null => {
    
    const str = this.deps.localStorage.getItem(this.getStorageKey(id));

    if (!str) return null;
    try {
      const json = str ? JSON.parse(str) as ModelCache<ProductModel> : null;
      if (json?.appVersion !== APP_VERSION) {
        this.deleteModelStorage(id);
        return null;
      }
      return json?.model || null;
    } catch (error) {
      this.deleteModelStorage(id);
      console.log('failed to parse localStorage.product', error)
      return null;
    }
  }

  private getStorageKey(id: string | null): string {
    return 'product-' + (id || 'new');
  }
}
