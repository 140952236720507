import { UserStore } from "./UserStore";
import { ErrorStore } from "./ErrorStore";
import { AlertStore } from "./AlertStore";
import { WebDependencies } from "../WebDependencies";
import { authAutorun } from "./storeUtils";
import { queryAction, snapAction, snapData, upsertChanges } from "../util/util";
import { observable, autorun, action, computed } from "mobx";
import { ProductGroup, FirewardOutput, FirewardInput, isTimestamp, ProductListing, ProductDesign, OrderDeskAccount } from "@root/shared/lib/database";
import { tryJson, isValidationMapError, Assign, clone, filterUndefined } from "@root/shared/lib/x";
import {isProductGroup, isProductGroupModel, ProductGroupModel, validateGroupModel} from '@root/shared/lib/schemas/productGroupGuards'
import { ModelStorage } from "./store";
import { isFieldValue } from "@root/shared/lib/firex";
import type { firestore } from 'firebase'
import { NoSentinelOutput } from "../../../shared/lib/schemas/productGuards";




export class OrderDeskStore {
  private readonly auth: UserStore
  private readonly error: ErrorStore

  private readonly alert: AlertStore
  private readonly deps: WebDependencies;
  @observable private _account: OrderDeskAccount<FirewardOutput> | null | undefined;

  constructor(deps: WebDependencies, auth: UserStore, error: ErrorStore, alert: AlertStore) {

    this.deps = deps;
    this.auth = auth;
    this.error = error;
    this.alert = alert;

    auth.onAuth( uid => {
      this._account = undefined;
      if (uid) {
        return this.orderDesk$(uid).onSnapshot(snapAction(snap => {
          this._account = snapData<OrderDeskAccount<FirewardOutput>>(snap)
        }));
      } else this._account = null;
    })

  }
  private user$ = (uid: string) => {
    return this.deps
      .firebase.firestore()
      .collection('users').doc(uid);
  }


  private orderDesk$(uid: string) {
    return this.deps.firebase.firestore().collection('order-desk-accounts').doc(uid);
  }

  @computed({keepAlive: true}) get account() {
    return this._account;
  }

  connectAccount = (credentials: Pick<OrderDeskAccount, 'apiKey' | 'storeId'>, uid: string) => {
    console.log('connecting to order desk', credentials)
    return this.deps.fireCall('connectOrderDesk', {credentials})
  }

}
