import { ebayTradingListingDurations } from "@root/shared/lib/api/ebayTypes";
import { FirewardOutput, FirewardInput, PrintProvider, StandardSettings } from "@root/shared/lib/database";
import { isArrayOf, isLiteral, isNumber, isString, isValid, validateNonEmptyString } from "@root/shared/lib/x";
import { validatePrintProvider } from "@root/shared/lib/schemas/productGuards";
import { computed, observable } from "mobx";
import { string } from "mobx-state-tree/dist/internal";
import { snapAction, snapData } from "../util/util";
import { WebDependencies } from "../WebDependencies";
import { ErrorStore } from "./ErrorStore";
import { UserStore } from "./UserStore";

export const validateStandardSettings = isValid<StandardSettings<FirewardInput>>({
  description: isValid<StandardSettings<FirewardInput>['description']>({
    contactUs: isString, feedback: isString
  }),
  ebayListingDuration: isLiteral(ebayTradingListingDurations),
  paymentPolicy: isValid<{name: string, ebayPolicyId: number}>({name: isString, ebayPolicyId: isNumber}),
  returnPolicy: isValid<{name: string, ebayPolicyId: number}>({name: isString, ebayPolicyId: isNumber}),
  providers: [isArrayOf(validatePrintProvider, 1)]
});

export class SettingsStore {
  
  @observable private _standardSettings: StandardSettings<FirewardOutput>|null|undefined;
  
  private deps: WebDependencies
  private userStore: UserStore
  
  constructor(deps: WebDependencies, user: UserStore, error: ErrorStore) {
    
    this.deps = deps;
    this.userStore = user;

    user.onAuth( uid => {
      this._standardSettings = undefined
      if (uid) return this.standardSettingsRef(uid).onSnapshot(snapAction(snap => {
          this._standardSettings = snapData<StandardSettings<FirewardOutput>>(snap);
        }));
      else this._standardSettings = null;
    });
  }

  @computed get standardSettings() {
    return this._standardSettings;
  }

  getStandardSettings = async () => {
    const uid = await this.userStore.getUserId()
    const snap = await this.standardSettingsRef(uid).get();
    return snapData<StandardSettings<FirewardOutput>>(snap)
  }

  private standardSettingsRef(uid: string) {
    return this.deps.firebase.firestore()
      .collection('standard-settings').doc(uid);
  }

  saveStandardSettings = (value: StandardSettings<FirewardInput>, uid: string) => {

    return this.standardSettingsRef(uid).set(value, {merge: true})
  }
}