import { observer } from "mobx-react-lite";
import { useStore } from "../store/StoreContext";
import { Transition } from "../util/Transition";
import React = require("react");
import cx = require('classnames');
// import {Link} from 'react-navi';
import { Link, useRouting } from "../routing/routes";
import { User } from "@root/shared/lib/database";
import classNames = require("classnames");
import { Icon } from "./kit/icons";

export type DrawerSidebarProps = {
  isOpen: boolean,
  onClose?: ()=>void
}
const Logo = ()=>{
  const routing = useRouting();
  return <>
    <Link href={routing.home.href()}>
      <strong>mass</strong>DROP
    </Link>

  </>
}
type UserMenuItem = {
  label: string
  onClick: ()=>void
}
const displayName = (user: User, auth: firebase.User) => 
  user.firstName && user.lastName
  ? user.firstName + ' ' + user.lastName
  : user.firstName || user.lastName
  ? user.firstName || user.lastName
  : auth.displayName || 'Anonymous'

const UserMenu = (props: {user: User, auth: firebase.User, show: boolean, onToggle: (show: boolean)=>void, items: UserMenuItem[][]}) => {
  return <div className="UserMenu w-full relative">
    <div
      onClick={e => { props.onToggle(!props.show); e.preventDefault(); } }
      className={classNames([
        "fixed inset-0 w-full h-full z-10",
        {
          'hidden': !props.show
        }
      ])}
    />
    <div onClick={e => { props.onToggle(!props.show); e.preventDefault(); } }>
      <button type="button" className="group w-full rounded-md px-3.5 py-2 text-sm leading-5 font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-500 focus:outline-none focus:bg-gray-200 focus:border-blue-300 active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150" id="options-menu" aria-haspopup="true" aria-expanded="true">
        <div className="flex w-full justify-between items-center">
          <div className="flex items-center justify-between space-x-3">
            <img className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0" src={props.user.auth.photoURL} alt=""/>
            <div className="flex-1">
              <h2 className="text-gray-900 text-sm leading-5 font-medium">{
                displayName(props.user, props.auth)
              }</h2>
              <p className="text-gray-500 text-xs leading-5 truncate">{props.user.auth.uid.substr(0,7)}</p>
            </div>
          </div>
          <svg className="h-5 w-5 text-gray-400 group-hover:text-gray-500" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </div>
      </button>
    </div>
    {/* <!--
      Dropdown panel, show/hide based on dropdown state.

      Entering: "transition ease-out duration-100"
        From: "transform opacity-0 scale-95"
        To: "transform opacity-100 scale-100"
      Leaving: "transition ease-in duration-75"
        From: "transform opacity-100 scale-100"
        To: "transform opacity-0 scale-95"
    --> */}
    <Transition
      show={props.show}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <div className="z-10 mx-3 origin-top absolute bottom-0 right-0 left-0 mt-1 rounded-md shadow-lg">
        <div className="rounded-md bg-white shadow-xs" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          {
            props.items.map( (section, i) => <div className="pt-1" key={i}>
              {
                section.map( (item, i) => 
                  <span key={i} onClick={()=>{ props.onToggle(false); item.onClick();}} className="cursor-pointer block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                    {item.label}
                  </span>
                )
              }
              {i < props.items.length - 1 && <div className="border-b mt-1 border-gray-100"></div>}
            </div>)
          }
        </div>
      </div>
    </Transition>
  </div>
  
}
export const StaticSidebar: React.FunctionComponent = observer(props => {
  
  const store = useStore();
  const routing = useRouting();

  const [ userMenu, setUserMenu ] = React.useState<boolean>(false);
  return <div className="hidden md:flex md:flex-shrink-0">
    <div className="flex flex-col w-64 border-r border-gray-200 bg-white">
    <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
        <div className="flex-shrink-0 flex items-center px-4">
          {/* <img className="h-8 w-auto" src="/img/logos/workflow-logo-on-white.svg" alt="Workflow" /> */}
          <Logo/>
        </div>
        <nav className="mt-5 px-2">
          <Link href={routing.home.href()} className="group flex items-center px-2 py-2 text-base leading-6 font-medium text-gray-900 rounded-md bg-gray-100 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150">
            <svg className="mr-4 h-6 w-6 text-gray-500 group-hover:text-gray-500 group-focus:text-gray-600 transition ease-in-out duration-150" fill="none" viewBox="0 0 20 20"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
            Home
          </Link>
          {/* <Link href={routing.userAccount.href()} className="mt-1 group flex items-center px-2 py-2 text-base leading-6 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-100 transition ease-in-out duration-150">
            <Icon name="user" color="secondary" size={6} shade={400} className="mr-4 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150" />
            Account Info
          </Link> */}
          <Link href={routing.standardSettings.href()} className="mt-1 group flex items-center px-2 py-2 text-base leading-6 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-100 transition ease-in-out duration-150">
            <Icon name="adjustmentsOutline" color="secondary" size={6} shade={400} className="mr-4 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150" />
            Standard Settings
          </Link>
          <Link href={routing.linkedAccounts.href()} className="mt-1 group flex items-center px-2 py-2 text-base leading-6 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-100 transition ease-in-out duration-150">
            <svg className="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"/>
            </svg>
            Linked Accounts
          </Link>
          <Link href={routing.ebayRegisteredCategories.href()} className="mt-1 group flex items-center px-2 py-2 text-base leading-6 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-100 transition ease-in-out duration-150">
            <svg className="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"/>
            </svg>
            Ebay Categories
          </Link>
          <Link href={routing.products.href()} className="mt-1 group flex items-center px-2 py-2 text-base leading-6 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-100 transition ease-in-out duration-150">
            <Icon name="giftOutline" color="secondary" size={6} shade={400} className="mr-4 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150" />
            Products
          </Link>
          <Link href={routing.productGroups.href()} className="mt-1 group flex items-center px-2 py-2 text-base leading-6 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-100 transition ease-in-out duration-150">
            <Icon name="collectionOutline" color="secondary" size={6} shade={400} className="mr-4 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150" />
            Product Groups
          </Link>
          <Link href={routing.designQueue.href()} className="mt-1 group flex items-center px-2 py-2 text-base leading-6 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-100 transition ease-in-out duration-150">
            <Icon name="clock" color="secondary" size={6} shade={400} className="mr-4 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150" />
            Design Upload Queue
          </Link>
          <Link href={routing.pendingListings.href()} className="mt-1 group flex items-center px-2 py-2 text-base leading-6 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-100 transition ease-in-out duration-150">
            <Icon name="clipboardListOutline" color="secondary" size={6} shade={400} className="mr-4 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150" />
            Pending Listings
          </Link>
        </nav>
      </div>
      <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
        
            {
              store.user.user && store.user.state
              ? <div className="ml-3 w-full">
                  <UserMenu user={store.user.user} auth={store.user.state} show={userMenu} onToggle={setUserMenu} items={[[{label: 'Account Info', onClick: () => routing.userAccount.navigate()}, {label: 'Logout', onClick: store.user.logout}]]} />
                </div>
              : <div className="ml-3">
                  <p className="text-xs leading-4 font-medium text-gray-500 group-hover:text-gray-700 transition ease-in-out duration-150">
                    Not logged in
                  </p>
                </div>
            }
      </div>
    </div>
  </div>
})
export const DrawerSidebar: React.FunctionComponent<DrawerSidebarProps> = observer(props => {
  
  const store = useStore();
  const isOpen = props.isOpen;
  const routing = useRouting();
  const [ userMenu, setUserMenu ] = React.useState<boolean>(false);
  return <div className={cx(["md:hidden", {hidden: false &&!isOpen}])}>
    <div className="fixed top-0 left-0 right-0 z-40">
      {/* <!--
        Off-canvas menu overlay, show/hide based on off-canvas menu state.

        Entering: "transition-opacity ease-linear duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "transition-opacity ease-linear duration-300"
          From: "opacity-100"
          To: "opacity-0"
      --> */}
      <Transition
        name="menu-backdrop"
        show={isOpen}
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        timeout={300}
      >
        <div onClick={props.onClose} className="fixed inset-0 zebra">
          <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
        </div>
      </Transition>
      {/* <!--
        Off-canvas menu, show/hide based on off-canvas menu state.

        Entering: "transition ease-in-out duration-300 transform"
          From: "-translate-x-full"
          To: "translate-x-0"
        Leaving: "transition ease-in-out duration-300 transform"
          From: "translate-x-0"
          To: "-translate-x-full"
      --> */}
      <Transition
        show={isOpen}
        enter="transition ease-in-out duration-300 transform"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
      >
        <div onClick={props.onClose} className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
          <div className="absolute top-0 right-0 p-1">
            <button onClick={props.onClose} className="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600" aria-label="Close sidebar">
              <svg className="h-6 w-6 text-gray-900" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
            <div className="flex-shrink-0 flex items-center px-4">
              <Logo/>
            </div>
            <nav className="mt-5 px-2">
              <Link href={routing.home.href()} className="group flex items-center px-2 py-2 text-base leading-6 font-medium text-gray-900 rounded-md bg-gray-100 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150">
                <svg className="mr-4 h-6 w-6 text-gray-500 group-hover:text-gray-500 group-focus:text-gray-600 transition ease-in-out duration-150" fill="none" viewBox="0 0 20 20"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                Home
              </Link>
              {/* <Link href={routing.userAccount.href()} className="mt-1 group flex items-center px-2 py-2 text-base leading-6 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-100 transition ease-in-out duration-150">
                <Icon name="user" color="secondary" size={6} shade={400} className="mr-4 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150" />
                Account Info
              </Link> */}
              <Link href={routing.standardSettings.href()} className="mt-1 group flex items-center px-2 py-2 text-base leading-6 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-100 transition ease-in-out duration-150">
                <Icon name="adjustmentsOutline" color="secondary" size={6} shade={400} className="mr-4 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150" />
                Standard Settings
              </Link>

              <Link href={routing.linkedAccounts.href()} className="mt-1 group flex items-center px-2 py-2 text-base leading-6 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-100 transition ease-in-out duration-150">
                <svg className="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"/>
                </svg>
                Linked Accounts
              </Link>
              <Link href={routing.ebayRegisteredCategories.href()} className="mt-1 group flex items-center px-2 py-2 text-base leading-6 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-100 transition ease-in-out duration-150">
                <svg className="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"/>
                </svg>
                Ebay Categories
              </Link>
              <Link href={routing.products.href()} className="mt-1 group flex items-center px-2 py-2 text-base leading-6 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-100 transition ease-in-out duration-150">
                <Icon name="giftOutline" color="secondary" size={6} shade={400} className="mr-4 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150" />
                Products
              </Link>
              <Link href={routing.productGroups.href()} className="mt-1 group flex items-center px-2 py-2 text-base leading-6 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-100 transition ease-in-out duration-150">
                <Icon name="collectionOutline" color="secondary" size={6} shade={400} className="mr-4 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150" />
                Product Groups
              </Link>
              <Link href={routing.designQueue.href()} className="mt-1 group flex items-center px-2 py-2 text-base leading-6 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-100 transition ease-in-out duration-150">
                <Icon name="clock" color="secondary" size={6} shade={400} className="mr-4 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150" />
                Design Upload Queue
              </Link>
              <Link href={routing.pendingListings.href()} className="mt-1 group flex items-center px-2 py-2 text-base leading-6 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-100 transition ease-in-out duration-150">
                <Icon name="clipboardListOutline" color="secondary" size={6} shade={400} className="mr-4 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150" />
                Pending Listings
              </Link>
            </nav>
          </div>
          <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                <div className="ml-3 w-full">
                  
                  {
                    store.user.user && store.user.state
                    ? <UserMenu user={store.user.user} auth={store.user.state} show={userMenu} onToggle={setUserMenu} items={[[{label: 'Account Info', onClick: () => routing.userAccount.navigate()}, {label: 'Logout', onClick: store.user.logout}]]} />
                    : <div>Not logged in</div>
                  }
                </div>
              </div>
        </div>
        <div className="flex-shrink-0 w-14">
          {/* <!-- Force sidebar to shrink to fit close icon --> */}
        </div>
      </Transition>
    </div>
      
  </div>
})

export const MainSidebar:React.FunctionComponent<DrawerSidebarProps> = props=>{
  return <>
    <StaticSidebar/>
    <DrawerSidebar isOpen={props.isOpen} onClose={props.onClose} />
  </>
}
