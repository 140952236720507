import { observer } from 'mobx-react-lite';
import * as React from 'react';

import heroFull from '../../../../img/HomePageFull.png';
import heroMobi from '../../../../img/HomePageMobile.png';
import { useStore } from '../store/StoreContext';
import { Login } from './Login';

export const LandingPage = (function LandingPage(props: {}) {
  // const store = useStore();

  // const showLogin = store.user.user === null;

  return <div>

    <img
      className="sm:hidden w-full"
      src={heroMobi}
      style={{maxWidth: 1200, margin: "0 auto"}}
    />

    <img
      className="hidden sm:block w-full"
      src={heroFull}
      style={{maxWidth: 1200, margin: "0 auto"}}
    />


    <Login className="mt-4" />
  </div>
})
