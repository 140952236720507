import React = require('react');
import { observer } from 'mobx-react-lite';
import { observable, action, autorun } from 'mobx';
import { useController } from '../../util/mobxUtils';
import classNames = require('classnames');
import { ClassValue } from 'classnames/types';


export type ToggleInputProps = {
  className?: ClassValue
  value: boolean
  onChange?: (value: boolean)=>void
  disabled?: boolean
}



export const ToggleInput = (function ToggleInput(props: ToggleInputProps) {
  return <span onClick={() => !props.disabled && props.onChange && action(props.onChange)(!props.value)} role="checkbox" tabIndex={0} aria-checked={props.value} className={
                  classNames(
                    'ToggleInput',
                    "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline",
                    {
                      "bg-gray-200": !props.value,
                      "bg-indigo-600": props.value && !props.disabled,
                      "bg-gray-400": props.value && props.disabled
                    },
                    props.className
                  )
            }>
              {/* On: "translate-x-5", Off: "translate-x-0" */}
              <span aria-hidden="true" className={classNames(
                "inline-block h-5 w-5 rounded-full shadow transform transition ease-in-out duration-200",
                {
                  "translate-x-5": props.value,
                  "translate-x-0": !props.value
                },
                props.disabled ? "bg-gray-100" : "bg-white"
              )}></span>
          </span>
});
