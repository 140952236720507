import React = require('react');
import { useStore } from '../store/StoreContext';
import { observer } from 'mobx-react-lite';
import { SpinnerOverlay } from './kit/SpinnerOverlay';
import { Transition } from '../util/Transition';
import { MainSidebar } from './MainSidebar';
// import {useCurrentRoute} from 'react-navi';
import { TopAlert } from './kit/TopAlert';
import { usePathInfo } from '../routing/routes';
// https://heroicons.dev/
/**
 * hamburger
 *               <svg className="mr-4 h-6 w-6 text-gray-500 group-hover:text-gray-500 group-focus:text-gray-600 transition ease-in-out duration-150" fill="none" viewBox="0 0 20 20" stroke="currentColor">
                <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
              </svg>

 */


export const MainLayout:React.StatelessComponent = observer(props=>{
  const store = useStore()
  const [isOpen, toggleOpen] = React.useState(false);
  // const rt = useCurrentRoute();
  const pathInfo = usePathInfo()
  
  return <div className="h-screen flex overflow-hidden bg-white relative">
    {/* <SpinnerOverlay/> */}
    <TopAlert/>
    
    <MainSidebar isOpen={isOpen} onClose={()=>toggleOpen(false)} />
    
    {/* <!-- Static sidebar for desktop --> */}
    
    <div className="flex flex-col w-0 flex-1 overflow-hidden">
      
      <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
        <button onClick={e=>toggleOpen(true)} className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150" aria-label="Open sidebar">
          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"/>
          </svg>
        </button>
      </div>

      <main className="flex-1 relative z-0 overflow-y-auto pt-2 pb-6 focus:outline-none md:py-6" tabIndex={0}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          {
            pathInfo?.title && <h1 className="text-2xl font-semibold text-gray-900">
              {pathInfo?.title}
            </h1>
          }
        </div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          {/* <!-- Replace with your content --> */}
          <div className="py-4">
            <div className="py-3 px-3 border-4 border-dashed border-gray-200 rounded-lg min-h-64">
    
              {props.children}
              
              
            </div>
          </div>
          {/* <!-- /End replace --> */}
        </div>
      </main>
    </div>
  </div>
})
