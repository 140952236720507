import { useState } from "react";
import { hasChild, isString } from "@root/shared/lib/x";

export type ErrState = Error | string;
export const getErrString = (e:unknown)=>{
  if (typeof e==='string') return e;
  if (hasChild('message', isString)(e)) return e.message;
  if (hasChild('text', isString)(e)) return e.text;
  if (hasChild('statusText', isString)(e)) return e.statusText;
  return null;
}
export const useErrorState = (init: unknown|null = null)=>{
  const [ error, setError ] = useState<unknown|null>(init);
  let e:string|null = null;
  if (error) {
    e = getErrString(error);
  }
  return [e, setError] as const;
}
