import { EbayTradingListingDuration } from "../database";

export type EbayStage = 'production' | 'sandbox';

const isArray = (o: unknown): o is Array<unknown> =>
  typeof o === 'object' && !!o && Object.prototype.toString.call(o) === "[object Array]";

function ensureArray<T>(v: T[]|T):T[] {
  if (!v) return [];
  if (!isArray(v)) v = [v];
  return v;
}
function forceArray<T>(v: T[]|T|undefined): T[] {
  return v ? ensureArray(v) : []
}

type EbayTradingErrorItemXml = {
   /** token */
   ErrorCode: string
   // ErrorParameters: Record<string, string>
   ErrorParameters?: ({ParamID: string, value: string}[])|{ParamID: string, value: string}
   
   /** SeverityCodeType */
   SeverityCode: 'Error' | 'Warning'
   /** ErrorClassificationCodeType */
   ErrorClassification: 'RequestError' | 'SystemError'
   
   ShortMessage: string
   LongMessage: string
}

export type EbayTradingErrorItem = {
  /** token */
  ErrorCode: string
  // ErrorParameters: Record<string, string>
  ErrorParameters: {ParamID: string, value: string}[]
  
  /** SeverityCodeType */
  SeverityCode: 'Error' | 'Warning'
  /** ErrorClassificationCodeType */
  ErrorClassification: 'RequestError' | 'SystemError'
  
  ShortMessage: string
  LongMessage: string
}
function ensureEbayTradingErrorItem(item: EbayTradingErrorItemXml): EbayTradingErrorItem {
  return {
    ...item,
    ErrorParameters: item.ErrorParameters ? ensureArray(item.ErrorParameters) : []
  }
}
export type EbayTradingStandardOutput = {
  Build: string
  CorrelationID: string
  HardExpirationWarning?: string
  Timestamp: string
  Version: string

}

/**
 * Convert this into EbayTradingErrorOutput with the ensure func below
 */
type EbayTradingErrorOutputXml = {
  Ack: 'Failure' 
  Errors?: (EbayTradingErrorItemXml[])|EbayTradingErrorItemXml 
} & EbayTradingStandardOutput;

export type EbayTradingErrorOutput = {
  Ack: 'Failure' 
  Errors: EbayTradingErrorItem[] 
} & EbayTradingStandardOutput;


function ensureEbayTradingErrorOutput(item: EbayTradingErrorOutputXml): EbayTradingErrorOutput {
  return {
    ...item,
    Errors: item.Errors ? ensureArray(item.Errors).map(ensureEbayTradingErrorItem) : []
  }
}

export type EbayTradingSuccessOutput<T> ={
  Ack: 'Success' | 'Warning'
} & T & EbayTradingStandardOutput

export function ensureEbayTradingOutput<T>(item: EbayTradingSuccessOutput<T>|EbayTradingErrorOutputXml): EbayTradingSuccessOutput<T>|EbayTradingErrorOutput {
  return item.Ack==='Failure' ? ensureEbayTradingErrorOutput(item) : item;
}
export class EbayTradingError extends Error {
  response: EbayTradingErrorOutput
  constructor(res: EbayTradingErrorOutput, msg = 'eBay responded with errors.') {
    super(msg);
    this.response = res;
  }
}
export const isEbayTradingError = (t:any): t is EbayTradingError => {
  return t && (typeof t === 'object') && isEbayTradingErrorOutput(t.response)
}
export function isEbayTradingErrorOutput<T>(v: EbayTradingErrorOutput|EbayTradingSuccessOutput<T>): v is EbayTradingErrorOutput {
  return v && (typeof v === 'object') && v.Ack==='Failure'
}


export type EbayCategory = {
  AutoPayEnabled: boolean
  B2BVATEnabled: boolean
  BestOfferEnabled: boolean
  CategoryID: number
  CategoryLevel: number
  CategoryName: string
  CategoryParentID: number[]
  Expired: boolean
  LeafCategory: boolean
  LSD: boolean
  ORPA: boolean
  ORRA: boolean
  Virtual: boolean
}

export type ValidationRules = {
  MaxValues?: number
  MinValues?: number
  SelectionMode?: "FreeText" | "SelectionOnly"
  UsageConstraint?: "Required"|"Recommended"|"Optional"
  ValueType?: "Text"
}
export type ValueRecommendation = {
  Value: string
  ValidationRules: ValidationRules
}
export type NameRecommendation = {
  HelpText: string
  Name: string
  ValidationRules: ValidationRules
  ValueRecommendation: ValueRecommendation[]
}
export type EbayCategorySpecific = {
  "Timestamp": "2020-07-13T05:41:59.766Z",
  "Ack": "Success",
  "Version": "1159",
  "Build": "E1159_CORE_API6_19215280_R1",
  "Recommendations": {
    "CategoryID": number,
    NameRecommendation: NameRecommendation[]
  }
}

export const SHIPPING_PACKAGE_TYPES = {
  "BulkyGoods": "Bulky goods",
  "Caravan": "Caravan",
  "Cars": "Cars",
  "CustomCode": "Reserved for internal or future use.",
  "Europallet": "Europallet",
  "ExpandableToughBags": "Expandable Tough Bags",
  "ExtraLargePack": "Extra Large Package/Oversize 3",
  "Furniture": "Furniture",
  "IndustryVehicles": "Industry vehicles",
  "LargeCanadaPostBox": "Large Canada Post Box",
  "LargeCanadaPostBubbleMailer": "Large Canada Post Bubble Mailer",
  "LargeEnvelope": "LargeEnvelope",
  "Letter": "Letter",
  "MailingBoxes": "Mailing Boxes",
  "MediumCanadaPostBox": "Medium Canada Post Box",
  "MediumCanadaPostBubbleMailer": "Medium Canada Post Bubble Mailer",
  "Motorbikes": "Motorbikes",
  "None": "None",
  "OneWayPallet": "Onewaypallet",
  "PackageThickEnvelope": "Package/thick envelope",
  "PaddedBags": "Padded Bags",
  "ParcelOrPaddedEnvelope": "Parcel or padded Envelope",
  "Roll": "Roll",
  "SmallCanadaPostBox": "Small Canada Post Box",
  "SmallCanadaPostBubbleMailer": "Small Canada Post Bubble Mailer",
  "ToughBags": "Tough Bags",
  "UPSLetter": "UPS Letter",
  "USPSFlatRateEnvelope": "USPS Flat Rate Envelope",
  "USPSLargePack": "USPS Large Package/Oversize 1",
  "VeryLargePack": "Very Large Package/Oversize 2",
  "Winepak": "Winepak",
} as const

export type ShippingPackageCodeType = keyof typeof SHIPPING_PACKAGE_TYPES;

export type MeasurementSystemCodeType = 'English' | 'Metric';


// export type EbayTradingListingDuration = keyof typeof ebayTradingListingDurations;

export type EbayFixedPriceItem = {
  ApplicationData?: string,
  AutoPay?: boolean
}

export type EbayTradingListingEnhancement = 
  'BoldTitle' | 'CustomCode' | 'ValuePackBundle';

const getStoreResponse = {
  "Timestamp": "2020-09-30T07:10:55.880Z",
  "Ack": "Success",
  "Version": "1169",
  "Build": "E1169_UNI_API5_19247202_R1",
  "Store": {
    "Name": "Abstract Subject",
    "SubscriptionLevel": "CustomCode",
    "CustomCategories": {
      "CustomCategory": [
        {
          "CategoryID": "1",
          "Name": "Other",
          "Order": "0"
        },
        {
          "CategoryID": "33219990014",
          "Name": "Tees",
          "Order": "1"
        },
        {
          "CategoryID": "33219991014",
          "Name": "Mugs",
          "Order": "2"
        }
      ]
    }
  }
}

type EbayTradingStoreCategory = {
  CategoryID: string;
  Name: string;
  Order: string;
  ChildCategory?: EbayTradingStoreCategory[]|EbayTradingStoreCategory
};

export type EbayTradingStore = {
  Name: string;
  SubscriptionLevel: string;
  CustomCategories?: {
    CustomCategory?: EbayTradingStoreCategory[];
  };
};

type GetStoreResponseContent = {
  Store: EbayTradingStore;
}
export type EbayGetStoreSuccessOutput = EbayTradingSuccessOutput<GetStoreResponseContent>