import { WebDependencies } from "../WebDependencies";
import { computed, observable, autorun, action, flow } from "mobx";
import { UserStore } from "./UserStore";
import { snapData } from "../util/util";
import { UserDropbox } from "@root/shared/lib/database";
import type * as Sentry from '@sentry/react';
import { EbayTradingAPI } from "@root/shared/lib/clients/EbayTrading";

export class ErrorStore {
  private deps: WebDependencies;
  private auth: UserStore

  constructor(deps: WebDependencies, auth: UserStore) {
    this.deps = deps;
    this.auth = auth;
    deps.sentry.init({
      dsn: "https://2c2dbd637b104ea598dd34aeec559c3c@o416947.ingest.sentry.io/5314453",
      environment: deps.config.stage
    });
  }

  captureException: typeof Sentry.captureException = (e, context)=>{
    /// @ts-ignore
    console.log('logging error', window.eee = e)
    if (this.auth.state) {
      this.deps.sentry.setUser({id: this.auth.state.uid})
    }
    return this.deps.sentry.captureException(e, context)
  }
  addBreadcrumb: typeof Sentry.addBreadcrumb = (breadcrumb)=>{
    return this.deps.sentry.addBreadcrumb(breadcrumb);
  }
  captureEvent: typeof Sentry.captureEvent = (event)=>{
    if (this.auth.state) {
      this.deps.sentry.setUser({id: this.auth.state.uid})
    }
    return this.deps.sentry.captureEvent(event);
  }
}
