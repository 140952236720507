import { InputProps } from "./kit/inputTypes"
import classNames = require("classnames")
import React = require("react")
import { observer } from "mobx-react-lite"
import { action, observable, computed, autorun } from "mobx"
import { useController } from "../util/mobxUtils"
import { Icon } from "./kit/icons"
import { TextInput } from "./kit/TextInput"
import { ProductColorOption, FirewardInput, ProductSizePrice } from "@root/shared/lib/database"
import { Checkbox } from "./kit/Checkbox"
import { AvailableSizesInput } from "./AvailableSizesInput"
import { flatten, Model, randomId, Assign, isArray } from "@root/shared/lib/x"
import { MainStore } from "../store/store"
import { useStore } from "../store/StoreContext"
import { FileDropzoneInput } from "./kit/FileDropzoneInput"
import { SpinnerOverlay } from "./kit/SpinnerOverlay"
import {ColorOptionsModel} from '../store/ProductStore'
import { textRequired } from "@root/shared/lib/schemas/errorValidators"
import { cleanupCode } from "@root/shared/lib/skus"


export type ColorOptionsInputProps = {
  value?: ColorOptionsModel[]
  onChange?: (value: ColorOptionsModel[]) => void
  sizes: ProductSizePrice[]
} & InputProps

export class ColorOptionsInputController {
  
  @observable props: ColorOptionsInputProps;
  @observable private urlCache: Record<string, string|null|undefined> = {};
  @observable uploadingItemId: string|null = null;
  
  private store: MainStore

  constructor(props: ColorOptionsInputProps, store: MainStore) {
    this.props = props;
    this.store = store;

    autorun(()=>{
      this.items.forEach(item=>{
        if (item.imagePath) {
          this.store.images.getImageUrl(item.imagePath)
            .then(url => this.setImageUrl(item.id, url));
        }
      });
    }, {delay: 500});

  }

  @action private setImageUrl = (itemId: string, url: string) => {
    this.urlCache[itemId] = url;
  }

  @action setProps = (props: ColorOptionsInputProps) => {
    this.props = {...props};
  }

  @action onChange = (items: ColorOptionsModel[]) => {
    this.props.onChange && this.props.onChange(items);
  }

  @action addItem = () => {
    const next: ColorOptionsModel = { id: randomId(), sizeIds: [] }
    this.onChange([...this.items, next])
  }

  @action removeItem = (item: ColorOptionsModel) => {
    this.onChange(this.items.filter(prev=>prev.id !== item.id))
  }

  @action onItemChange = (next: ColorOptionsModel) => {
    const value = this.items.map((item) => item.id === next.id ? {...item, ...next} : item);
    this.onChange(value);
  }

  @action uploadImage(item: ColorOptionsModel, files: File[]|File) {
    if (this.uid) {
      const file = isArray(files) ? files[0] : files;
      this.uploadingItemId = item.id;
      
      this.store.images.uploadNewImage({
        uid: this.uid, type: 'preview', data: file, ext: file.name.replace(/.*\.([^.]+)$/, '$1')
      }).then(action(r => {
        
        return r.progress(action((percent, complete)=>{
          console.log('upload progress=%o\% complete=%o', percent, complete)
          if (complete) {
            const updatedItem = this.items.find(x => x.id === item.id) || {};
            this.onItemChange({...item, ...updatedItem, imagePath: r.image.path})
            this.uploadingItemId = null;
          }
        }))
      }))
        .catch(e=>{
          this.uploadingItemId = null;
          this.store.error.captureException(e);
          this.store.alert.error([e.message], 'Failed to upload')
        })
    }
  }
  @computed private get uid() {
    return this.store.user.state?.uid
  }
  @computed get items() {
    return this.props.value || [];
  }

  @computed get checkedSizeIds() {
    return flatten(this.items.map(item => item.sizeIds || []))
  }

  @computed get imageUrls() {
    return this.urlCache
  }

  
  
}

export const ColorOptionsInput = observer(function ColorOptionsInput(props: ColorOptionsInputProps) {
  const store = useStore();
  const self = useController(()=>new ColorOptionsInputController(props, store), props);

  return <div className={classNames(
    "ColorOptionsInput  relative rounded border border-solid p-2",
    props.className, 
  )}>
    <div className="label">Define color options by</div>  
    <table>
      <thead>
        <tr>
          <th>Preview</th>
          <th>Color</th>
          <th>Color Code</th>
          <th>
            <div>
              Available Sizes
              {' '}
              <button onClick={self.addItem} className="ml-4 focus:shadow-outline-blue focus:outline-none focus:border-blue-300">
                <Icon name="circlePlus" color="success" />
              </button>
            </div>

          </th>
        </tr>

        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th className="label text-left pl-2">
            {
              props.sizes.map(s => <span key={s.id} className="text-center w-6 inline-block text-xs font-light">{s.size}</span>)
            }
          </th>
        </tr>
      
      </thead>
      <tbody>
        {
          self.items.map(item => <tr key={item.id}>
            <td>
              <div className='relative'>


                
                <FileDropzoneInput
                  className='relative z-10 opacity-75 bg-gray-100 bg-opacity-25'
                  onDrop={files=>self.uploadImage(item, files)}
                  accept={['image/*']}
                />
                <div className='absolute content-center flex h-full inset-y-0 items-stretch justify-center p-1 right-0 w-full'>
                  <div className="bg-center bg-contain bg-no-repeat h-full w-full"style={{
                    backgroundImage: `url(${self.imageUrls[item.id]})` || undefined,
                  }}></div>
                  {!item.imagePath && <span className="error text-xs break-words">image required</span>}
                </div>

                
                
                <SpinnerOverlay show={self.uploadingItemId===item.id || !!item.imagePath && !self.imageUrls[item.id]} />

              </div>
            </td>
            <td><TextInput errorFunc={textRequired} value={item.name || ''} onChange={name=>self.onItemChange({...item, name})} className="mx-2"/></td>
            <td><TextInput errorFunc={textRequired} value={item.code || ''} onChange={code=>self.onItemChange({...item, code})} filter={cleanupCode} /></td>
            <td>

              {
                <AvailableSizesInput
                  className="mx-2"
                  dirty={props.dirty}
                  sizes={props.sizes}
                  value={item.sizeIds}
                  onChange={sizeIds => self.onItemChange({...item, sizeIds})}
                  disabledSizeIds={[] }
                />
              }
              {
                props.dirty && item.sizeIds.length === 0 && <div className="error text-xs mt-1 h-0 ml-2.5">
                  select a size
                </div>
              }
            </td>
            <td>
              <Icon name="trash" color="danger" onClick={()=>self.removeItem(item)}/>
            </td>
          </tr>)
        }
      </tbody>
    </table>
  </div>
})
