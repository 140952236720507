import { UserStore } from "./UserStore";
import { ErrorStore } from "./ErrorStore";
import { AlertStore } from "./AlertStore";
import { WebDependencies } from "../WebDependencies";
import { authAutorun } from "./storeUtils";
import { queryAction, snapData, upsertChanges } from "../util/util";
import { observable, autorun, action, computed } from "mobx";
import { ProductGroup, FirewardOutput, FirewardInput, isTimestamp } from "@root/shared/lib/database";
import { tryJson, isValidationMapError, Assign, clone, filterUndefined, upsert, notVoid } from "@root/shared/lib/x";
import {isProductGroup, isProductGroupModel, ProductGroupModel, validateGroupModel} from '@root/shared/lib/schemas/productGroupGuards'
import { isModelStorage, ModelStorage } from "./store";
import { isFieldValue } from "@root/shared/lib/firex";
import { APP_VERSION } from "@root/shared/lib/schemas/constants";
import { NoArraySentinelInput } from "@root/shared/src/schemas/productGuards";

const storageKey = (groupId: string|null) => {
  return 'product-group-' + (groupId||'new')
}


type ModelCache<T> = {value?: T, appVersion?: string, time: number}

export class ProductGroupStore {
  private readonly auth: UserStore
  private readonly error: ErrorStore

  private readonly alert: AlertStore
  private readonly deps: WebDependencies;

  @observable private groups: ProductGroup<FirewardOutput>[]|null = null
  // @observable private cloudFiles: CloudFile<FirewardInput>[]|null = null;

  constructor(deps: WebDependencies, auth: UserStore, error: ErrorStore, alert: AlertStore) {
    this.deps = deps;
    this.auth = auth;
    this.error = error;
    this.alert = alert;

    auth.onAuth( uid => {
      this.groups = null;
      if (uid) {
        return this.collRef(uid)
          .where('archived', '==', false)
          .onSnapshot(queryAction(snap => {
              this.groups = this.groups || [];
              upsertChanges(this.groups, snap, g => g.id)
            }),
            e => {
              error.captureException(e, {tags: {place: `list product-groups`}});
              alert.error([e.message]);
            }
          )
      }
    })
    
  }

  private collRef = (uid: string) => {
    return this.deps
      .firebase.firestore()
      .collection('users').doc(uid)
      .collection('product-groups');
  }


  getGroup = (groupId: string, userId: string): Promise<ProductGroup<FirewardOutput> | null> => {
    const g = this.groups && this.groups.find( g => g.id === groupId );
    if (g) return Promise.resolve(g);
    return this.collRef(userId).doc(groupId).get()
      .then(s=>snapData<ProductGroup<FirewardOutput>>(s))
      
  }

  getModelFromStorage = (groupId: string|null): ModelStorage<ProductGroupModel>|null => {
    const s = this.deps.localStorage.getItem(storageKey(groupId));
    if (!s) return null;
    const j = tryJson<ModelStorage<unknown>|null>(s, null)
    if (!j) return null;
    
    try {
      if (!validateGroupModel(j.value)) {
        this.deleteModelFromStorage(groupId);
        return null;  
      }
    } catch (error) {
      this.deleteModelFromStorage(groupId);
      if (isValidationMapError(error)) {
        console.log('error messages', error.messages())
      }
    }
    if (j.appVersion !== APP_VERSION) {
      this.deleteModelFromStorage(groupId);
      return null;
    }
    
    return isModelStorage(isProductGroupModel)(j) ? j : null;

  }

  loadArchived = (uid: string) => {
    return this.collRef(uid).where('archived', '==', true).get()
      .then(snaps => snaps.docs.map(snap => snapData<ProductGroup<FirewardOutput>>(snap)).filter(notVoid))
  }

  saveModelToStorage = (model: ProductGroupModel) => {
    const value = {...model, lastWrite: isFieldValue(model.lastWrite) ? this.currentTimestamp() : model.lastWrite};
    const data: ModelCache<ProductGroupModel> = {
      value, time: Date.now()
    }
    this.deps.localStorage.setItem(storageKey(value.id || null), JSON.stringify(data));
  }
  deleteModelFromStorage = (groupId: string|null) => {
    this.deps.localStorage.removeItem(storageKey(groupId));
  }

  @computed get productGroups(): (readonly ProductGroup<FirewardOutput>[]|null) {
    return this.groups
  }
  saveGroup = (group: Assign<ProductGroup<NoArraySentinelInput>, {id?: string}>, uid: string) => {
    return this.deps.fireCall('saveProductGroup', {group}).then(r=>r.group)
  }
  private currentTimestamp() {
    return this.deps.firebase.firestore.Timestamp.fromDate(new Date());
  }
}
