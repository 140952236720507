import { WebEnv as WebEnv } from "./env/WebEnv";
import { WebDependencies, Firebase, FirebaseUI } from "./WebDependencies";
import * as drop from 'dropbox';
import * as Sentry from '@sentry/react';
Sentry.init({
  dsn: 'https://2c2dbd637b104ea598dd34aeec559c3c@o416947.ingest.sentry.io/5314453',
  normalizeDepth: 8
});

import { fireCall } from "./util/funcs";
import { CallableRoutes } from "@root/shared/lib/callableRoutes";
import { EbayTradingAPI } from "@root/shared/lib/clients/EbayTrading";
// import { configure } from "mobx";
import * as mobix from 'mobx';
import { deepFind } from "@root/shared/lib/x";
import { isFieldValue } from "./util/util";
import Imgix = require('imgix-core-js');

declare const firebaseui: FirebaseUI
declare const firebase: Firebase;

export function makeLiveWebDependencies(env: WebEnv): WebDependencies {

  var db = firebase.app().firestore();

  mobix.configure({enforceActions: 'observed'});
  
  if (typeof location !== 'undefined') {
    
    const hostname = location.hostname.trim().replace(/\/$/, '');
    const host = location.protocol + '//' + hostname;
    const ssl = location.protocol.indexOf('https') > -1;

    if (location.host.indexOf(':') > -1 || location.host.indexOf('q.abstractsubject.com') > -1) {      
      const funcURL = host + ':5001';

      firebase.functions().useFunctionsEmulator(funcURL);

      db.settings({
        host: hostname + ":8080",
        ssl: ssl
      });
    }
  }

  firebase.functions().httpsCallable('callMe')({fooooo: 123})
  .catch(e=>{
    console.error('Functions are not callable', e);
    Sentry.captureException(e, {tags: {place: 'liveWebDependenices'}})
  })

  const fireCall = <K extends keyof CallableRoutes>(name: K, data: CallableRoutes[K][0], timeout?: number): Promise<CallableRoutes[K][1]> => {
    if (deepFind(data, isFieldValue)) {
      const error = new Error('Unexpected value sent to server. The administrator has been notified..');
      Sentry.captureException(error, {extra: {place: 'liveWebDependencies'}})
      throw error;
    }
    return firebase.functions().httpsCallable(name, {timeout})(data)
    .then(x=>x.data)
    .catch(r => {
      if (r?.details?.response?.Errors?.length > 0) {
        if (r.details.response.Errors[0].ErrorCode == '21916015' /**  */) {
          /* 
            {
              "ShortMessage": "Invalid session ID.",
              "LongMessage": "The SessionID in your request is invalid",
              "ErrorCode": "21916015",
              "SeverityCode": "Error",
              "ErrorClassification": "RequestError",
              "ErrorParameters": []
            }
          */
         alert('The eBay session has expired. Please reconnect the account and reload the page.')
        }
        
      }
      return Promise.reject(r);
    })
  }

  const imgix = new Imgix({domain: env.imgixDomain});

  
  return {
    localStorage: localStorage,
    setInterval: window.setInterval.bind(window),
    setTimeout: window.setTimeout.bind(window),
    sentry: Sentry,
    location,
    fireCall,
    firebaseui, 
    firebase,
    dropbox: new drop.Dropbox({fetch: window.fetch, clientId: env.dropboxClientId}),
    config: {
      stage: 'development',
      baseURL: typeof location != 'undefined' ? location.protocol + '//' + location.host : '//NULL-host'
    },
    imgix
  }
}
