import React = require('react');
import { observer } from 'mobx-react-lite';
import { observable, action, autorun } from 'mobx';
import { useController } from '../util/mobxUtils';
import classNames = require('classnames');
import { MainStore } from '../store/store';
import { useStore } from '../store/StoreContext';
import { ClassValue } from 'classnames/types';
import { PendingListingOptions } from '../store/ListingsStore';
import { first, last, uniq } from '@root/shared/lib/x';
import { Select } from './kit/Select';


export type PendingListingsPaginationProps = {
  className?: ClassValue
  totalItems: number
  perPage: number
  page: number
  onPerPage: (perPage: number|undefined) => void
  next: () => void
  previous: () => void
  onPage: (page: number) => void
}

// class PendingListingsPaginationController {
  
//   @observable private props: PendingListingsPaginationProps;
//   store: MainStore

//   constructor(props: PendingListingsPaginationProps, store: MainStore) {
//     this.props = props;
//     this.store = store;
//   }

//   @action setProps = (props: PendingListingsPaginationProps) => {
//     this.props = props;
//   }
// }
// const PageLink = (props: PendingListingOptions & {linkPage: number, label?: JSX.Element}) => {
//   return <a href="/" className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
//     1
//   </a>
// }
export const PendingListingsPagination = observer(function PendingListingsPagination(props: PendingListingsPaginationProps) {
  const store = useStore();

  const resualtStart = props.page * props.perPage;
  const resultEnd = Math.min(resualtStart + props.perPage, props.totalItems);
  const totalPages = Math.ceil(props.totalItems / props.perPage);


  const allPages = new Array(totalPages).fill(0).map((v, i) => i);

  const paginationStart = Math.max(0, Math.min(props.page - 2, totalPages - 5));
  const paginationEnd = Math.min(paginationStart + 6, totalPages)
  
  const pages: number[] = allPages.slice(paginationStart, paginationEnd);
  const lastLink = last(pages);

  const canPage = (n: number) => 0 <= n && n < totalPages && n !== props.page;
  const canNext = canPage(props.page + 1);
  const canPrev = canPage(props.page - 1);

  const perPageOptions = [2, 4, 8, 25, 50, 100, 150].map(n => ({
    label: n.toString(),
    value: n
  }));

  // const self = useController(() => new PendingListingsPaginationController(props, store), props);
  if (pages.length === 0) return null;

  return <div className={classNames('PendingListingsPagination', props.className)}>
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="flex-1 flex justify-between sm:hidden">
        <div onClick={props.previous} className={classNames({
          "cursor-pointer text-gray-700 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 hover:text-gray-500 active:bg-gray-100 active:text-gray-700": canPrev,
          "cursor-not-allowed text-gray-300": !canPrev
        }, "relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md bg-white  transition ease-in-out duration-150")}>
          Previous
        </div>
        <div onClick={props.next} className={classNames({
          "cursor-pointer text-gray-700 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 hover:text-gray-500 active:bg-gray-100 active:text-gray-700": canNext,
          "cursor-not-allowed text-gray-300": !canNext
        }, "relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md bg-white  transition ease-in-out duration-150")}>
          Next
        </div>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm leading-5 text-gray-700">
            Showing{' '}
            <span className="font-medium">{resualtStart + 1}</span>{' '}
            to{' '}
            <span className="font-medium">{resultEnd}</span>{' '}
            of{' '}
            <span className="font-medium">{props.totalItems}</span>{' '}
            results
          </p>
        </div>
        <div>
          <Select
            options={perPageOptions}
            onChange={props.onPerPage}
            value={props.perPage}
          />
        </div>
        <div>
          <nav className="relative z-0 inline-flex shadow-sm">
            <div onClick={props.previous} className={classNames({
              "cursor-pointer text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 ": canPrev,
              "cursor-not-allowed text-gray-300": !canPrev
            }, "relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium transition ease-in-out duration-150")} 
            aria-label="Previous">
              {/* Heroicon name: chevron-left */}
              <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
            </div>
            {
              !!pages[0] && pages[0] > 0 && <span className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700">
                ...
              </span>
            }
            {
              pages.map(n => 
                <div onClick={canPage(n) ? (() => props.onPage(n)) : undefined} key={n} className={classNames({
                  "cursor-pointer text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700": canPage(n),
                  "cursor-default text-gray-500 underline": !canPage(n)
                }, "-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium transition ease-in-out duration-150")}>
                  {n + 1}
                </div>
                )
            }
            
            {
              !!lastLink && lastLink < totalPages - 1 && <span className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700">
                ...
              </span>
            }
            <div onClick={props.next} className={classNames({
              "cursor-pointer text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500": canNext,
              "cursor-not-allowed text-gray-300": !canNext
            }, "-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium transition ease-in-out duration-150")} aria-label="Next">
              {/* Heroicon name: chevron-right */}
              <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
              </svg>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
});