import { InputProps } from "./inputTypes"
import { observer } from "mobx-react-lite"
import React = require("react")
import { observable, action } from "mobx";
import { useController } from "../../util/mobxUtils";
import classNames = require("classnames");
import { MainStore } from "../../store/store";
import { useStore } from "../../store/StoreContext";
import {useDropzone, DropzoneOptions} from 'react-dropzone'
import { UserImage } from "./UserImage";
import { Icon } from "./icons";
import { isArray } from "@root/shared/lib/x";


export type AcceptedUploadTypes = 'image/*' | 'image/png';

export type FileDropzoneInputProps = {
  src?: string
  accept: AcceptedUploadTypes|[AcceptedUploadTypes, ...AcceptedUploadTypes[]]
  disabled?: boolean
  maxSize?: number
  multiple?: boolean
  onDrop: (files: File[]) => void
} & InputProps;

export const FileDropzoneInput = (function ImageUploadInput(props: FileDropzoneInputProps) {

  // const accept = isArray()

  const onDrop  = React.useCallback<Required<DropzoneOptions>['onDrop']>((acceptedFiles: File[], fileRejections, event) => {
    props.onDrop(acceptedFiles)
  }, [])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop, 
    maxSize: props.maxSize || 1000000000, 
    multiple: props.multiple || false,
    accept: props.accept
  })
  
  return <div className={classNames(
    'ImageUploadInput rounded border-dashed border-2 p-2', 
    {
      'border-blue-500': isDragActive
    },
    props.className
    )}>
    <div {...getRootProps()} className="flex justify-center items-center">
      <input {...getInputProps({})} />
        <Icon name="upload" color="secondary" size={8} />
        {
          isDragActive ?
            <span>...</span> :
            <span></span>
        }
    </div>
  </div>
})
