import React = require('react');
import SpinKit = require('react-spinkit');
import { ColorType, colorClass } from './kitTypes';
import classNames = require('classnames');
export type SpinnerProps = {
  className?: string;
  spinkitColor?: SpinKit.SpinnerProps['color'];
  /** will be dark shade of gray if omitted */
  color?: ColorType | 'white' | null
  text?: string
  style?: React.CSSProperties
  small?: boolean
};

export const Spinner = function Spinner(props: SpinnerProps) {
  const color = props.color === 'white' 
    ? null 
    : !props.color
    ?  'text-gray-700'
    : colorClass(props.color)
  ;
  const style = {
    ...(props.style || {})
  };
  const smallStyle = {width: '12px', height: '12px'};
  if (props.small) Object.assign(style, smallStyle);
  
  return <span className="inline-flex">
    <SpinKit 
      overrideSpinnerClassName="sk-spinner-override" 
      fadeIn={"none"} 
      color={props.spinkitColor} 
      style={style}
      className={classNames([
        props.className,
        !props.spinkitColor ? color : null
      ])} 
      name="circle" 
    /> 
    {props.text && <span className="ml-2 text-sm text-gray-500">{props.text}</span>}
  </span>
}
  
