import React = require('react');
import { observer } from 'mobx-react-lite';
import { observable, action, autorun } from 'mobx';
import { useController } from '../../util/mobxUtils';
import classNames = require('classnames');
import { ClassValue } from 'classnames/types';
import { Transition } from '../../util/Transition';
import { ColorType } from './kitTypes';
import { Button } from './Button';
import { Icon, IconName } from './icons';
import { ModalButtons } from '../ModalButtons';


export type ModalProps = React.PropsWithChildren<{
  className?: ClassValue
  title: string
  okButton?: string
  cancelButton?: string
  okColor?: ColorType
  onOK?: () => void
  onXClose?: () => void
  onCancel?: () => void
  noButtons?: boolean
  icon: IconName
}>

class ModalController {
  
  @observable private props: ModalProps;

  constructor(props: ModalProps) {
    this.props = props;
  }

  @action setProps = (props: ModalProps) => {
    this.props = props;
  }
}

export const Modal = observer(function Modal(props: ModalProps) {
  
  const self = useController(()=>new ModalController(props), props);
  const color = props.okColor || 'default';

  return <div className={classNames('Modal fixed z-10 inset-0 overflow-y-auto', props.className)}>
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        {/* <!--
          Background overlay, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        --> */}
        {/* <Transition
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        > */}
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
          {/* <!--
            Modal panel, show/hide based on modal state.

            Entering: "ease-out duration-300"
              From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              To: "opacity-100 translate-y-0 sm:scale-100"
            Leaving: "ease-in duration-200"
              From: "opacity-100 translate-y-0 sm:scale-100"
              To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          --> */}
          {/* <Transition
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          > */}
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                {
                  (props.onXClose || props.onCancel) &&
                  <button onClick={props.onXClose || props.onCancel} type="button" className="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150" aria-label="Close">
                    {/* Heroicon name: x */}
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                }
              </div>
              <div className="sm:flex sm:items-start">
                <div className={classNames(
                  "mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10",
                  color === 'danger' ? 'bg-red-100' : 'bg-blue-100'
                )}>
                  {/* Heroicon name: exclamation */}
                  {/* <svg className="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                  </svg> */}
                  <Icon name={props.icon} color={color}  />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                    {props.title}
                  </h3>
                  <div className="mt-2">
                    <div className="text-sm leading-5 text-gray-500">
                      {
                        props.children
                      }
                    </div>
                  </div>
                </div>
              </div>
              { !props.noButtons && props.onOK &&
                <ModalButtons
                  okButton={props.okButton || 'OK'}
                  onOK={props.onOK}
                  onCancel={props.onCancel}
                  cancelButton={props.cancelButton}
                />
              } 
            </div>
          {/* </Transition> */}
        {/* </Transition> */}
      </div>
  </div>
});