import { UserStore } from "./UserStore";
import { ErrorStore } from "./ErrorStore";
import { AlertStore } from "./AlertStore";
import { WebDependencies } from "../WebDependencies";
import { authAutorun } from "./storeUtils";
import { queryAction, snapData, upsertChanges } from "../util/util";
import { observable, autorun, action, computed } from "mobx";
import { ProductGroup, FirewardOutput, FirewardInput, isTimestamp, ProductListing, ProductDesign, DesignQueueItem, FirewardTypes } from "@root/shared/lib/database";
import { tryJson, isValidationMapError, Assign, clone, filterUndefined, debounce, upsert } from "@root/shared/lib/x";
import {isProductGroup, isProductGroupModel, ProductGroupModel, validateGroupModel} from '@root/shared/lib/schemas/productGroupGuards'
import { ModelStorage } from "./store";
import { isFieldValue } from "@root/shared/lib/firex";
import type { firestore } from 'firebase'
import { NoSentinelOutput } from "../../../shared/lib/schemas/productGuards";




export class ListingsStore {
  private readonly user: UserStore
  private readonly error: ErrorStore

  private readonly alert: AlertStore
  private readonly deps: WebDependencies;

  @observable private listings: ProductListing<FirewardOutput>[] = [];
  @observable private designs: ProductDesign<FirewardOutput>[]|null = null;
  @observable private designListeners: {designId: string, off: ()=>void}[] = []

  @observable designQueueItems: DesignQueueItem<FirewardOutput>[]|null = [];

  private designQueueItemsCancel: null|(() => void) = null;

  constructor(deps: WebDependencies, user: UserStore, error: ErrorStore, alert: AlertStore) {
    this.deps = deps;
    this.user = user;
    this.error = error;
    this.alert = alert;

    user.onAuth( uid => {
      return () => {
        this.designListeners.forEach(x=>x.off());
        this.designListeners = [];
        this.listings = [];
      }
    });
    
    
    //
    // Insert/update/delete listings in a debounced way.
    //
    let listings: firestore.DocumentChange<firestore.DocumentData>[] = [];

    const upsertListings = (changes: firestore.DocumentChange<firestore.DocumentData>[]) => {
      listings.splice(listings.length - 1, 0, ...changes);
      syncListings(null)
    }
    const syncListings = debounce(action(() => {
      listings.forEach(x => upsert(this.listings, x => x.id, x.doc.id, x.type === 'removed' ? null : snapData(x.doc), (a, b) => a.skus.ebayCustomLabel > b.skus.ebayCustomLabel));
      listings = [];
    }), 400);

    const syncDeletes = debounce(action(() => {
      const designIds = this.pendingDesigns?.map(d => d.id) || [];
      const next = this.listings.filter(l => designIds.indexOf(l.designId) > -1);
      if (next.length !== this.listings.length) {
        this.listings = next;
      }
    }), 400);

    user.onAuth( uid => {
      this.designs = null;
      if (uid) {
        return this.designs$(uid)
          .where('archived', '==', false)
          .orderBy('lastChange', 'desc')
          .onSnapshot(queryAction(
            snap => {
              this.designs = this.designs || [];
              upsertChanges(this.designs, snap, g => g.id);
              
              const newDesigns = this.designs.filter(d => !this.designListeners.some(l => l.designId == d.id));

              newDesigns
                .forEach(d => {
                  this.designListeners.push({
                    designId: d.id, 
                    off: this.user$(uid).collection('listings')
                      .where('designId', '==', d.id)
                      .orderBy('productId', 'asc')
                      .onSnapshot(queryAction(snap => {
                        upsertListings(snap.docChanges())
                        // upsertChanges(this.listings, snap, x => x.id)
                      }))
                  })
                });

              const designListenersRemoved = this.designListeners.filter(l => !this.designs?.some(d => l.designId == d.id));

              designListenersRemoved.forEach(desListner => {
                  desListner.off();
                });

              if (snap.docChanges().some(c => c.type === 'removed')) {
                syncDeletes(null);
              }

              this.designListeners = this.designListeners.filter(l => !designListenersRemoved.some(r => r.designId === l.designId))
  
            }),
            e => {
              error.captureException(e, {tags: {place: `list product-designs`}});
              alert.error([e.message]);
            }
          )
      }
    });

  }
  private user$ = (uid: string) => {
    return this.deps
      .firebase.firestore()
      .collection('users').doc(uid);
  }
  private listings$ = (uid: string) => {
    return this.user$(uid).collection('listings');
  }
  
  private designs$(uid: string) {
    return this.user$(uid).collection('designs');
  }

  @computed({name: 'ListingsStore.pendingListings'}) get pendingListings(): (readonly ProductListing<FirewardOutput>[]) {
    return this.listings
  }

  @computed({name: 'ListingsStore.pendingDesigns'}) get pendingDesigns(): (readonly ProductDesign<FirewardOutput>[]|null) {
    return this.designs
  }

  updateListing = (ls: ProductListing<FirewardInput>, uid: string) => {
    return this.listings$(uid).doc(ls.id).set(ls, {merge: true})
  }
  
  /**
   * This method can probably be merged with the updateMultipleListings 
   * @param lss 
   * @param uid 
   */
  partialUpdateMultipleListings = (lss: (Partial<ProductListing<FirewardInput>> & {id: string})[], uid: string) => {
    const batch = this.deps.firebase.firestore().batch();
    lss.forEach(ls => batch.update(this.listings$(uid).doc(ls.id), ls))
    return batch.commit();
  }

  updateMultipleListings = (lss: ProductListing<FirewardInput>[], uid: string) => {
    // return Promise.all(lss.map(ls => this.listings$(uid).doc(ls.id).update(ls)))
    const batch = this.deps.firebase.firestore().batch();
    lss.forEach(ls => batch.update(this.listings$(uid).doc(ls.id), ls))
    return batch.commit();
  }
  updateDesign = (design: Partial<ProductDesign<FirewardInput>> & {id: string}, uid: string) => {
    return this.designs$(uid).doc(design.id).update(design)
  }
  updateMultipleDesigns = (designs: (Partial<ProductDesign<FirewardInput>> & {id: string})[], uid: string) => {
    const batch = this.deps.firebase.firestore().batch();
    designs.forEach(update => {
      batch.update(this.designs$(uid).doc(update.id), update)
    });
    return batch.commit(); 
  }
  processMultipleDesigns = (designIds: string[], userId: string) => {
    return this.updateMultipleDesigns(designIds.map(id => ({
      id, 
      queuedForPublication: true,
      publicationError: null
    })), userId)
  }  
  overrideDesignTitles = (title: string, designIds: string[], userId: string) => {
    return this.updateMultipleDesigns(designIds.map(id => ({id, keywords: title})), userId);
  }

  private queueItemCollRef = (uid: string) => {
    return this.deps.firebase.firestore().collection('users').doc(uid).collection('process-queue')
  }
  private queueItemRef = (itemId: string, uid: string) => {
    return this.queueItemCollRef(uid).doc(itemId)
  }
  deleteQueueItem = (item: DesignQueueItem, uid: string) => {
    return this.queueItemRef(item.id, uid).delete();
  }
  updateQueueItem = (item: Partial<DesignQueueItem<FirewardInput>> & {id: string}, uid: string) => {
    return this.queueItemRef(item.id, uid).update(item);
  }
  /**
   * This is not initiated right away in order to prevent unnecessary activity, which could be significant.
   * @param uid string
   */
  listenToDesignQueueItems = (uid: string) => {
    if (this.designQueueItemsCancel) return;

    this.designQueueItemsCancel = this.queueItemCollRef(uid)
    .orderBy('lastWrite', 'asc')
    .onSnapshot(queryAction(q => {
      this.designQueueItems = this.designQueueItems || [];
      upsertChanges(this.designQueueItems, q, x => x.id);      
    }))
  }

  getListingOptions = () => {
    const str = this.deps.localStorage.getItem('PendingListingOptions');
    return str ? tryJson<PendingListingOptions|null>(str, null) : null
  }
  saveListingOptions = (opt: PendingListingOptions) => {
    return this.deps.localStorage.setItem('PendingListingOptions', JSON.stringify(opt))
  }


}
export type PendingListingOptions = {
  perPage?: number
}