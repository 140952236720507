import React = require("react")
import { observer } from "mobx-react-lite"
import { useStore } from "../../store/StoreContext"
import { AlertDanger, AlertSuccess } from "./Alert";

export const TopAlert =  observer((props: {}) => {
  const store = useStore();
  return <div className="fixed top-0 pt-2 left-0 w-full px-12 z-50">
    {
      store.alert.alert && store.alert.alert.type=='error' &&
      <AlertDanger onDismiss={store.alert.dismiss} errors={store.alert.alert.content} title={store.alert.alert.title} />
    }
    {
      store.alert.alert && store.alert.alert.type=='success' &&
      <AlertSuccess onDismiss={store.alert.dismiss} message={store.alert.alert.message} />
    }
  </div>
})
