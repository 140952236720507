import React = require("react")
import { observer } from "mobx-react-lite"
import { useStore } from "../store/StoreContext"
import { Login } from "./Login";
import {ProductGroupModel, productGroupToModel, validateProductGroupOutput, PRODUCT_GROUP_LABELS} from '@root/shared/lib/schemas/productGroupGuards';
import { MainStore, ModelStorage } from "../store/store";
import { Link, useRouting } from "../routing/routes";
import { useController } from "../util/mobxUtils";
import { action, observable, autorun, toJS, computed } from "mobx";
import { ProductGroup, FirewardOutput } from "@root/shared/lib/database";
import { errorToString, hasChild, isString, isValidationMapError, keys } from "@root/shared/lib/x";
import { SpinnerOverlay } from "./kit/SpinnerOverlay";
import { ProductGroupColorInput } from "./ProductGroupColorInput";
import { LinkText } from "./kit/LinkText";
import { TextInput } from "./kit/TextInput";
import { ButtonBar } from "./kit/ButtonBar";
import { Button } from "./kit/Button";
import { Select } from "./kit/Select";


type ProductGroupEditorProps = {
  productGroupId?: string;
};

const DEFAULT_MODEL: ProductGroupModel = {
  lastWrite:  null,  
  archived: false,
  productColors: []
}
export class ProductGroupEditorController {
  
  private original: ProductGroupModel;
  private uid: string
  private store: MainStore
  routing: ReturnType<typeof useRouting>

  @observable private props: ProductGroupEditorProps

  @observable model: ProductGroupModel

  @observable dirty: boolean = false;
  @observable saving: boolean = false;

  constructor(
    group: ProductGroup<FirewardOutput>|null, 
    cache: ModelStorage<ProductGroupModel>|null,
    uid: string,
    store: MainStore, 
    props: ProductGroupEditorProps,
    routing: ReturnType<typeof useRouting>
    ) {
    this.store = store;
    this.props = props;
    this.uid = uid;
    this.routing = routing;

    if (cache) {
      if (group) {
        // @ts-ignore
        console.log('lastWrite', window.z = group.lastWrite)
        this.model = group.lastWrite && cache.time > group.lastWrite.toMillis() 
          ? cache.value : productGroupToModel(group);
          // this.model = productGroupToModel(group)
      } else {
        this.model = cache.value;
      }
    } else {
      this.model = group ? productGroupToModel(group) : DEFAULT_MODEL
    }

    this.original = this.model;

    autorun(() => {
      store.groups.saveModelToStorage(this.model);
    }, {delay: 300})
    
  }

  @computed get errors() {
    
    const errs: {[s in Exclude<keyof ProductGroupModel, undefined>]?: string} = {};
    if (this.saving) return null;
    
    const name = this.store.groups.productGroups?.find(g => g.name == this.model.name && this.original?.id != g.id)
    ? `another group with this name already exists.` : !this.model.name ? `required` : null
    ;
    
    if (name) errs.name = name;

    return keys(errs).filter(k => errs[k]).length == 0 ? null : errs;
  }

  @computed get storeCategoryOptions() {
    const o = this.store.ebay.ebayStore?.CustomCategories?.CustomCategory
    ?.filter(c => !c.ChildCategory)
    ?.map(c => ({
      label: c.Name, value: c.CategoryID
    }));
    return o ? [{label: '', value: null}, ...o] : null
  }


  @action setProps = (props: ProductGroupEditorProps) => {
    this.props = props;
  }

  @action reset = () => {
    this.model = this.original;
  }
  @computed get cat2error() {
    return this.model.ebayStoreCategory2Id && !this.storeCategoryOptions?.find(o => o.value === this.model.ebayStoreCategory2Id) 
      ? `Category not available`
      : null;
  }
  @computed get cat1error() {
    return this.model.ebayStoreCategoryId && !this.storeCategoryOptions?.find(o => o.value === this.model.ebayStoreCategoryId) 
      ? `Category not available`
      : null;
  }
  @action save = () => {

    this.dirty = true;

    const model = toJS(this.model);
    model.lastWrite = new Date();
    model.userId = this.uid;

    if (this.errors) return;

    if (model.ebayStoreCategoryId === undefined) {
      delete model.ebayStoreCategoryId
    }
    
    if (model.ebayStoreCategory2Id === undefined) {
      delete model.ebayStoreCategory2Id
    }

    try {
      
      if (!validateProductGroupOutput(model)) return;      
      
    } catch (e) {
      if (isValidationMapError(e)) {
        this.store.alert.error(e.messages(PRODUCT_GROUP_LABELS), 'Please fix the following:');
      } else {
        this.store.alert.error([errorToString(e)], 'Database replied with error:');
        this.store.error.captureException(e, {extra: {model, place: 'saveGroup', uid: this.uid}})
      }
    }
    
    if (!validateProductGroupOutput(model)) return;

    if (this.cat1error) {
      return this.store.alert.error([this.cat1error], "First store category is invalid.");
    }
    if (this.cat2error) {
      return this.store.alert.error([this.cat2error], "Second store category is invalid.");
    }
    
    model.name = model.name.trim();
    const startGroupId = hasChild('id', isString)(model) ? model.id : null;
    
    this.saving = true;
    this.store.groups.saveGroup(model, this.uid)
      .then(action(saved => {
        this.original = saved;
        this.model = saved;
        this.store.groups.deleteModelFromStorage(startGroupId || null);
        this.saving = false;
        this.dirty = false;
        this.store.alert.add({type: 'success', message: `Group saved. Upload pictures to a folder your DropBox named ${saved.name}.`})
        this.routing.productGroups.navigate();
      }))
      .catch(action(e => {
        this.saving = false;
        /// @ts-ignore 
        window.eee = e;
        this.store.alert.error([errorToString(e)], 'Database replied with error:');
        this.store.error.captureException(e, {extra: {model, place: 'saveGroup', uid: this.uid}})
      }));
      

  }
}

export const ProductGroupEditor = observer(function ProductGroupEditor(props: ProductGroupEditorProps) {
  const store = useStore();
  const routing = useRouting();

  const [ self, setSelf ] = React.useState<ProductGroupEditorController|null>(null);

  React.useEffect(() => {
    let mounted = true;
    const uid = store.user.state?.uid;
    const id = props.productGroupId;
    if (uid) {
      const cache = store.groups.getModelFromStorage(id || null)
      if (id) {
        store.groups.getGroup(id, uid)
        .then(group => {
          if (!mounted) return;
          setSelf(new ProductGroupEditorController(group || null, cache, uid, store, props, routing))
        })
        .catch( e => {
          store.error.captureException(e);
          store.alert.error([errorToString(e)], 'Database returned an error requesting product groups')
        })
      } else {
        setSelf(new ProductGroupEditorController(null, cache, uid, store, props, routing))
      }
    } 

    return ()=>{mounted = false}

  }, [props.productGroupId, store.user.state]);
  
  if (!store.user.state) return <Login/>;

  if (!self || !store.products.productList) return <div className="relative">
    <SpinnerOverlay text="loading product group" />
  </div>

  if (store.products.productList.length == 0) {
    return <div>
      You have no products set up. Please <Link href={routing.products.href()}><LinkText>create some</LinkText></Link> before creating a group.
    </div>
  }

  return <div className="ProductGroupEditor">
    
    <TextInput
      dirty={self.dirty}
      className="mb-3"
      label="Product Group Name"
      value={self.model.name || ''}
      onChange={val => self.model.name = val}
      error={self.errors?.name}
    />

    {
      self.storeCategoryOptions &&
      <Select
      dirty={true}
      className="mb-3"
      label="Store Category 1"
      value={self.model.ebayStoreCategoryId}
      onChange={val => self.model.ebayStoreCategoryId = val || undefined}
      options={self.storeCategoryOptions}
      error={self.cat1error || false}
      />
    }

    {
      self.storeCategoryOptions &&
      <Select
        dirty={true}
        className="mb-3"
        label="Store Category 2"
        value={self.model.ebayStoreCategory2Id}
        onChange={val => self.model.ebayStoreCategory2Id = val || undefined}
        error={self.cat2error}
        options={self.storeCategoryOptions}
      />
    }
    
    <ProductGroupColorInput
      dirty={self.dirty}
      value={self.model.productColors.map(c => ({...c}))}
      onChange={val => self.model.productColors = val.map(c => ({...c}))}
      products={store.products.productList}
    />

    <ButtonBar left>
      <Button onClick={self.reset} text="Reset" outline />
      <Button spinner={self.saving} onClick={self.save} text={self.model.id ? 'Update' : 'Create'} />
    </ButtonBar>
  </div>
})
