import * as React from 'react';
import Spinner = require('react-spinkit');
import { Transition } from '../../util/Transition';

export type SpinnerOverlayProps = {
  /**
   * true by default
   */
  show?: boolean
  text?: JSX.Element|null|number|string|false
}
export const SpinnerOverlay = ({show, text}: SpinnerOverlayProps) => 
<Transition
        show={show || show===undefined}
        enter="transition ease-in-out duration-300 transform"
        enterFrom="scale-y-100 opacity-100"
        enterTo="scale-y-0 opacity-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="scale-y-0 opacity-0"
        leaveTo="scale-y-100 opacity-100"
      >
  <div className="SpinnerOverlay absolute top-0 left-0  w-full h-full flex flex-col items-center justify-center z-50">
    <div className="overlay absolute top-0 left-0  w-full h-full bg-gray-100 bg-opacity-25"></div>
    <Spinner name="chasing-dots"/>
    {text && <div className="text-xs text-center muted mt-3">{text}</div>}
  </div> 
</Transition>
