import classNames = require("classnames")
import { ClassValue } from "classnames/types"
import { action } from "mobx"
import React = require("react")
import { Button } from "./kit/Button"
import { IconName } from "./kit/icons"
import { ColorType } from "./kit/kitTypes"

type ModalButtonsProps = React.PropsWithChildren<{
  className?: ClassValue
  okButton: string
  cancelButton?: string
  okColor?: ColorType
  busy?: boolean
  onOK?: () => void
  onCancel?: () => void
}>
export const ModalButtons = function ModalButtons(props: ModalButtonsProps) {
  return <div className={classNames(
    "mt-5 sm:mt-4 sm:flex sm:flex-row-reverse",
    props.className
  )}>
    <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
      {
        props.onOK && <Button spinner={props.busy} color={props.okColor} text={props.okButton} onClick={action(props.onOK)} />
      }
    </span>
    <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
      {
        props.onCancel && <Button color="secondary" noBorder text={props.cancelButton || "Cancel"} onClick={action(props.onCancel)} />
      }      
    </span>
  </div>
}