import React = require("react");
import { EbayCategory } from "@root/shared/lib/clients/EbayTradingTypes";
import classNames = require("classnames");
import { LinkText } from "./kit/LinkText";
import { observe } from "mobx";
import { observer } from "mobx-react-lite";
import { useStore } from "../store/StoreContext";
import { useErrorState } from "../util/useErrorState";
import { Spinner } from "./kit/Spinner";
import { useState, useCallback } from "react";
// import { Link } from "react-navi";
import { Link, useRouting } from "../routing/routes";

type EbayCateogryItemProps = {
  category: EbayCategory;
  onSelect: () => void;
  first: boolean;
  last: boolean;
};

export const EbayCategoryItem = observer(function EbayCategoryItem(props: EbayCateogryItemProps) {
  const routing = useRouting();
  // const [ specifics, setSpecifics ] = React.useState<any>(null);
  const store = useStore();
  const registeredCatIds = store.ebay.registeredCategories?.map(cat => cat.categoryId)
  const [error, setError] = useState<any>(null);
  const [ loading, setLoading ] = useState<boolean>(false);

  const isRegistered = (id: number) => registeredCatIds?.includes(id)
 
  return <li className={classNames({
    "border-t border-gray-200": true,
    "rounded": props.first
    
    }, "rounded-sm relative")}>
    <div className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
      <div className="px-4 py-4 flex items-center sm:px-6">
        <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            <div className="label">
              {props.category.CategoryName}
              <span className="ml-1 font-normal text-sm text-gray-500">
                ID {props.category.CategoryID}, Level {props.category.CategoryLevel} {props.category.LeafCategory && "Leaf"}
              </span>
            </div>
            <div className="mt-2 flex">
              {
                props.category.LeafCategory && 
                  <div className="flex items-center text-sm leading-5 text-gray-500">
                    <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"/>
                    </svg>
                    <span>
                      {
                        loading
                        ? <Spinner/>
                        : <Link href={routing.ebayCategoryEditor.href({categoryId: props.category.CategoryID, catLevel: props.category.CategoryLevel})}>
                            <LinkText bold>{isRegistered(props.category.CategoryID) ? 'Edit' : 'Set up'}</LinkText>
                          </Link>
                      }
                      
                    </span>
                  </div>
              }
            </div>
          </div>
          <div className="mt-4 flex-shrink-0 sm:mt-0">
            
          </div>
        </div>
        { !props.category.LeafCategory &&
          <div onClick={props.onSelect} className="ml-5 cursor-pointer flex-shrink-0 bg-gray-100 border-l border-gray-200 bg-opacity-50 absolute right-0 h-full top-0 flex items-center ">
            <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"/>
            </svg>
          </div>
        }
      </div>
      <pre className="text-red-500">
        {error && JSON.stringify(error, null, '  ')}
      </pre>
    </div>
  </li>
});
